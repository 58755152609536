import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withTable, { TableContext } from '../../hoc/withTable';
import Table from '../../components/Table';
import fetchEmailBatches from '../../services/EmailTask/list_services';


const ListEmailTaskPage = ({ history }) => {
  const columns = [
    {
      Header: 'Descrição',
      accessor: 'name',
    },
    {
      Header: 'Tipo de lote',
      accessor: 'type_name',
    },
    {
      Header: 'Status do Lote',
      accessor: 'status',
    },
  ];

  return (
    <section>
      <div className="list-base list-base-width" style={{ height: '100vh' }}>
        <div className="d-flex align-items-center position-relative">
          <h2 style={{ fontSize: '1.35rem' }}>Lotes de emails</h2>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <button
            type="button"
            className="btn btn-primary w-25"
            onClick={() => history.push('/emails/new')}
          >
            Criar novo lote
          </button>
        </div>
        <TableContext.Consumer>
          {props => <Table columns={columns} {...props} />}
        </TableContext.Consumer>
      </div>
    </section>
  );
};

ListEmailTaskPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(withTable(fetchEmailBatches)(ListEmailTaskPage));
