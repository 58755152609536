import { ApolloProvider } from '@apollo/react-hooks';
import axios from 'axios';
import React from 'react';
import {
  Redirect,
  Switch
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import client from './app/apollo_client';
import { PrivateRoute } from './app/elements';
import DocumentsDetailPage from './app/enrollment/pages/DocumentsDetailPage';
import EnrollmentDetailPage from './app/enrollment/pages/EnrollmentDetailPage';
import HealthDetailPage from './app/health/pages/HealthDetailPage';
import * as pages from './app/pages';
import CreateEmailTaskPage from './app/pages/EmailTask/CreateEmailTaskPage';
import ListEmailTaskPage from './app/pages/EmailTask/ListEmailTaskPage';
import ImportsPage from './app/pages/imports/ImportsPage';
import './app/register-fa-icons';
import InviteProcessPage from './app/v2/invite/pages/InviteProcessPage';
import './assets/css/main.css';


class Main extends React.Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');
    if (token) {
      this.token = token
      axios.defaults.headers.common.Authorization = `JWT ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  }



  render() {
    const {token} = this;
    return (
      <>
        <ApolloProvider client={client}>
          <Switch>
            <PrivateRoute path="/aguarde" component={pages.WaitingProject} />
            <PrivateRoute navbar exact path="/financers" component={pages.Financers} />
            <PrivateRoute navbar path="/financers/:token" component={pages.FinancerSummary} />
            <PrivateRoute navbar exact path="/academic" component={pages.DocumentsPage} />
            <PrivateRoute navbar path="/academic/:token" component={DocumentsDetailPage} />
            <PrivateRoute navbar path="/v2/financers/:token" component={EnrollmentDetailPage} />
            <PrivateRoute navbar exact path="/health" component={pages.HealthPage} />
            <PrivateRoute navbar path="/health/:token" component={HealthDetailPage} />
            <PrivateRoute navbar path="/enrollments" component={pages.Enrollments} />
            <PrivateRoute navbar path="/onboarding" component={pages.Onboarding} />
            <PrivateRoute navbar path="/support" component={pages.Support} />
            <PrivateRoute navbar path="/importacao" component={ImportsPage} />
            <PrivateRoute navbar path="/dashboard" component={pages.DashboardPage} />
            <PrivateRoute navbar exact path="/naap" component={pages.NAAPPage} />
            <PrivateRoute navbar path="/naap/:token" component={pages.NAAPDetailsPage} />
            <PrivateRoute navbar path="/invite" component={InviteProcessPage} permission="invites.view_menu_convocacao" />
            <PrivateRoute navbar path="/emails/new" component={CreateEmailTaskPage} />
            <PrivateRoute navbar path="/emails" component={ListEmailTaskPage} />
            <Redirect to={token ? "/dashboard" : "/login"} />
          </Switch>
        </ApolloProvider>
      </>
    );
  }
}

export default Main;
