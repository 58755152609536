import React from 'react';
import { get } from 'lodash';
import { Document, Page, pdfjs } from 'react-pdf';
import { withRouter } from 'react-router-dom';
import ComponentWithToken from '../components/CompontentWithToken';
import { fetchStudent } from '../services/enrollment_services';
import { approveTerm } from '../services/notice_services';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Term extends ComponentWithToken {
  state = {
    student: {},
    loading: true,
  }

  componentDidMount() {
    this.fetchStudent();
  }

  onClick = () => {
    const { onClick } = this.props;
    approveTerm(this.token).then(() => onClick());
  }

  fetchStudent = () => {
    fetchStudent(this.token)
      .then((response) => {
        this.setState({ student: response, loading: false });
      });
  }

  render() {
    const { student } = this.state;

    return (
      <>
        <Document
          file={get(student, 'onboarding.term_url')}
          error="Não foi possível carregar o PDF."
          loading="Carregando..."
          noData="Não existe PDF."
          className="mb-5 pdf-viewer"
        >
          <Page pageNumber={1} />
        </Document>
        <div className="text-right">
          <div className="col-md-4 p-0 offset-md-8 col-sm-12">
            <div className="btn-actions">
              <button className="btn btn-black mr-2" type="button" onClick={() => this.props.backClick()}>Voltar</button>
              <button className="btn btn-primary" type="button" onClick={this.onClick}>Aceitar e continuar</button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Term);
