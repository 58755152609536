import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getIcon = status => ({
  ok: 'check',
  pending: 'asterisk',
  saving: 'spinner',
  error: 'times',
}[status]);

const getColorClass = status => ({
  ok: 'success',
  pending: 'blue',
  saving: 'blue',
  error: 'danger',
}[status]);

const AutosaveFeedback = ({ status, style }) => {
  const icon = getIcon(status);
  if (icon) {
    return (
      <span style={style} className="no-icon">
        <FontAwesomeIcon
          className={getColorClass(status)}
          icon={icon}
          pulse={status === 'saving'}
        />
      </span>
    );
  }
  return <div className="no-icon" />;
};

AutosaveFeedback.propTypes = {
  status: PropTypes.oneOf(['ok', 'pending', 'saving', 'error']),
  style: PropTypes.oneOfType([PropTypes.object]),
};

AutosaveFeedback.defaultProps = {
  status: null,
  style: {},
};

export default AutosaveFeedback;
