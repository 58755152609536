import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { InputTypeText, FieldSet, Spinner } from '../elements';
import { Form } from 'informed';
import { get } from 'lodash';
import { API_URL } from '../../lib/const';

class InfoForm extends React.Component {
  state = {
    loading: false,
    data: undefined,
  }

  get token() {
    const { match: { params: { token } } } = this.props;
    return token;
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
      this.setState({ loading: true });
      axios.get(`${API_URL}/onboarding/onboardings/${this.token}`)
      .then((response) => {
        this.setState({
        data: response.data,
        loading: false
      });
    })
    .catch((error) => {
    })
  }

  render() {
    const { onClick } = this.props;
    const { loading, data } = this.state

    return(
      <Spinner active={loading}>
        <Form>
          <FieldSet title="Informações Gerais">
            <InputTypeText value="Medicina" field="course" label="Curso" col="4" disabled />
            <InputTypeText value="Integral" field="turn" label="Turno" col="4" disabled />
            <InputTypeText value={`${get(data, 'field_step', '-')}º ano`} field="ingressForm" label="Ano/Período de Promoção" col="4" disabled />
          </FieldSet>
          <div className="text-right">
            <div className="col-md-3 p-0 offset-md-9 col-sm-12">
              <button className="btn btn-primary" type="submit" onClick={onClick}>Próximo</button>
            </div>
          </div>
        </Form>
      </Spinner>
    );
  }
}

export default withRouter(InfoForm);
