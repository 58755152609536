const HealthRecordReducer = (state, action) => {
  const { vaccinesExams } = state;
  switch (action.type) {
    case 'HANDLE_VACCINES':
      return {
        ...state,
        vaccines: action.payload,
      };
    case 'HANDLE_CONTACTS':
      return {
        ...state,
        contacts: action.payload,
      };
    case 'SET_SUBMITTING':
      return {
        ...state,
        submitting: action.payload,
      };
    case 'SET_BLOOD_TYPES':
      return {
        ...state,
        bloodTypes: action.payload,
      };
    case 'SET_VACCINES':
      return {
        ...state,
        vaccinesOptions: action.payload,
      };
    case 'SET_FORM_API':
      return {
        ...state,
        formApi: action.payload,
      };
    case 'GET_CONTACTS':
      return {
        ...state,
        contacts: action.payload,
      };
    case 'GET_VACCINES':
      return {
        ...state,
        vaccines: action.payload,
      };
    case 'GET_QUESTIONS':
      return {
        ...state,
        questions: action.payload,
      };
    case 'SET_HEALTH_INSURRANCE':
      return {
        ...state,
        hasHealthInsurrance: action.payload,
      };
    case 'SET_MEDICATION_QUESTION':
      return {
        ...state,
        hasMedication: action.payload,
      };
    case 'SET_FILES':
      return {
        ...state,
        vaccinesExams: [...vaccinesExams, action.payload],
      };
    case 'REMOVE_FILES':
      return {
        ...state,
        vaccinesExams: action.payload,
      };
    default:
      return state;
  }
};

export default HealthRecordReducer;
