import React from 'react';
import { Icon } from './';
import PropTypes from 'prop-types';

class Ball extends React.Component {
  render() {
    const { iconWidth, ballLabel, icon, small, bgColor, extraSmall } = this.props;
    return(
      <div className={`ball-style bg-${bgColor} ${small && 'small'} ${extraSmall && 'extra-small'}`}>
      {
        ballLabel && 
        <h2>
          {ballLabel}
        </h2>
      }
      {
        icon &&
        <Icon name={icon} width={iconWidth} />
      }
      </div>
    );
  }
}

Ball.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
  ballLabel: PropTypes.string,
  icon: PropTypes.string,
  small: PropTypes.bool,
  extraSmall: PropTypes.bool,
}

Ball.defaultProps = {
  bgColor: 'gray'
}

export default Ball;
