import React, { Fragment } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import * as Talk from 'talkjs';
import { toast } from 'react-toastify';
import { Spinner } from '../elements';
import { API_URL, CHAT_ID } from '../../lib/const';
import { getAxiosConfig } from '../../lib/auth';
import ChatInbox from '../components/ChatInbox'

class Financers extends React.Component {
  state = {
    loading: true,
  }

  componentDidMount() {
    this.fetchSupport();
    this.fetchContacts()
  }

  fetchSupport() {
    const { history } = this.props;
    axios.get(`${API_URL}/core/support`, getAxiosConfig())
      .then((response) => {
        this.setState({
          currentUser: get(response, 'data.support_user'),
          signature: get(response, 'data.signature'),
        }, () => this.loadChat());
      })
      .catch(error => {
        toast.error('Você não tem permissão para entrar nesta página', {position: toast.POSITION.BOTTOM_RIGHT});
        history.push('/login');
      })
  }

  loadChat() {
    const { currentUser, signature } = this.state
    Talk.ready
      .then(() => {
        const me = new Talk.User(currentUser);

        const talkSession = new Talk.Session({
          appId: CHAT_ID,
          me,
          signature,
        });
        var inbox = talkSession.createInbox();
        inbox.mount(document.getElementById("inbox-container"))
          .then(() => this.setState({ loading: false }))
      });
  }

  fetchNextPage(pageUrl) {

    const { targetUser } = this.state

    if(pageUrl){
      axios.get(pageUrl, getAxiosConfig()).then(response => {
        response.data.results.map(res => {
          targetUser.push({id: res.enrollment.token, name: res.real_name })
        })
        this.setState({
          targetUser
        })
        this.fetchNextPage(response.data.next)
      })
    }
  }


  fetchContacts() {
    axios.get(`${API_URL}/personal/personal-data/approvals`, getAxiosConfig())
      .then(response => {
        const target = []
        response.data.results.map(res => {
          target.push({id: res.enrollment.token, name: res.real_name })
        })
        this.setState({
          targetUser: target
        })
        this.fetchNextPage(response.data.next)
      })
      .catch(() => {
        toast.error('Você não tem permissão para entrar nesta página', {position: toast.POSITION.BOTTOM_RIGHT});
      })
  }


  render() {
    const { currentUser, loading, targetUser } = this.state;
    return (
      <Fragment>
        {/* <div className="container">
          <section className="one-page-only">
            <div className="title-button-page mb-5">
              <h2 style={{ fontSize: '1.35rem' }}>Suporte</h2>
              <Link className="btn btn-primary" to="/dashboard" style={{ maxWidth: 150 }}> Voltar</Link>
            </div>
            <Spinner active={loading}>
              <div>
                <div id="inbox-container" style={{ width: '90%', margin: 30, height: 500 }}><i>Loading chat...</i></div>
              </div>

            </Spinner>
          </section>
        </div> */}
          {targetUser && <ChatInbox sourceUser={currentUser} supportUser={targetUser} client="SUPPORT"/>}
      </Fragment>
    );
  }
}

export default Financers;
