import React from 'react';
import Rodal from 'rodal';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Modal extends React.Component {
  modalStyle = {
    width: '1000px',
    height: '700px',
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
  }

  state = {
    rotate: 0,
    isImage: false,
  };

  handleRotate = () => {
    this.setState((prevState) => {
      const { rotate } = prevState;
      const nextRotate = (rotate + 180) % 360;
      return ({ rotate: nextRotate });
    });
  }

  renderByFileType = () => {
    const { rotate, isImage } = this.state;
    const { title, src } = this.props;

    if (isImage) {
      return (
        <>
          <div className="document-container h-100">
            <img className={`document-image rotate${rotate}`} src={src} alt={title} />
          </div>
        </>
      );
    }

    return (
      <iframe
        src={src}
        name="documents"
        title="modalFile"
        frameBorder="0"
        scrolling="no"
        width="100%"
        height="100%"
      />
    );
  }

  componentDidUpdate = (prevProps) => {
    const { title } = this.props;
    const previousTitle = prevProps.title;
    if (previousTitle !== title) {
      const checkedFileName = typeof title === 'string' ? title : '';
      const imageTypes = ['JPG', 'PNG', 'JPEG'];
      const isImage = imageTypes.some(imageType => checkedFileName.toUpperCase().includes(imageType));
      this.setState({ isImage });
    }
  }

  render() {
    const {
      visible, onClose, title,
    } = this.props;
    const { isImage } = this.state;

    return (
      <Rodal customStyles={this.modalStyle} visible={visible} onClose={onClose} closeOnEsc height={700} width={1000}>
        <div className="modal-header">
          {title}
          {isImage && (
            <button className="rotate-button" type="button" onClick={this.handleRotate}>
              <FontAwesomeIcon icon="sync-alt" />
            </button>
          )}
        </div>
        <div className="modal-body">
              {this.renderByFileType()}
            </div>
            <div className="modal-footer">
              <button type="button" onClick={onClose} className="btn-none blue">Ok</button>
            </div>
      </Rodal>
    );
  }
}

Modal.propTypes = {
            visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export default Modal;
