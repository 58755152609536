import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Scope,
} from 'informed';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import HealthRecordReducer from '../reducers/HealthRecordReducer';
import InputTypeText from '../elements/InputTypeText';
import {
  FieldSet,
  InputTypeDropdown,
  InputTel,
  InputTypeArea,
} from '../elements';
import { API_URL } from '../../lib/const';
import HealthVaccines from '../enroll/HealthVaccines';
import HealthContacts from '../enroll/HealthContacts';
import HealthContents from '../enroll/HealthContents';
import HealthQuestions from '../enroll/HealthQuestions';
import RadioButtonsAnswer from '../elements/RadioButtonsAnswer';


const HealthRecord = ({
  backClick,
  onNext,
  match,
  isAcademic,
  onEdit,
}) => {
  const initialState = {
    contacts: [],
    vaccines: [],
    questions: [],
    vaccinesOptions: [],
    submitting: false,
    hasHealthInsurrance: false,
    hasMedication: false,
    bloodTypes: [],
    formApi: null,
    vaccinesExams: [],
  };

  const { token } = match.params;

  const [state, dispatch] = useReducer(HealthRecordReducer, initialState);

  const {
    contacts,
    questions,
    vaccines,
    submitting,
    bloodTypes,
    vaccinesOptions,
    formApi,
    hasHealthInsurrance,
    hasMedication,
    vaccinesExams,
  } = state;

  const fetchData = async () => {
    const res = await axios.get(`${API_URL}/health/health-data/${token}`);
    dispatch({
      type: 'GET_CONTACTS',
      payload: res.data.contacts.map(contact => contact),
    });
    dispatch({
      type: 'GET_VACCINES',
      payload: res.data.vaccines.filter(vaccine => vaccine.health_vaccine),
    });
    const { data } = res;
    return data;
  };

  const fetchQuestions = async () => {
    const params = {
      page_size: 1000,
    };
    const res = await axios.get(`${API_URL}/health/questions`, { params });
    const questionData = res.data.results;
    dispatch({
      type: 'GET_QUESTIONS',
      payload: questionData,
    });
    return questionData;
  };

  const handleData = () => {
    if (formApi) {
      axios.all([
        fetchData(),
        fetchQuestions(),
      ])
        .then(([data, questionData]) => {
          const newQuestions = questionData.map((question) => {
            const answeredQuestion = data.questions.find(dataQuestion => dataQuestion.health_question.id === question.id);
            return answeredQuestion ? {
              ...answeredQuestion, ...question, health_question: question.id, id: answeredQuestion.id,
            }
              : {
                answer: false, health_question: question.id, description: question.description, observation: '',
              };
          });
          const newVaccines = (
            get(data, 'vaccines', []).filter(v => v.health_vaccine).map(vaccine => ({ ...vaccine, health_vaccine: get(vaccine, 'health_vaccine.id') }))
          );
          if (get(data, 'has_medications')) {
            dispatch({ type: 'SET_MEDICATION_QUESTION', payload: get(data, 'has_medications') });
          }
          if (get(data, 'has_insurrance')) {
            dispatch({ type: 'SET_HEALTH_INSURRANCE', payload: get(data, 'has_insurrance') });
          }
          const dataValues = {
            ...data,
            questions: newQuestions.map(question => ({ ...question, answer: question.answer } || { answer: 'false' })),
            vaccines: newVaccines,
          };
          data.vaccines.forEach((vaccine, vaccine_index) => {
            if (vaccine.exam) {
              dispatch({ type: 'SET_FILES', payload: { ...vaccine.exam, vaccine_index } });
            }
          });
          formApi.setValues(dataValues);
        });
    }
  };

  const fetchBloodTypes = async () => {
    const res = await axios.get(`${API_URL}/health/blood-types`);
    dispatch({
      type: 'SET_BLOOD_TYPES',
      payload: res.data.map(bloodType => ({
        label: bloodType.name,
        value: bloodType.id,
      })),
    });
  };

  const fetchVaccines = async () => {
    const params = {
      page_size: 1000,
    };

    const res = await axios.get(`${API_URL}/health/vaccines`, { params });
    dispatch({
      type: 'SET_VACCINES',
      payload: res.data.results.map(vaccine => ({
        ...vaccine,
        label: vaccine.name,
        value: vaccine.id,
      })),
    });
  };

  useEffect(() => {
    fetchBloodTypes();
    fetchVaccines();
  }, []);

  useEffect(() => {
    handleData();
  }, [formApi]);

  const setLoading = type => dispatch({ type: 'SET_SUBMITTING', payload: type });

  const handleContact = () => {
    const contactsCopy = [...contacts];
    contactsCopy.push({});
    dispatch({
      type: 'HANDLE_CONTACTS',
      payload: contactsCopy,
    });
  };

  const handleVaccines = () => {
    const vaccinesCopy = [...vaccines];
    vaccinesCopy.push({});
    dispatch({
      type: 'HANDLE_VACCINES',
      payload: vaccinesCopy,
    });
  };

  const handleRemoveContacts = (i) => {
    const { values } = formApi.getState(state);
    if (values.contacts) {
      const contactsWithoutSelectedContact = values.contacts.filter((_, index) => index !== i);
      formApi.setValues({ ...values, contacts: contactsWithoutSelectedContact });
      const updatedValues = formApi.getState().values ? formApi.getState().values.contacts : [];
      dispatch({
        type: 'HANDLE_CONTACTS',
        payload: updatedValues,
      });
    } else {
      dispatch({
        type: 'HANDLE_CONTACTS',
        payload: [],
      });
    }
  };

  const handleRemoveVaccines = (i) => {
    const { values } = formApi.getState();
    if (values.vaccines) {
      const vaccinesWithoutSelectedVaccine = values.vaccines.filter((_, index) => index !== i);
      const updateVaccineIndex = vaccinesWithoutSelectedVaccine.map((vaccine, vaccine_index) => (
        { ...vaccine, exam: vaccine.exam ? { ...vaccine.exam, vaccine_index } : undefined }
      ));
      formApi.setValues({ ...values, vaccines: updateVaccineIndex });
      const updatedValues = formApi.getState().values ? formApi.getState().values.vaccines : [];
      dispatch({
        type: 'REMOVE_FILES',
        payload: updateVaccineIndex.map(updateExam => updateExam.exam),
      });
      dispatch({
        type: 'GET_VACCINES',
        payload: updatedValues,
      });
    } else {
      dispatch({
        type: 'REMOVE_FILES',
        payload: [],
      });
      dispatch({
        type: 'GET_VACCINES',
        payload: [],
      });
    }
  };

  const handleSendVaccineDocument = (fileData) => {
    const { values } = formApi.getState();
    const updateFiles = values.vaccines.map((vaccine, vaccine_index) => (
      vaccine_index === fileData.vaccine_index ? { ...vaccine, exam: fileData } : vaccine
    ));
    formApi.setValues({ ...values, vaccines: updateFiles });
    dispatch({ type: 'SET_FILES', payload: fileData });
  };

  const handleRemoveFile = (index) => {
    const filesArray = vaccinesExams.filter(file => (file.vaccine_index !== index));
    dispatch({ type: 'REMOVE_FILES', payload: filesArray });
  };

  const submit = async () => {
    const { values } = formApi.getState();
    const notNullVaccines = values.vaccines ? values.vaccines.filter(vaccine => vaccine) : [];
    const notNullContacts = values.contacts ? values.contacts.filter(contact => contact) : [];
    const notNullQuestions = values.questions ? values.questions.filter(question => question) : [];
    const notNullVaccinesExams = vaccinesExams ? vaccinesExams.filter(vaccinesExam => vaccinesExam) : [];
    const vaccinesWithFiles = notNullVaccines.map((vaccine, index) => (
      {
        ...vaccine,
        vaccines_doses: get(vaccine, 'vaccines_doses', []).map(vaccine_dose => ({ ...vaccine_dose, date: vaccine_dose.date || null })),
        expiration_date: vaccine.expiration_date || null,
        health_vaccine: +vaccine.health_vaccine,
        exam: notNullVaccinesExams.find(f => (f.vaccine_index === index)),
      }
    ));
    const params = {
      ...values,
      vaccines: vaccinesWithFiles,
      contacts: notNullContacts.map(contact => contact),
      questions: notNullQuestions.map(question => question),
    };
    setLoading(true);
    try {
      await axios.put(`${API_URL}/health/health-data/${token}`, params);
      onNext();
    } catch (err) {
      toast.error('Ocorreu um erro ao tentar enviar o formulário.');
    }
    setLoading(false);
  };

  const handleHealthInsurranceValue = (value) => {
    dispatch({ type: 'SET_HEALTH_INSURRANCE', payload: value });
  };

  const handleMedicationQuestion = (value) => {
    dispatch({ type: 'SET_MEDICATION_QUESTION', payload: value });
  };

  return (
    <Form
      getApi={api => dispatch({ type: 'SET_FORM_API', payload: api })}
    >
      <div className="row">
        <InputTypeText
          col="12"
          label="Em caso de emergência, levar para qual Hospital?"
          field="hospital"
        />
      </div>
      <div className="row">
        <InputTypeDropdown
          col="4"
          field="blood_type"
          label="Tipo Sanguíneo"
          options={bloodTypes}
        />
      </div>
      <div className="row">
        <RadioButtonsAnswer
          col="12"
          field="has_insurrance"
          onValueChange={handleHealthInsurranceValue}
          label="Você possui Plano de Saúde?"
        />
        {
          hasHealthInsurrance && (
            <>
              <InputTypeText
                col="4"
                label="Matrícula do plano de saúde"
                field="health_insurrance_enrollment"
              />
              <InputTel
                col="4"
                label="Telefone do plano de saúde"
                field="health_insurrance_phone"
              />
              <InputTypeText
                col="4"
                label="Plano de saúde"
                field="health_insurrance"
              />
            </>
          )
        }
      </div>
      <HealthContents
        title="Em caso de emergência, quem devemos contactar?"
        buttonLabel="Adicionar novo contato"
        onClick={handleContact}
      >
        {
          contacts.map((contact, index) => (
            <Scope key={contact} scope={`contacts[${index}]`}>
              <HealthContacts
                index={index}
                onRemoveContacts={handleRemoveContacts}
              />
              <hr />
            </Scope>
          ))
        }
      </HealthContents>
      <HealthContents
        title="Cadastro de vacinas"
        buttonLabel="Adicionar nova vacina"
        onClick={handleVaccines}
      >
        {
          (vaccines.map((vaccine, index) => (
            <Scope key={vaccine} scope={`vaccines[${index}]`}>
              <HealthVaccines
                vaccinesOptions={vaccinesOptions}
                onRemoveVaccines={handleRemoveVaccines}
                vaccineIndex={index}
                vaccine={vaccine}
                sendDocumentData={fileData => handleSendVaccineDocument(fileData)}
                onRemoveFile={handleRemoveFile}
              />
              <hr />
            </Scope>
          )))
        }
        <small className="d-block dark-silver mt-3">
          *Caso não possua o cartão de vacina atualizado, o candidato deverá apresentar exames que comprovem sua
          imunização ou deverá procurar unidade de saúde para que sejam administradas as doses solicitadas das vacinas recomendadas.
        </small>
      </HealthContents>
      <FieldSet title="Questionário" />
      {
        questions.map((question, index) => (
          <Scope scope={`questions[${index}]`}>
            <HealthQuestions
              question={question}
            />
          </Scope>
        ))
      }
      <div className="row">
        <RadioButtonsAnswer
          field="has_medications"
          label="Utiliza alguma medicação de uso contínuo? Quais?"
          onValueChange={handleMedicationQuestion}
        />
        {
          hasMedication && (
            <InputTypeArea
              label="Medicações"
              col="10"
              field="medications"
            />
          )
        }
        <hr />
      </div>
      {
        isAcademic ? (
          <div className="btn-actions">
            <button
              type="button"
              className="float-button mr-6"
              disabled={submitting}
              onClick={() => onEdit()}
            >
              <FontAwesomeIcon
                icon="times"
              />
            </button>
            <button
              className="float-button"
              type="submit"
              onClick={submit}
              disabled={submitting}
            >
              <FontAwesomeIcon
                icon="check"
              />
            </button>
          </div>
        ) : (
          <div className="text-right">
            <div className="col-md-4 p-0 offset-md-8 col-sm-12">
              <div className="btn-actions">
                <button
                  className="btn btn-black mr-2"
                  type="submit"
                  onClick={() => backClick()}
                >
                  Voltar
                </button>
                <button
                  disabled={submitting}
                  className="btn btn-primary"
                  type="button"
                  onClick={submit}
                >
                  {submitting ? 'Carregando...' : 'Próximo'}
                </button>
              </div>
            </div>
          </div>
        )
      }
    </Form>
  );
};

HealthRecord.propTypes = {
  backClick: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
  isAcademic: PropTypes.bool,
  onEdit: PropTypes.func,
};

HealthRecord.defaultProps = {
  isAcademic: false,
  onEdit: () => {},
};

export default withRouter(HealthRecord);
