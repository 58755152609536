import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import defaultPicture from '../../../assets/img/people.svg';
import { FieldSet, KeyValue } from '../../elements';
import HandicapSummary from './HandicapSummary';

const PersonalDataSummary = ({ personalData }) => (
  <>
    <FieldSet title="Dados Pessoais" />
    <div className="mb-5 text-center">
      <img src={get(personalData, 'profile_img_url') || defaultPicture} className="people-img" alt="people" />
    </div>
    <div className="enrollment-flex">
      <KeyValue title={get(personalData, 'real_name')} label="Nome" />
      <KeyValue title={get(personalData, 'assumed_name')} label="Nome Social" />
      <KeyValue title={get(personalData, 'nationality')} label="Nacionalidade" />
      <KeyValue title={get(personalData, 'birth_country')} label="Pais de Origem" />
      {get(personalData, 'birth_state') && <KeyValue title={get(personalData, 'birth_state')} label="Estado de Nascimento" />}
      {get(personalData, 'birth_city') && <KeyValue title={get(personalData, 'birth_city')} label="Naturalidade" />}
      {get(personalData, 'cpf') && <KeyValue title={get(personalData, 'cpf')} label="CPF" />}
      {get(personalData, 'rne') && <KeyValue title={get(personalData, 'rne')} label="RNE" />}
      <KeyValue title={get(personalData, 'birth_date')} label="Data de Nascimento" />
      <KeyValue title={get(personalData, 'marital_status')} label="Estado Civil" />
      <KeyValue title={get(personalData, 'gender')} label="Sexo" />
      {get(personalData, 'rg') && <KeyValue title={get(personalData, 'rg')} label="RG" />}
      {get(personalData, 'rg_issuing_body') && <KeyValue title={get(personalData, 'rg_issuing_body')} label="Orgão Emissor" />}
      {get(personalData, 'rg_issue_data') && <KeyValue title={get(personalData, 'rg_issue_date')} label="Data de Expedição do RG" />}
      <KeyValue title={get(personalData, 'electoral_card')} label="Titulo de Eleitor" />
      {get(personalData, 'military_certificate') && <KeyValue title={get(personalData, 'military_certificate')} label="Certificado Militar" />}
    </div>
    <FieldSet title="Filiação" />
    <div className="enrollment-flex">
      <KeyValue title={get(personalData, 'fathers_name')} label="Nome do Pai" />
      <KeyValue title={get(personalData, 'fathers_cpf')} label="CPF do Pai" />
      <KeyValue title={get(personalData, 'fathers_email')} label="Email do Pai" />
      <KeyValue title={get(personalData, 'fathers_phone')} label="Telefone do Pai" />
    </div>
    <div className="enrollment-flex">
      <KeyValue title={get(personalData, 'mothers_name')} label="Nome da Mãe" />
      <KeyValue title={get(personalData, 'mothers_cpf')} label="CPF da Mãe" />
      <KeyValue title={get(personalData, 'mothers_email')} label="Email da Mãe" />
      <KeyValue title={get(personalData, 'mothers_phone')} label="Telefone da Mãe" />
    </div>
    <FieldSet title="Contato" />
    <div className="enrollment-flex">
      <KeyValue title={get(personalData, 'email')} label="E-mail" />
      <KeyValue title={get(personalData, 'phone_number')} label="Telefone" />
      <KeyValue title={get(personalData, 'landline')} label="Telefone 2" />
      <KeyValue title={get(personalData, 'extra_phone_number')} label="Telefone 3" />
    </div>
    <div className="enrollment-flex">
      <KeyValue title={get(personalData, 'zip_code')} label="CEP" />
      <KeyValue title={get(personalData, 'street_address')} label="Logradouro" />
      <KeyValue title={get(personalData, 'address_number')} label="Número" />
      <KeyValue title={get(personalData, 'complement_address')} label="Complemento" />
      <KeyValue title={get(personalData, 'neighborhood')} label="Bairro" />
      <KeyValue title={get(personalData, 'city')} label="Cidade" />
      <KeyValue title={get(personalData, 'state')} label="Estado" />
    </div>
    <FieldSet title="Dados do curso" />
    <div className="enrollment-flex">
      <KeyValue title={get(personalData, 'course_name')} label="Curso" />
      <KeyValue title={get(personalData, 'enrollment.onboarding.curriculum')} label="Currículo" />
      <KeyValue title={get(personalData, 'enrollment.onboarding.field_step')} label="Ano de promoção" />
      <KeyValue title={get(personalData, 'admission_type')} label="Tipo de Ingresso" />
    </div>
    <HandicapSummary
      handicap={get(personalData, 'handicap')}
      disabilities={get(personalData, 'disabilities')}
      special_needs={get(personalData, 'special_needs')}
    />
  </>
);

PersonalDataSummary.propTypes = {
  personalData: PropTypes.shape({
    profile_img_url: PropTypes.string,
    real_name: PropTypes.string,
    assumed_name: PropTypes.string,
    nationality: PropTypes.string,
    birth_country: PropTypes.string,
    birth_state: PropTypes.string,
    birth_city: PropTypes.string,
    cpf: PropTypes.string,
    rne: PropTypes.string,
    birth_date: PropTypes.string,
    marital_status: PropTypes.string,
    gender: PropTypes.string,
    rg_issuing_body: PropTypes.string,
    rg_issue_date: PropTypes.string,
    electoral_card: PropTypes.string,
    military_certificate: PropTypes.string,
    fathers_name: PropTypes.string,
    mothers_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    landline: PropTypes.string,
    extra_phone_number: PropTypes.string,
    zip_code: PropTypes.string,
    street_address: PropTypes.string,
    address_number: PropTypes.string,
    complement_address: PropTypes.string,
    neighborhood: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    curriculum: PropTypes.string,
  }).isRequired,
};

export default PersonalDataSummary;
