// @ts-ignore
import { Radio, RadioGroup, withFormApi } from 'informed';
import React from 'react';
import { FieldSet } from '../../elements';
import { IFormApi, IPaymentMethod } from '../types';


type PaymentMethodFormProps = {
  paymentMethods: IPaymentMethod[],
  formApi: IFormApi
};

const PaymentMethodForm = ({ formApi, paymentMethods }: PaymentMethodFormProps) => {
  const error = formApi ? formApi.getError('payment_method') : undefined;

  return (
    <FieldSet title="Método de pagamento">
      <div className="list-base">
        <RadioGroup style={{ display: 'flex' }} field="payment_method">
          <div id="payment-method-options" className="radio-group">
            {paymentMethods.map(h => (
              <label key={h.id} className="input-radio-type" htmlFor={`payment-method-${h.id}`}>{h.name}
                <Radio value={h.id} id={`payment-method-${h.id}`} />
                <span className="radiomark" />
              </label>
            ))}
          </div>
        </RadioGroup>
        {error && <label className="red" htmlFor="payment-method-options"> {error}</label>}
      </div>
    </FieldSet>
  );
};


export default withFormApi(PaymentMethodForm);
