import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldSet } from '../../elements';

const PendencyList = ({ pendencies, loadingPendencies }) => (
  <>
    <FieldSet title="Pendências" />
    {loadingPendencies ? (
      <div className="text-center"><FontAwesomeIcon className="green" icon="spinner" pulse /></div>
    ) : (
      <>
        {pendencies.map(p => (
          <div className="pendencies">
            <div className="pendencies-header">
              {p.resolved_at && <FontAwesomeIcon icon="check-circle" className="green mt-1 mr-2" />}
              <div className="mb-2">
                <label className="pendencies-header-title">Pendência {p.id}:</label>
                <small className="d-block "><em>{p.step}</em></small>
              </div>
            </div>
            <div className="pendencies-body">
              {p.description}
            </div>
            <div className="pendencies-date">{p.created_at}</div>
          </div>
        ))}
      </>
    )}
  </>
);

PendencyList.propTypes = {
  pendencies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    step: PropTypes.string,
    description: PropTypes.string,
    created_at: PropTypes.string,
  })).isRequired,
  loadingPendencies: PropTypes.bool.isRequired,
};

export default PendencyList;
