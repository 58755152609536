import { withFormApi } from 'informed';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { MARITIAL_STATUS } from '../../lib/config';
import { FieldSet, InputBirth, InputCpf, InputTypeDropdown, InputTypeText } from '../elements';

class GuarantorsGeneralDataForm extends React.Component {
  renderGuarantorCPFField = (rg = true, fieldPrefix = '') => {
    const { formApi, guarantorIndex, nationalities } = this.props;
    const { values } = formApi.getState();
    const guarantor = get(values, `guarantors.${guarantorIndex}`);

    if (guarantor) {
      const nationality = nationalities.find(g => +guarantor[`${fieldPrefix}nationality`] === +g.id);
      if (nationality) {
        if (nationality.check_native) {
          return (
            <>
              <InputCpf field={`${fieldPrefix}cpf`} label="CPF" col="4" helper="Ex: 000.000.000-00" required />
              {rg && (
                <>
                  <InputTypeText field="rg" label="RG" col="4" required />
                  <InputTypeText field="rg_issuing_body" label="Órgão Emissor" col="4" required />
                  <InputBirth field="rg_issue_date" label="Data de Expedição do RG" col="4" required />
                </>
              )}
            </>
          );
        }
        return <InputTypeText field={`${fieldPrefix}rne`} label="RNE" col="4" required />;
      }
    }
    return null;
  }

  shouldShowSpouse = () => {
    const { formApi, guarantorIndex, maritalPropertySystems } = this.props;
    const { values } = formApi.getState();
    const guarantor = get(values, `guarantors.${guarantorIndex}`);
    if (guarantor) {
      const maritalPropertySystem = maritalPropertySystems.find(g => +guarantor.marital_property_system === +g.id);
      return maritalPropertySystem && maritalPropertySystem.check_spouse;
    }
    return false;
  }

  render() {
    const {
      formApi, nationalities, maritalStatuses, guarantorIndex, maritalPropertySystems,
    } = this.props;
    const { values } = formApi.getState();

    return (
      <>
        <FieldSet title="Dados Gerais">
          <InputTypeText field="name" label="Nome" col="4" required />
          <InputTypeDropdown field="nationality" label="Nacionalidade" col="4" required options={nationalities} />
          {this.renderGuarantorCPFField()}
          <InputBirth field="birth_date" label="Data de Nascimento" col="4" required />
          <InputTypeDropdown field="marital_status" label="Estado Civil" col="4" options={maritalStatuses} required />
        </FieldSet>
        {
          +get(values, `guarantors[${guarantorIndex}].marital_status`, null) === MARITIAL_STATUS.MARRIED && (
            <FieldSet title="Cônjuge">
              <InputTypeDropdown field="marital_property_system" label="Regime de Casamento" col="4" options={maritalPropertySystems} required />
              {this.shouldShowSpouse() && (
                <>
                  <InputTypeText field="spouse_name" label="Nome do Cônjuge" col="4" required />
                  <InputTypeDropdown field="spouse_nationality" label="Nacionalidade do cônjuge" col="4" required options={nationalities} />
                  {this.renderGuarantorCPFField(false, 'spouse_')}
                  <InputTypeText field="spouse_email" label="E-mail do Cônjuge" col="4" required />
                </>
              )}
            </FieldSet>
          )
        }
      </>
    );
  }
}

GuarantorsGeneralDataForm.propTypes = {
  nationalities: PropTypes.arrayOf(PropTypes.object),
  maritalStatuses: PropTypes.arrayOf(PropTypes.object),
  maritalPropertySystems: PropTypes.arrayOf(PropTypes.object),
  guarantorIndex: PropTypes.number.isRequired,
  formApi: PropTypes.shape({
    getState: PropTypes.func,
  }).isRequired,
};

GuarantorsGeneralDataForm.defaultProps = {
  nationalities: [],
  maritalStatuses: [],
  maritalPropertySystems: [],
};

export default withFormApi(GuarantorsGeneralDataForm);
