import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Form } from 'informed';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API_URL } from '../../lib/const';
import { InputTypeArea } from '../elements';
import { EnrollmentSummaryFinancer, FinanceDataForm } from '../forms';
import { getAxiosConfig } from '../../lib/auth';
import { FinancersBase } from '../components';
import { fetchDocuments } from '../services/documents_services';

class FinancerSummary extends FinancersBase {
  state = {
    showPendencies: false,
    form: {},
    id: null,
    status_id: null,
    pendencies: [],
    hasPendencies: false,
    loadingPendencies: true,
    submittingPendencies: false,
    isEditing: false,
    pendingDocument: null
  };

  get token() {
    const { match: { params: { token } } } = this.props;
    return token;
  }

  getData(id, status_id) {
    this.setState({ id, status_id }, this.fetchData);
  }

  fetchData() {
    const { id } = this.state;
    const dadosFinanceiros = 'Dados financeiros';
    axios.get(`${API_URL}/onboarding/pendencies`,
      {
        params: {
          enrollment: id,
        },
        ...getAxiosConfig(),
      }).then((response) => {
        this.setState({
          pendencies: response.data.filter(p => p.step === dadosFinanceiros),
          loadingPendencies: false,
        });
      })
      .catch((error) => {
        this.setState({
          loadingPendencies: false,
        });
        this.handleError(error);
      });
  }

  handlePendency() {
    const { form, id, pendingDocument } = this.state;
    const dadosFinanceiros = 3;
    if(pendingDocument){
        axios.patch(`${API_URL}/documents/documents-data/${this.token}/review_document`, { document_id: pendingDocument.id, approved: false })
          .then(() => {
            toast.success('Documento reprovado!');
            this.setState({pendingDocument:null, showPendencies:false})
            this.fetchData();
          })
          .catch(e => {
            console.error(e)
            return toast.error("Algo deu errado.")
          })
    }
    axios
      .post(`${API_URL}/onboarding/pendencies`, { ...form, enrollment: id, step: dadosFinanceiros })
      .then(() => {
        this.fetchData();
        this.setState({
          showPendencies: false,
          hasPendencies: true,
        });
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao criar pendência. Por favor tente novamente mais tarde');
      });
  }

  handleDocumentApproval = (pendingDoc) =>{
    if (!window.confirm('Tem certeza de que deseja aprovar o documento')) return

    axios.patch(`${API_URL}/documents/documents-data/${this.token}/review_document`, { document_id: pendingDoc.id, approved: true })
      .then(() => {
        toast.success('Documento aprovado!')
        this.fetchData()
      })
      .catch(e => {
        console.error(e)
        return toast.error("Algo deu errado.")
      })

  }

  handleDocumentRepproval = pendingDoc => {
    this.setState({ pendingDocument:pendingDoc, showPendencies:true })
  }

  sendPendencies() {
    this.setState({ submittingPendencies: true });
    return axios.post(`${API_URL}/onboarding/enrollments/${this.token}/pendency`, {}, getAxiosConfig())
      .then((response) => {
        this.fetchData();
        toast.success('Pendências enviadas com sucesso!');
        this.setState({
          submittingPendencies: false,
          hasPendencies: false,
        });
        return Promise.resolve(response);
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao enviar a(s) pendência(s). Por favor tente novamente mais tarde.');
        this.setState({
          submittingPendencies: false,
          loadingPendencies: false,
        });
      });
  }

  render() {
    const {
      showPendencies, pendencies, status_id, hasPendencies, loadingPendencies, submittingPendencies,
      isEditing,
    } = this.state;

    return (
      <div className="container">
        <div className="summary-header">
          <h2 style={{ fontSize: '1.25rem', marginTop: '4rem' }}>Resumo do Aluno</h2>
        </div>
        <div className="one-page-only">
          {
            isEditing ? (
              <>
                <FinanceDataForm
                  isEditing={isEditing}
                  onEdit={() => this.setState({ isEditing: false })}
                  onClick={() => this.setState({ isEditing: false })}
                />
              </>
            ) : (
              <EnrollmentSummaryFinancer
                getData={(id, statusId) => this.getData(id, statusId)}
                handle={() => this.sendPendencies()}
                pendencies={pendencies}
                hasPendencies={hasPendencies}
                loadingPendencies={loadingPendencies}
                submittingPendencies={submittingPendencies}
                reloadPendencies={() => this.fetchData()}
                onDocumentApproval={this.handleDocumentApproval}
                onDocumentReproval={this.handleDocumentRepproval}
              />
            )
          }
        </div>
        {
          (!isEditing && status_id === 2) && (
            <>
              <button
                type="button"
                className="float-button mr-6"
                onClick={() => this.setState({ isEditing: true })}
              >
                <FontAwesomeIcon
                  icon="edit"
                />
              </button>
              <button type="button" className="float-button" onClick={() => this.setState({ showPendencies: true })}>
                <i className="fa fa-plus" />
              </button>
            </>
          )
        }
        <CSSTransition
          in={showPendencies}
          timeout={300}
          classNames="expand-animation"
          unmountOnExit
        >
          <aside className="financer-float">
            <button className="btn-none float-right" type="button" onClick={() => this.setState({ showPendencies: false, pendingDocument:null })}>
              <FontAwesomeIcon icon="times" />
            </button>
            <h4 className="mt-3 bt-3">Pendências</h4>
            <hr />
            <Form
              id="pendencias"
              onValueChange={form => this.setState({ form })}
            >
              <InputTypeArea field="description" initialValue={`Pendência para documento ${this.state.pendingDocument ? this.state.pendingDocument.name : ""}: \n `} label="Descrição" row="10" />
              <button type="submit" className="btn btn-primary" onClick={() => this.handlePendency()}>Criar Pendência</button>
            </Form>
          </aside>
        </CSSTransition>
      </div>
    );
  }
}

export default withRouter(FinancerSummary);
