const HealthDetailReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING_PAGE':
      return {
        ...state,
        loadingPage: action.payload,
      };
    case 'SET_PERSONAL_DATA':
      return {
        ...state,
        personalData: action.payload,
      };
    case 'SET_HEALTH_DATA':
      return {
        ...state,
        healthData: action.payload,
      };
    case 'SET_DOCUMENT_TYPES':
      return {
        ...state,
        documentTypes: action.payload,
      };
    case 'SET_DOCUMENTS':
      return {
        ...state,
        documents: action.payload,
      };
    case 'SET_EDITING':
      return {
        ...state,
        isEditing: !state.isEditing,
      };
    case 'SET_CONFIRMATION_MODAL':
      return {
        ...state,
        confirmationModal: !state.confirmationModal,
      };
    default: return state;
  }
};

export default HealthDetailReducer;
