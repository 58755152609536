import React from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { formatCPF } from '../../lib/utils';
import TableFilterForm from '../forms/TableFilterForm';
import withTable, { TableContext } from '../hoc/withTable';
import Table from '../components/Table';
import { fetchPersonalDataApprovals } from '../services/personal_data_service';
import { InputDropdownCourse, InputDropdownStatus } from '../elements';

const NAAPPage = ({ onFilterChange }) => {
  const columns = [
    {
      Header: 'Nome',
      accessor: 'real_name',
      Cell: d => (
        <Link
          style={{
            overflow: 'visible',
            whiteSpace: 'normal',
          }}
          to={`/naap/${get(d, 'original.enrollment.token')}`}
        >
          <p>{d.value}</p>
          {formatCPF(d)}
        </Link>
      ),
    },
    {
      Header: 'Curso',
      accessor: 'enrollment.onboarding.course.name',
      Cell: d => (
        <div>
          <p className="m-0">{get(d, 'value')}</p>
          <p className="m-0">{get(d, 'original.enrollment.onboarding.field_step')}º Ano/Período de promoção</p>
        </div>
      ),
    },
    {
      Header: 'Status do candidato',
      accessor: 'enrollment.status.name',
      Cell: d => (
        <div
          style={{
            overflow: 'visible', whiteSpace: 'normal',
          }}
        >
          {d.value}
        </div>
      ),
    },
    {
      Header: 'Forma de ingresso',
      accessor: 'enrollment.onboarding.admission.name',
      Cell: d => (
        <div
          style={{
            overflow: 'visible', whiteSpace: 'normal',
          }}
        >
          {d.value}
        </div>
      ),
    },
    {
      Header: 'Aprovação do NAAP',
      accessor: 'enrollment.naap_status.name',
      Cell: d => (
        <div
          style={{
            overflow: 'visible', whiteSpace: 'normal',
          }}
        >
          {d.value}
        </div>
      ),
    },
  ];

  return (
    <section>
      <div className="list-base list-base-width" style={{ height: '100vh' }}>
        <TableFilterForm
          onFilterChange={onFilterChange}
          title="Aprovação - NAAP"
        >
          <InputDropdownStatus col="6" />
          <InputDropdownCourse col="6" />
        </TableFilterForm>
        <TableContext.Consumer>
          {props => <Table columns={columns} {...props} />}
        </TableContext.Consumer>
      </div>
    </section>
  );
};

export default withTable(fetchPersonalDataApprovals)(NAAPPage);
