import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup } from 'informed';

const RadioButtonsAnswer = ({
  label,
  field,
  onValueChange,
  col,
}) => (
  <RadioGroup field={field} onValueChange={onValueChange}>
    <div className={`radio-group col-${col}`}>
      <p className="radio-label">{label}</p>
      <div className="radio-input">
        <label className="input-radio-type">
          <span className="label-input">Sim</span>
          <Radio value={true} />
          <span className="radiomark" />
        </label>
        <label className="input-radio-type">
          <span className="label-input">Não</span>
          <Radio value={false} />
          <span className="radiomark" />
        </label>
      </div>
    </div>
  </RadioGroup>
);

RadioButtonsAnswer.propTypes = {
  label: PropTypes.string,
  field: PropTypes.string,
  col: PropTypes.string,
  onValueChange: PropTypes.func,
};

RadioButtonsAnswer.defaultProps = {
  label: '',
  field: '',
  col: '10',
  onValueChange: () => {},
};

export default RadioButtonsAnswer;
