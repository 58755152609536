import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { get } from 'lodash';
import { PermissionContext } from '../../../hoc/withPermission';
import { API_URL } from '../../../lib/const';
import ImportErrors from '../../components/imports/ImportErrors';
import InputSelect from '../../components/InputSelect';


class ImportsBase extends React.Component {
  state = {
    importFeedback: null,
    importing: false,
    files: [],
    error: null,
    activeImport: null,
  }

  handleImport = () => {
    const { activeImport } = this.state;
    this.setState({
      importing: true,
      error: null,
      errorMessage: null,
      importFeedback: null,
    });
    const form = new FormData();
    const { files } = this.state;
    form.append('file', files[0]);
    axios.post(`${API_URL}${activeImport.endpoint}`, form, {
      headers: { 'Content-Type': 'form/multipart-form-data' },
    })
      .then((response) => {
        const error_file = get(response, 'data.error_file');
        if (error_file) {
          const a = document.createElement('a');
          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${error_file}`;
          a.href = linkSource;
          a.setAttribute('download', `resultado_${files[0].name}`);
          a.click();
        }
        this.setState({
          importFeedback: 'Importação concluída.',
          importing: false,
        });
      })
      .catch((err) => {
        let errorMessage;
        let error;

        if (!err.response || err.response.status !== 400) {
          errorMessage = 'Ocorreu um erro inesperado. Certifique-se de que as regras acima foram seguidas.';
        } else if (err.response.data.message) {
          errorMessage = err.response.data.message;
        } else {
          error = err.response.data;
        }

        this.setState({ error, errorMessage, importing: false });
      });
  }

  handleFileChange = (e) => {
    this.setState({ files: e.target.files });
  }

  render() {
    const { data } = this.props;
    const {
      importFeedback, importing, files, error, errorMessage, activeImport,
    } = this.state;
    const importButtonIsDisabled = importing || files.length === 0;
    const setNonFieldErrors = (error && error.non_field_errors) ? new Set(error.non_field_errors) : [];
    const nonFieldErrors = [...setNonFieldErrors];

    return (
      <PermissionContext.Consumer>
        {({ hasPermission }) => (
          <div>
            <p>Selecione um tipo importação para carregar as regras:</p>
            <InputSelect
              value={activeImport}
              onChange={value => this.setState({ activeImport: value, importFeedback: null })}
              options={data.filter(d => !d.permission || hasPermission(d.permission))}
              placeholder="Tipo de importação"
              className="width-270 mb-2"
              getOptionLabel={d => d.title}
              getOptionValue={d => d.id}
            />
            {
              activeImport && (
                <>
                  {activeImport.ruleset}
                  <hr />
                  <div className="d-flex justify-content-between mt-3">
                    <input type="file" className="mt-1" onChange={this.handleFileChange} />
                    <button
                      className="btn btn-primary ml-3 w-25"
                      disabled={importButtonIsDisabled}
                      onClick={this.handleImport}
                      type="button"
                    >
                      Importar
                    </button>
                  </div>
                </>
              )
            }
            {importing && <div><i className="fa fa-circle-notch fa-spin" /> Importando...</div>}
            {errorMessage && <p className="danger">{errorMessage}</p>}
            {nonFieldErrors.length > 0 && (
              <ul className="danger">
                {nonFieldErrors.map(err => <li key={err}>{err}</li>)}
              </ul>
            )}
            {error && !error.non_field_errors && Object.keys(error).map(err => (
              <ImportErrors key={err} rows={error[err]} tabName={err} />
            ))}
            {importFeedback && <p className="success">{importFeedback}</p>}
          </div>
        )}
      </PermissionContext.Consumer>
    );
  }
}

ImportsBase.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    endpoint: PropTypes.string,
    title: PropTypes.string,
    ruleset: PropTypes.object,
    permission: PropTypes.string,
  })),
};

ImportsBase.defaultProps = {
  data: [],
};

export default ImportsBase;
