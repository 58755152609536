import axios from 'axios';
import { API_URL } from '../../../../lib/const';


const fetchOnboardings = (params = {}) => (
  axios.get(`${API_URL}/onboardings`, { params })
    .then(response => response.data)
);

export default fetchOnboardings;
