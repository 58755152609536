import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import { InputTypeText } from '../elements';

const TableFilterForm = ({ title, children, onFilterChange }) => {
  const [isFilterShowing, setFilterShowing] = useState(false);
  return (
    <div>
      <div className="d-flex align-items-center position-relative">
        <h2 style={{ fontSize: '1.35rem' }}>{title}</h2>
        <button type="button" onClick={() => setFilterShowing(!isFilterShowing)} className="btn-invisible table-filters">Mais filtros {isFilterShowing ? '▲' : '▼'}</button>
      </div>
      <Form
        onValueChange={filters => onFilterChange(filters)}
      >
        <div className="row">
          <InputTypeText
            field="real_name"
            label="Nome"
            col="6"
          />
          <InputTypeText
            field="cpf"
            label="CPF"
            col="6"
          />
        </div>
        <div className="row">
          {isFilterShowing && children}
        </div>
      </Form>
    </div>
  );
};

TableFilterForm.propTypes = {
  children: PropTypes.node,
  onFilterChange: PropTypes.func,
  title: PropTypes.string,
};

TableFilterForm.defaultProps = {
  children: null,
  onFilterChange: () => {},
  title: 'Aprovação - Financeira',
};

export default TableFilterForm;
