import axios from 'axios';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import SweetAlert from 'sweetalert2-react';
import defaultPicture from '../../assets/img/people.svg';
import { GUARANTEE_TYPE } from '../../lib/config';
import { API_URL } from '../../lib/const';
import { Modal } from '../components';
import DocumentViewer from '../components/DocumentViewer';
import { FieldSet, KeyValue, Spinner } from '../elements';
import DocumentsView from '../enrollment/components/DocumentsView';
import HealthData from '../enrollment/components/HealthData';
import { fetchDocuments, fetchDocumentTypesWithEquivalents } from '../services/documents_services';
import { fetchHealthData } from '../services/health_services';

class BaseEnrollmentSummary extends React.Component {
  state = {
    loading: true,
    show: false,
    showSuccess: false,
    showModal: false,
    data: [],
    financeData: [],
    personalDocumentsTypes: [],
    personalDocuments: [],
    healthData: {},
    fileSrc: null,
  }

  componentDidMount() {
    Promise.all(
      [
        this.fetchData(),
        this.fetchFinancerData(),
        this.fetchHealthData(),
        this.fetchDocuments(),
      ],
    ).finally(() => this.setState({ loading: false }));
  }

  handleModal(document) {
    axios.get(document.url)
      .then(response => this.setState({
        showModal: true,
        fileSrc: get(response, 'data.url'),
      })).catch(() => this.setState({ showModal: false }));
  }

  fetchDocuments() {
    const { token } = this.props;
    fetchDocuments(token)
      .then((data) => {
        this.setState({ personalDocuments: data.documents });
        this.fetchDocumentTypes();
      });
  }

  fetchDocumentTypes() {
    const { token } = this.props;
    fetchDocumentTypesWithEquivalents(token)
      .then(data => this.setState({ personalDocumentsTypes: data }));
  }


  fetchHealthData() {
    const { token } = this.props;
    fetchHealthData(token)
      .then(data => this.setState({ healthData: data }))
  }

  fetchData() {
    const { token } = this.props;
    axios.get(`${API_URL}/onboarding/personal-data/${token}/summary`)
      .then(response => this.setState({ data: response.data }));
  }

  fetchFinancerData() {
    const { token } = this.props;

    axios.get(`${API_URL}/finance/finance-data/${token}/summary`)
      .then(response => this.setState({ financeData: response.data }));
  }

  render() {
    const {
      onClick,
      token,
      onDocumentReproval,
      onDocumentApproval
    } = this.props;
    const {
      loading,
      data,
      show,
      showSuccess,
      confirmMessage,
      financeData,
      showModal,
      fileSrc,
      healthData,
      personalDocumentsTypes,
      personalDocuments,
    } = this.state;
    const CASADO = 'Casado(a)';
    const SEPARACAOTOTAL = 'Separação Total de Bens';
    const regularStudent = !get(data, 'full_fies_or_prouni', false);
    return (
      <Spinner active={loading}>
        <div className="enrollment-summary mt-4">
          <FieldSet title="Dados Pessoais" />
          <div className="mb-5 text-center">
            <img src={get(data, 'profile_img_url') || defaultPicture} className="people-img" alt="people" />
          </div>
          <div className="enrollment-flex">
            <KeyValue title={get(data, 'real_name')} label="Nome" />
            <KeyValue title={get(data, 'assumed_name')} label="Nome Social" />
            <KeyValue title={get(data, 'nationality')} label="Nacionalidade" />
            <KeyValue title={get(data, 'birth_country')} label="Pais de Origem" />
            {get(data, 'birth_state') && <KeyValue title={get(data, 'birth_state')} label="Estado de Nascimento" />}
            {get(data, 'birth_city') && <KeyValue title={get(data, 'birth_city')} label="Naturalidade" />}
            {get(data, 'cpf') && <KeyValue title={get(data, 'cpf')} label="CPF" />}
            {get(data, 'rne') && <KeyValue title={get(data, 'rne')} label="RNE" />}
            <KeyValue title={get(data, 'birth_date')} label="Data de Nascimento" />
            <KeyValue title={get(data, 'marital_status')} label="Estado Civil" />
            <KeyValue title={get(data, 'gender')} label="Sexo" />
            {get(data, 'rg') && <KeyValue title={get(data, 'rg')} label="RG" />}
            {get(data, 'rg_issuing_body') && <KeyValue title={get(data, 'rg_issuing_body')} label="Orgão Emissor" />}
            {get(data, 'rg_issue_data') && <KeyValue title={get(data, 'rg_issue_date')} label="Data de Expedição do RG" />}
            <KeyValue title={get(data, 'electoral_card')} label="Titulo de Eleitor" />
            {get(data, 'military_certificate') && <KeyValue title={get(data, 'military_certificate')} label="Certificado Militar" />}
          </div>
          <FieldSet title="Filiação" />
          <div className="enrollment-flex">
            <KeyValue title={get(data, 'fathers_name')} label="Nome do Pai" />
            <KeyValue title={get(data, 'fathers_cpf')} label="CPF do Pai" />
            <KeyValue title={get(data, 'fathers_email')} label="Email do Pai" />
            <KeyValue title={get(data, 'fathers_phone')} label="Telefone do Pai" />
          </div>
          <div className="enrollment-flex">
            <KeyValue title={get(data, 'mothers_name')} label="Nome da Mãe" />
            <KeyValue title={get(data, 'mothers_cpf')} label="CPF da Mãe" />
            <KeyValue title={get(data, 'mothers_email')} label="Email da Mãe" />
            <KeyValue title={get(data, 'mothers_phone')} label="Telefone da Mãe" />
          </div>
          <FieldSet title="Contato" />
          <div className="enrollment-flex">
            <KeyValue title={get(data, 'email')} label="E-mail" />
            <KeyValue title={get(data, 'phone_number')} label="Telefone" />
            <KeyValue title={get(data, 'landline')} label="Telefone 2" />
            <KeyValue title={get(data, 'extra_phone_number')} label="Telefone 3" />
          </div>
          <div className="enrollment-flex">
            <KeyValue title={get(data, 'zip_code')} label="CEP" />
            <KeyValue title={get(data, 'street_address')} label="Logradouro" />
            <KeyValue title={get(data, 'address_number')} label="Número" />
            <KeyValue title={get(data, 'complement_address')} label="Complemento" />
            <KeyValue title={get(data, 'neighborhood')} label="Bairro" />
            <KeyValue title={get(data, 'city')} label="Cidade" />
            <KeyValue title={get(data, 'state')} label="Estado" />
          </div>
          <FieldSet title="Dados do curso" />
          <div className="enrollment-flex">
            <KeyValue title={get(data, 'course_name')} label="Curso" />
            <KeyValue title={get(data, 'enrollment.onboarding.curriculum')} label="Currículo" />
            <KeyValue title={get(data, 'enrollment.onboarding.field_step')} label="Ano de promoção" />
            <KeyValue title={get(data, 'admission_type')} label="Tipo de Ingresso" />
          </div>
          {regularStudent && (
            <>
              <FieldSet title="Financeiro" />
              <div className="enrollment-flex">
                <KeyValue title={get(financeData, 'plan.name')} label="Plano" />
                <KeyValue title={get(financeData, 'plan_value')} label="Total" />
                <KeyValue title={get(financeData, 'plan.installments')} label="Parcelas" />
                <KeyValue title={get(financeData, 'plan.due_date')} label="Vencimentos" />
                {get(financeData, 'guarantee_type') && (<KeyValue title={get(financeData, 'guarantee_type.name')} label="Tipo de garantia" />)}
                <KeyValue title={get(financeData, 'payment_method.name')} label="Método de Pagamento" />
              </div>
              {get(financeData, 'guarantee_type.id') === GUARANTEE_TYPE.LETTER_GUARANTEE && (
                <>
                  <FieldSet title="Carta Fiança" />
                  <div className="enrollment-flex">
                    <KeyValue title={get(financeData, 'bank_name')} label="Banco" />
                  </div>
                  {get(financeData, 'letter_guarantee') && <DocumentViewer centralizeContent documents={[get(financeData, 'letter_guarantee')]} />}
                </>
              )}
              {
                get(financeData, 'guarantee_type.id') === GUARANTEE_TYPE.GUARANTOR && data.guarantors && get(data, 'guarantors').map((g, i) => (
                  <>
                    <FieldSet title={`Fiador ${i + 1}`} />
                    <div className="enrollment-flex">
                      <KeyValue title={get(g, 'name')} label="Nome" />
                      <KeyValue title={get(g, 'nationality')} label="Nacionalidade" />
                      {get(g, 'cpf') && <KeyValue title={get(g, 'cpf')} label="CPF" />}
                      {get(g, 'rne') && <KeyValue title={get(g, 'rne')} label="RNE" />}
                      <KeyValue title={get(g, 'birth_date')} label="Data de Nascimento" />
                      {get(g, 'rg') && <KeyValue title={get(g, 'rg')} label="RG" />}
                      {get(g, 'rg_issuing_body') && <KeyValue title={get(g, 'rg_issuing_body')} label="Orgão Emissor" />}
                      {get(g, 'rg_issue_date') && <KeyValue title={get(g, 'rg_issue_date')} label="Data de Expedição do RG" />}
                      <KeyValue title={get(g, 'marital_status')} label="Estado Civil" />
                    </div>
                    {
                      get(g, 'marital_status', null) === CASADO && (
                        <div className="enrollment-flex">
                          <KeyValue title={get(g, 'marital_property_system')} label="Regime de Casamento" />
                          {
                            get(g, 'marital_property_system') !== SEPARACAOTOTAL && (
                              <>
                                <KeyValue title={get(g, 'spouse_name')} label="Nome do Cônjugue" />
                                <KeyValue title={get(g, 'spouse_nationality')} label="Nacionalidade do Cônjugue" />
                                {get(g, 'spouse_cpf') ? <KeyValue title={get(g, 'spouse_cpf')} label="CPF do Cônjugue" /> : <KeyValue title={get(g, 'spouse_rne')} label="RNE do Cônjugue" />}
                                <KeyValue title={get(g, 'spouse_email')} label="Email do Cônjugue" />
                              </>
                            )
                          }
                        </div>
                      )
                    }
                    <div className="enrollment-flex">
                      <KeyValue title={get(g, 'email')} label="email" />
                      <KeyValue title={get(g, 'phone_number')} label="Telefone 1" />
                      <KeyValue title={get(g, 'landline')} label="Telefone 2" />
                      <KeyValue title={get(g, 'zip_code')} label="CEP" />
                      <KeyValue title={get(g, 'street_address')} label="Logradouro" />
                      <KeyValue title={get(g, 'address_number')} label="Número" />
                      <KeyValue title={get(g, 'complement_address')} label="Complemento" />
                      <KeyValue title={get(g, 'neighborhood')} label="Bairro" />
                      <KeyValue title={get(g, 'state')} label="Estado" />
                      <KeyValue title={get(g, 'city')} label="Cidade" />
                    </div>
                    {
                      g.documents.length > 0 && (
                        <>
                          <hr />
                          <h6>Documentos Fiador {i + 1}</h6>
                          <DocumentViewer
                            documents={g.documents}
                            token={token}
                            onDocumentReproval={onDocumentReproval}
                            onDocumentApproval={onDocumentApproval}

                          />
                        </>
                      )
                    }
                  </>
                ))
              }
            </>
          )}
        </div>
        <HealthData
          healthData={healthData}
        />
        {personalDocuments.length > 0 && (
          <>
            <FieldSet title="DOCUMENTOS" />
            <div className="documents-base row">
              <DocumentsView
                audittableDocuments
                personalDocumentsTypes={personalDocumentsTypes}
                personalDocuments={personalDocuments}
                onDocumentReproval={onDocumentReproval}
                onDocumentApproval={onDocumentApproval}
              />
            </div>
          </>
        )}
        <SweetAlert
          show={show}
          type={showSuccess ? 'success' : 'error'}
          text={confirmMessage}
          onConfirm={showSuccess ? onClick : () => this.setState({ show: false })}
        />
        <Modal visible={showModal} onClose={() => this.setState({ showModal: false })} title={document.name} src={fileSrc} />
      </Spinner>
    );
  }
}

BaseEnrollmentSummary.propTypes = {
  token: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDocumentApproval: PropTypes.func,
  onDocumentReproval: PropTypes.func
};

BaseEnrollmentSummary.defaultProps = {
  onClick: () => null,
};

export default BaseEnrollmentSummary;
