import React from 'react';
import { Select, asField, Option } from 'informed';


export default asField(({
  required, helper, value, options, fieldState, field, label, col, helpBlock, numbers, autosaveFeedBack, ...props
}) => (
  <div className={`col-md-${col} col-sm-12`} style={{ display: 'flex' }}>
    <div className="input-type-style" style={{ display: 'flex' }}>
      <Select
        field={field}
        id={field}
        className={`dropdown-style ${fieldState.value !== undefined && 'animation'} ${fieldState.error && 'input-error'}`}
        fieldState={fieldState}
        disabled={!options}
        {...props}
      >
        <Option value="" />
        {
          options.map(option => (
            <Option value={option.value}>
              {option.label}
            </Option>
          ))
      }
      </Select>
      {helper && <label className="input-helper">{helper}</label>}
      <label
        htmlFor={field}
        className={`input-label-style ${required && 'required'} ${fieldState.error && 'label-error'}`}
      >
        {label}
      </label>
      <span className="arrow-select-pointer ">
        ▼
      </span>
      {fieldState.error && fieldState.error.map(e => <label key={e} className="input-helper text-danger">{e}</label>)}
    </div>
    <div style={{ display: 'flex', marginTop: '40px', marginLeft: '5px' }}>
      {autosaveFeedBack}
    </div>
  </div>
));
