import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import SidebarItem from './SidebarItem';
import SidebarItemOption from './SidebarItemOption';
import { menu } from '../../lib/menu';
import withPermission from '../../hoc/withPermission';

class SidebarMenu extends React.Component {
  state = {
    collapsed: false,
    isSidebarOpen: false,
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  handleClick = () => {
    const { onCollapse } = this.props;
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
    onCollapse();
  }

  updateDimensions = () => {
    const width = window.innerWidth;
    const mediumSizeScreen = 1200;

    this.setState({
      collapsed: width <= mediumSizeScreen,
    });
  }

  render() {
    const { collapsed, isSidebarOpen } = this.state;
    const width = window.innerWidth;
    const smallSizeScreen = 768;

    if (width > smallSizeScreen) {
      return (
        <aside className={`sidebar-base ${collapsed ? 'collapsed' : ''}`}>
          <div className="sidebar-action">
            {!collapsed && <span className="sidebar-text">{menu.name}</span>}
            <button type="button" className="btn-none" onClick={this.handleClick}>
              <FontAwesomeIcon icon={faBars} className="icon-sidebar" />
            </button>
          </div>
          <ul className="menu-list">
            {menu.sections.map(section => (
              <SidebarItem key={section.id} label={section.name} icon={section.icon} collapsed={collapsed}>
                {section.options.map(option => {
                  if (!option.permission || this.props.hasPermission(option.permission)) {
                    return (
                      <SidebarItemOption
                        key={option.id}
                        to={option.url || ''}
                      >
                        {option.name}
                      </SidebarItemOption>
                    )
                  }
                  return null;
                })}
              </SidebarItem>
            ))}
          </ul>
        </aside>
      );
    }
    return (
      <nav className="nav-mobile">
        <button type="button" className="btn-none" onClick={() => this.setState({ isSidebarOpen: true, collapsed: false })}>
          <FontAwesomeIcon icon={faBars} className="font-22" />
        </button>
        <ul className={`menu-list ${isSidebarOpen ? 'open' : null}`}>
          <button type="button" className="btn-none close-sidebar" onClick={() => this.setState({ isSidebarOpen: false, collapsed: true })}>
            <FontAwesomeIcon icon="times" />
            <span className="ml-2">Fechar</span>
          </button>
          <div className="menu-elements">
            {menu.sections.map(section => (
              <SidebarItem key={section.id} label={section.name} icon={section.icon} collapsed={collapsed}>
                {section.options.map(option => {
                  if (!option.permission || this.props.hasPermission(option.permission)) {
                    return (
                      <SidebarItemOption
                        key={option.id}
                        to={option.url || ''}
                        onClick={() => this.setState({ collapsed: true, isSidebarOpen: false })}
                      >
                        {option.name}
                      </SidebarItemOption>
                    )
                  }
                  return null;
                })}
              </SidebarItem>
            ))}
          </div>
          <button type="button" onClick={this.logout} className="btn-none">
            Sair
          </button>
        </ul>
      </nav>
    );
  }
}

export default withPermission(SidebarMenu);
