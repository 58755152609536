import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';


const EnrollmentActionButtons = ({
  history, disabled, loading, loadingMessage, confirmLabel, onConfirmClick, backLabel,
  onBackClick, showConfirmButton,
}) => {
  const defaultHandleBackClick = () => history.push('/financers');
  return (
    <div className="text-right" style={{ marginBottom: '2.5rem' }}>
      <div className="mt-5" style={{ display: 'flex' }}>
        <div className="col-md-4 p-0 offset-md-8 col-sm-12">
          <div className="btn-actions">
            <button type="button" className="btn btn-black mr-2" onClick={onBackClick || defaultHandleBackClick}>{backLabel}</button>
            {showConfirmButton && <button className="btn btn-primary" type="submit" disabled={disabled} onClick={onConfirmClick}>{loading ? loadingMessage : confirmLabel}</button>}
          </div>
        </div>
      </div>
    </div>
  );
};

EnrollmentActionButtons.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  confirmLabel: PropTypes.string,
  backLabel: PropTypes.string,
  onConfirmClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func,
  showConfirmButton: PropTypes.bool.isRequired,
};

EnrollmentActionButtons.defaultProps = {
  disabled: false,
  loading: false,
  loadingMessage: 'Carregando',
  confirmLabel: 'Aprovar',
  backLabel: 'Voltar',
  onBackClick: null,
};

export default withRouter(EnrollmentActionButtons);
