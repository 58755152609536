import { get } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CONTRACT_STATUS, ENROLLMENT_STATUS, GUARANTEE_TYPE } from '../../../lib/config';
import { API_URL } from '../../../lib/const';
import Boleto from '../../components/boleto/Boleto';
import ComponentWithToken from '../../components/CompontentWithToken';
import Modal from '../../components/ConfirmationModal';
import ContractMonitor from '../../components/ContractMonitor';
import DownloadButton from '../../components/DownloadButton';
import { FieldSet, Spinner } from '../../elements';
import { approveFinanceData, approveProof } from '../../services/approval_services';
import fetchContract from '../../services/contract_services';
import { fetchFinanceDataSummary } from '../../services/finance_data_service';
import { createPendency, fetchPendencies, sendPendencies } from '../../services/pendecy_services';
import { fetchPersonalDataSummary } from '../../services/personal_data_service';
import { showModal } from '../../services/upload_service';
import { MessageContext } from '../../v2/common/states/MessageState';
import CreatePendecyForm from '../components/CreatePendecyForm';
import EnrollmentActionButtons from '../components/EnrollmentActionButtons';
import EnrollmentPendingFieldSet from '../components/EnrollmentPedingFieldSet';
import FinanceDataSummary from '../components/FinanceDataSummary';
import LetterGuaranteeDetail from '../components/LetterGuaranteeDetail';
import PendencyList from '../components/PendecyList';
import PersonalDataSummary from '../components/PersonalDataSummary';


class EnrollmentDetailPage extends ComponentWithToken {
  state = {
    financeData: {},
    personalData: {},
    enrollment: {},
    loading: true,
    regularStudent: false,
    resubmittingContract: false,
    showResubmit: false,
    contract: null,
    showModal: false,
    fileSrc: null,
    content: {
      name: null,
    },
    showPendencyForm: false,
    pendencyForm: {},
    fetchingPendencies: true,
    pendencies: [],
    showConfirmationModal: false,
    showConfirmationModalProof: false,
    approvingData: false,
  };

  shouldFetchContract = () => Object.values(CONTRACT_STATUS).some(contractStatusId => contractStatusId === get(this.state.enrollment, 'contract_status_id'));

  shouldShowLetterGuarantee = () => get(this.state.financeData, 'guarantee_type.id') === GUARANTEE_TYPE.LETTER_GUARANTEE;

  shouldShowPendingFieldSet = () => {
    const pendingStatuses = [
      ENROLLMENT_STATUS.FILLING,
      ENROLLMENT_STATUS.PENDING,
      ENROLLMENT_STATUS.NOT_STARTED,
      ENROLLMENT_STATUS.ACCESSED,
    ];

    return pendingStatuses.some(status => get(this.state.enrollment, 'status.id') === status);
  }

  shouldShowPendencies = () => get(this.state.enrollment, 'status.id') === ENROLLMENT_STATUS.PENDING || get(this.state.enrollment, 'status.id') === ENROLLMENT_STATUS.REVIEWING;

  shouldShowBoleto = () => {
    const boletoStatuses = [
      ENROLLMENT_STATUS.WAITING_PAYMENT,
      ENROLLMENT_STATUS.PAYMENT_REVIEWING,
      ENROLLMENT_STATUS.FINALIZED,
    ];

    return boletoStatuses.some(status => get(this.state.enrollment, 'status.id') === status);
  }

  shouldShowProofsOfPayments = () => {
    const ProofsOfPaymentsStatuses = [
      ENROLLMENT_STATUS.PAYMENT_REVIEWING,
      ENROLLMENT_STATUS.FINALIZED,
    ];

    return ProofsOfPaymentsStatuses.some(status => get(this.state.enrollment, 'status.id') === status);
  }

  shouldApproveEnrollment = get(this.state.enrollment, 'status.id') === ENROLLMENT_STATUS.PAYMENT_REVIEWING && get(this.state.enrollment, 'contract_status.id') === CONTRACT_STATUS.DONE;

  getComponentsToShow = () => {
    const { regularStudent } = this.state;

    const props = {
      showPersonalDataSummary: true,
      showFinanceDataSummary: regularStudent,
      showBoleto: this.shouldShowBoleto(),
      showLetterGuarantee: this.shouldShowLetterGuarantee(),
      showContract: this.shouldFetchContract(),
      showPendingFieldSet: this.shouldShowPendingFieldSet(),
      showCreatePendencyForm: get(this.state.enrollment, 'status.id') === ENROLLMENT_STATUS.REVIEWING,
      showPendencies: this.shouldShowPendencies(),
      showProofsOfPayments: this.shouldShowProofsOfPayments() && regularStudent,
    };

    return props;
  }

  fetchPendencies = () => fetchPendencies().then((data) => {
    this.setState({
      pendencies: data,
      fetchingPendencies: false,
    });
  })

  handleCreatePendency = () => {
    const { pendencyForm, enrollment } = this.state;
    createPendency(enrollment, { ...pendencyForm }).then(() => {
      this.fetchPendencies();
      this.setState({
        showPendencyForm: false,
        hasPendencies: true,
      });
    });
  }

  getActionButtonProps = () => {
    const props = {
      disabled: false,
      loading: false,
      loadingMessage: 'Carregando',
      confirmLabel: 'Aprovar',
      onConfirmClick: () => null,
      showConfirmButton: false,
    };

    const {
      enrollment, pendencies, submittingPendencies, approvingData, showConfirmationModalProof,
    } = this.state;
    const hasPendencies = pendencies.filter(f => !f.resolved_at).length > 0;

    switch (get(enrollment, 'status.id')) {
      case ENROLLMENT_STATUS.REVIEWING:
        if (hasPendencies) {
          props.onConfirmClick = this.sendPendencies;
          props.confirmLabel = 'Enviar todas pendências';
          props.loadingMessage = 'Enviando...';
          props.disabled = submittingPendencies;
          props.loading = submittingPendencies;
        } else {
          props.onConfirmClick = () => this.setState({ showConfirmationModal: true });
          props.confirmLabel = 'Aprovar';
          props.loadingMessage = 'Aprovando...';
          props.disabled = approvingData;
          props.loading = approvingData;
        }
        props.showConfirmButton = true;
        break;
      case ENROLLMENT_STATUS.PAYMENT_REVIEWING:
        props.onConfirmClick = () => this.setState({ showConfirmationModalProof: true });
        props.confirmLabel = 'Aprovar';
        props.loadingMessage = 'Carregando...';
        props.disabled = showConfirmationModalProof || !this.shouldApproveEnrollment;
        props.loading = showConfirmationModalProof;
        props.showConfirmButton = true;
        break;
      default:
        break;
    }
    return props;
  }

  approveFinanceData = () => {
    this.setState({ loading: true, showConfirmationModal: false });

    approveFinanceData(this.token).then(() => this.componentDidMount())
      .catch(() => this.setState({ loading: false }));
  };

  sendPendencies = () => {
    this.setState({ loading: true, submittingPendencies: true, loadingPendencies: true });

    sendPendencies(this.token).then(() => {
      toast.success('Pendências enviadas com sucesso!');
      this.setState({ submittingPendencies: false }, this.componentDidMount);
    }).catch(() => {
      toast.error('Ocorreu um erro ao enviar a(s) pendência(s). Por favor tente novamente mais tarde.');
      this.setState({
        submittingPendencies: false,
        loadingPendencies: false,
        loading: false,
      });
    });
  };

  handleApproveProof = () => {
    this.setState({ loading: true, showConfirmationModalProof: false });
    const data = { approve_proof: true };

    approveProof(this.token, data)
      .then(() => {
        this.componentDidMount();
      })
      .catch((error) => {
        toast.error(get(error, 'response.data[0]', 'Ocorreu um erro inesperado'), { position: toast.POSITION.BOTTOM_RIGHT });
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount = () => {
    this.setState({ loading: true });

    Promise.all([
      this.fetchFinanceDataSummary(),
      this.fetchPersonalDataSummary(),
      this.fetchPendencies(),
    ]).finally(() => this.setState({ loading: false }));
  }

  fetchContractDetails = () => fetchContract().then(data => this.setState({ contract: data }));

  fetchPersonalDataSummary = () => fetchPersonalDataSummary(this.token).then((data) => {
    this.setState({
      personalData: data,
      regularStudent: !get(data, 'full_fies_or_prouni', false),
      enrollment: get(data, 'enrollment'),
    }, () => {
      if (this.shouldFetchContract()) this.fetchContractDetails();
    });
  });

  fetchFinanceDataSummary = () => fetchFinanceDataSummary(this.token).then(data => this.setState({ financeData: data }))

  onClose = () => this.setState({ showModal: false })

  showModal = file => showModal(file).then(data => this.setState({ ...data }))

  render() {
    const {
      showPersonalDataSummary, showFinanceDataSummary, showContract, showLetterGuarantee,
      showPendingFieldSet, showCreatePendencyForm, showPendencies, showBoleto, showProofsOfPayments,
    } = this.getComponentsToShow();
    const {
      financeData, personalData, loading, contract, resubmittingContract, showResubmit,
      enrollment, showPendencyForm, pendencies, fetchingPendencies, showConfirmationModal,
      showConfirmationModalProof,
    } = this.state;

    return (
      <div className="container">
        <div className="summary-header">
          <h2 style={{ fontSize: '1.25rem', marginTop: '4rem' }}>Resumo do Aluno</h2>
        </div>
        <Spinner active={loading}>
          <div className="one-page-only">
            {showPersonalDataSummary && <PersonalDataSummary personalData={personalData} />}
            {showFinanceDataSummary && <FinanceDataSummary financeData={financeData} />}
            {showContract && (
              <ContractMonitor
                token={this.token}
                contract={contract}
                resubmitting={resubmittingContract}
                onResubmit={this.resubmitContract}
                showResubmit={showResubmit}
              />
            )}
            {showLetterGuarantee && <LetterGuaranteeDetail financeData={financeData} showModal={this.showModal} />}
            {showPendingFieldSet && <EnrollmentPendingFieldSet enrollment={enrollment} />}
            {showPendencies && (
              <PendencyList pendencies={pendencies} loadingPendencies={fetchingPendencies} />
            )}
            {showBoleto && <Boleto id={financeData.boleto_id} />}
            {showProofsOfPayments && (
              <>
                <FieldSet title="Comprovante de Pagamento" />
                <DownloadButton href={`${API_URL}/finance/finance-data/${this.token}/download/proofs_of_payment`} label="Baixar comprovante(s) de pagamento" />
                {!this.shouldApproveEnrollment && (
                  <>
                    <hr />
                    <em>Só é possível aprovar o aluno quando o contrato estiver completamente assinado.</em>
                  </>
                )}
              </>
            )}
            <EnrollmentActionButtons
              {...this.getActionButtonProps()}
            />
            {showCreatePendencyForm && (
              <CreatePendecyForm
                onShowForm={() => this.setState({ showPendencyForm: true })}
                onHideForm={() => this.setState({ showPendencyForm: false })}
                onCreatePendency={this.handleCreatePendency}
                onValueChange={pendencyForm => this.setState({ pendencyForm })}
                showForm={showPendencyForm}
              />
            )}
          </div>
        </Spinner>
        <Modal visible={this.state.showModal} onClose={this.onClose} title={this.state.content.name} src={this.state.fileSrc} />
        <Modal
          visible={showConfirmationModal}
          title="Confirmação"
          onCancel={() => this.setState({ showConfirmationModal: false })}
          onConfirm={this.approveFinanceData}
        >
          <MessageContext.Consumer>
            {
              ({ getMessage }) => (
                <>
                  {getMessage('APPROVE_ENROLLMENT') || 'Você tem certeza que deseja aprovar o aluno? Se sim, o contrato e o boleto, se necessário, serão enviados para o aluno e seus fiadores.?'}
                </>
              )
            }
          </MessageContext.Consumer>
        </Modal>
        <Modal
          visible={showConfirmationModalProof}
          title="Confirmação"
          onCancel={() => this.setState({ showConfirmationModalProof: false })}
          onConfirm={() => this.handleApproveProof()}
        >
          Você tem certeza que deseja concluir o processo de rematrícula do aluno?
        </Modal>
      </div>
    );
  }
}

export default withRouter(EnrollmentDetailPage);
