import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { API_URL } from '../../lib/const';

export const fetchPersonalData = token => (
  axios.get(`${API_URL}/personal/personal-data/${token}`)
    .then(response => response.data)
);

export const fetchPersonalDataSummary = token => (
  axios.get(`${API_URL}/onboarding/personal-data/${token}/summary`)
    .then(response => response.data)
);

export const validatePersonalData = (token, data) => axios.patch(`${API_URL}/personal/personal-data/${token}/validate`, data).then(response => response.data).catch(
  (error) => {
    const status = get(error, 'response.status');
    if (status === 400) {
      toast.error('Por favor, certifique-se de que todos campos foram preenchidos corretamente.', { position: toast.POSITION.BOTTOM_RIGHT });
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
    return Promise.reject(error);
  },
);

export const fetchPersonalDataApprovals = params => (
  axios.get(`${API_URL}/personal/personal-data/approvals`, {
    params,
  })
    .then(response => response.data)
);
