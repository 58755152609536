import React from 'react';
import { asField } from 'informed';
import InputAsync from './InputAsync';

export default asField(({
  fieldApi, onChange, fieldState, className, label, ...props
}) => (
  <div className={`input-type-style ${className}`}>
    <InputAsync
      className={fieldState.error ? 'input-error red' : 'dropdown-style'}
      classNamePrefix="dropdown"
      value={fieldState.value || ''}
      error={fieldState.error}
      onChange={(value) => {
        fieldApi.setError(null);
        fieldApi.setValue(value);
        if (onChange) {
          onChange(value);
        }
      }}
      {...props}
    />
    {
      label
      && <label className={`input-label-style ${fieldState.error ? 'red' : 'dim'}`}>{label}</label>
    }
  </div>
));
