import axios from 'axios';
import { API_URL } from '../../../../lib/const';


export const createInviteProcess = (params = {}) => (
  axios.post(`${API_URL}/invite-processes`, params)
    .then(response => response.data)
);

export const createInvite = (params = {}) => (
  axios.post(`${API_URL}/invites`, params)
    .then(response => response.data)
);
