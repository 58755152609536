import React from 'react';
import styled from 'styled-components';

type HtmlProps = {
  children: string | undefined
  noHighlight?: boolean,
  className?: string
}

const Html = ({ className, children }: HtmlProps) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: children || '' }} />
)

export default styled(Html)`
  ${props => props.noHighlight && `
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `}
`
