import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FieldSet } from '../../elements';
import DownloadButton from '../DownloadButton';
import BoletoStatus from './BoletoStatus';
import NewBoletoButton from './NewBoletoButton';

const BOLETO_QUERY = gql`
  query Boleto($id: ID!) {
    boleto(id: $id) {
      id
      pdfUrl
      status
      formattedTypableLine
      logs {
        id
        detailedMessage
      }
    }
  }
`;

const Boleto = ({
  id, resubmitting, onResubmit,
}) => {
  const {
    loading, error, data, refetch,
  } = useQuery(BOLETO_QUERY, { variables: { id }, errorPolicy: 'ignore' });
  const boleto = get(data, 'boleto') || {};

  useEffect(() => {
    const interval = setInterval(() => refetch(), 3000);
    return () => clearInterval(interval);
  }, []);

  if (error) {
    return (
      <>
        <FieldSet title="Boleto" />
        <p className="red">Ocorreu um erro ao carregar o boleto.</p>
        <NewBoletoButton disabled={resubmitting} onClick={onResubmit} />
      </>
    );
  }

  if (get(boleto, 'logs', []).length > 0) {
    return (
      <>
        <FieldSet title="Boleto" />
        {
          boleto.logs.map(log => (
            <p key={log.id} className="red">{log.detailedMessage}.</p>
          ))
        }
        <NewBoletoButton disabled={resubmitting} onClick={onResubmit} />
      </>
    );
  }


  if (loading || boleto.status === 'UNAVAILABLE') {
    return (
      <>
        <FieldSet title="Boleto" />
        <p>Por favor, aguarde alguns instantes até que o boleto seja gerado.</p>
        <NewBoletoButton disabled={resubmitting} onClick={onResubmit} />
      </>
    );
  }

  if (loading || boleto.status === 'UNAVAILABLE') {
    return (
      <>
        <FieldSet title="Boleto" />
        <p>Por favor, aguarde alguns instantes até que o boleto seja gerado.</p>
        <DownloadButton href={boleto.pdfUrl} label="Baixar boleto" filename="boleto.pdf" />
      </>
    );
  }
  return (
    <>
      <FieldSet title="Boleto" />
      <BoletoStatus status={boleto.status} />
      <DownloadButton href={boleto.pdfUrl} label="Baixar boleto" filename="boleto.pdf" />
      <NewBoletoButton disabled={resubmitting} onClick={onResubmit} />
    </>
  );
};

Boleto.propTypes = {
  id: PropTypes.number,
  resubmitting: PropTypes.bool,
  onResubmit: PropTypes.func,
};

Boleto.defaultProps = {
  id: undefined,
  onResubmit: () => null,
  resubmitting: false,
};

export default Boleto;
