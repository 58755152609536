import React from 'react';
import { Donut } from 'd3plus-react';
import PropTypes from 'prop-types';
import StatusCard from './StatusCard';
import Select from 'react-select'
import { get } from 'lodash';

class DashboardCourses extends React.Component {


  constructor(){
    super()
    this.state = {
      selectedCourse: null,
      selectedAdmissionType: null,
      selectedCurriculum: null
    }
    this.initialState = this.state
  }

  handleClearFilters = () =>{
    console.log(this.initiaState)
    this.setState(this.initialState, () => {
      this.props.fetchEnrollmentsByStatus({})
    })
  }

  handleSelectChange = (selected, actionMeta, field) => {


    this.setState({
      [field]: selected
    }, () => {
      const { selectedCourse, selectedCurriculum, selectedAdmissionType } = this.state;
      return this.props.fetchEnrollmentsByStatus({
        course_id: get(selectedCourse, 'value', null),
        curriculum: get(selectedCurriculum, 'value', null),
        admission_id: get(selectedAdmissionType, 'value', null)
      })
    })

  }



  render() {
    const { filterData, enrollmentsByStatus } = this.props;
    const { selectedCourse, selectedCurriculum, selectedAdmissionType } = this.state;


    const totalStudents = enrollmentsByStatus ? enrollmentsByStatus.reduce((acc, item) => acc + item.value, 0) : 0;


    const config = {
      groupBy: 'id',
      data: enrollmentsByStatus ? enrollmentsByStatus.map(enrollmentStatus => ({ id: enrollmentStatus.name, value: enrollmentStatus.value, color: enrollmentStatus.color })) : [],
      loadingHTML: 'Carregando..',
      color: d => d.color,
      duration: 0,
      legend: false,
      tooltipConfig: {
        body: d => (d.value / totalStudents).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }),
      },
    };



    return (
      <div className="bg-silver--lighter mt-4 p-4">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-xs-12 col-sm-12">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
              {filterData && <>

                <div>
                <Select
                  isClearable
                  isSearchable
                  placeholder="Selecione o curso"
                  value={selectedCourse}
                  onChange={(selected, actionMeta) => this.handleSelectChange(selected, actionMeta, 'selectedCourse')}
                  options={filterData.courses.map(course => ({ label: course.name, value: course.id }))}
                />
                </div>
                <Select
                  isSearchable
                  isClearable
                  placeholder="Selecione currículo"
                  value={selectedCurriculum}
                  onChange={(selected, actionMeta) => this.handleSelectChange(selected, actionMeta, 'selectedCurriculum')}
                  options={filterData.curriculums.map(curriculum => ({ label: curriculum, value: curriculum, key: 'selectedCurriculum' }))}
                />

                <Select
                  isClearable
                  isSearchable
                  placeholder="Selecione o tipo de ingresso"
                  value={selectedAdmissionType}
                  onChange={this.handleSelectChange}
                  onChange={(selected, actionMeta) => this.handleSelectChange(selected, actionMeta, 'selectedAdmissionType')}
                  options={filterData.admission_types.map(admission_type => ({ label: admission_type.name, value: admission_type.id, key: 'selectedAdmissionType' }))}
                />
                <button className="btn btn-primary" onClick={this.handleClearFilters}>limpar</button>
              </>

              }

            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-xs-12 col-sm-12">
            {/* {enrollmentsByStatus && <Donut config={newConfig} />} */}
            {enrollmentsByStatus ? (
              <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-6 col-sm-12">
                  <div className="height-300 mt-3">
                    {<Donut config={config} />}

                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-6 col-sm-12">
                  {enrollmentsByStatus.map(enrollmentStatus => (
                    <StatusCard key={enrollmentStatus.status} status={enrollmentStatus} />
                  ))}
                </div>
              </div>
            ) : <p className="text-center mb-0 mt-4">Selecione uma disciplina para ver as informações</p>}
          </div>
        </div>
      </div>
    );
  }
}

DashboardCourses.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object),
};

DashboardCourses.defaultProps = {
  courses: [],
  fetchEnrollmentsByStatus: () => undefined
};

export default DashboardCourses;
