import React, { Fragment } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import AutosaveFeedback from '../components/autoSaveFeedBack';
import { API_URL } from '../../lib/const';
import {
  InputTypeText, InputCep, FieldSet, InputTypeDropdown,
} from '../elements';


class Endereco extends React.Component {
  state = {
    cities: [],
    states: [],
  }

  componentDidMount() {
    setTimeout(() => {
      const { formApi, scope } = this.props;
      const { values } = formApi.getState();
      const key = scope ? `${scope}.zip_code` : 'zip_code';
      const zip_code = get(values, key);
      if (zip_code) {
        this.fetchAddress(zip_code);
      }
    }, 2000);
  }

  fetchAddress(zip_code) {
    const { formApi, scope } = this.props;
    const shouldFetchAddress = zip_code && zip_code.replace('_', '').length === 8;

    if (shouldFetchAddress) {
      axios.get(`${API_URL}/zipcode/${zip_code}/find`)
        .then((response) => {
          const address = response.data;
          this.setState({
            cities: [
              { label: address.city.name, value: address.city.id },
            ],
            states: [
              { label: address.uf, value: address.city.state },
            ],
          });
          formApi.setValue(scope ? `${scope}.zip_code` : 'zip_code', zip_code);
          if (address.street) {
            formApi.setValue(scope ? `${scope}.street_address` : 'street_address', address.street);
          }
          if (address.neighborhood) {
            formApi.setValue(scope ? `${scope}.neighborhood` : 'neighborhood', address.neighborhood);
          }
          formApi.setValue(scope ? `${scope}.state` : 'state', address.city.state);
          formApi.setValue(scope ? `${scope}.city` : 'city', address.city.id);
        });
    } else if (zip_code.length === 0) {
      formApi.setValue(scope ? `${scope}.zip_code` : 'zip_code', '');
      formApi.setValue(scope ? `${scope}.street_address` : 'street_address', '');
      formApi.setValue(scope ? `${scope}.neighborhood` : 'neighborhood', '');
      formApi.setValue(scope ? `${scope}.state` : 'state', null);
      formApi.setValue(scope ? `${scope}.city` : 'city', null);
      formApi.setValue(scope ? `${scope}.address_number` : 'address_number', '');
      formApi.setValue(scope ? `${scope}.complement_address` : 'complement_address', '');
    }
  }

  render() {
    const { cities, states } = this.state;
    const {
      fiador, fields, onValueChange, title,
    } = this.props;
    return (
      <FieldSet title={fiador ? `Endereço do Fiador ${fiador}` : title}>
        <Fragment>
          <InputCep field="zip_code" autosaveFeedBack={<AutosaveFeedback status={get(fields, 'zip_code')} />} label="CEP" col="3" helper="Ex: 30100000" onChange={e => this.fetchAddress(e.target.value)} required onValueChange={value => onValueChange('zip_code', value)} />
          <InputTypeText field="street_address" autosaveFeedBack={<AutosaveFeedback status={get(fields, 'street_address')} />} label="Logradouro" col="6" helper="Sua rua, avenida, etc." required onValueChange={value => onValueChange('street_address', value)} />
          <InputTypeText field="address_number" autosaveFeedBack={<AutosaveFeedback status={get(fields, 'address_number')} />} label="Número" maxLength="8" col="3" helper="Número da sua residência" required onValueChange={value => onValueChange('address_number', value)} />
          <InputTypeText field="complement_address" autosaveFeedBack={<AutosaveFeedback status={get(fields, 'complement_address')} />} label="Complemento" maxLength="60" col="3" onValueChange={value => onValueChange('complement_address', value)} />
          <InputTypeText field="neighborhood" autosaveFeedBack={<AutosaveFeedback status={get(fields, 'neighborhood')} />} required label="Bairro" col="3" onValueChange={value => onValueChange('neighborhood', value)} />
          <InputTypeDropdown field="state" autosaveFeedBack={<AutosaveFeedback status={get(fields, 'state')} />} label="Estado" col="3" required options={states} onValueChange={value => onValueChange('state', value)} />
          <InputTypeDropdown field="city" autosaveFeedBack={<AutosaveFeedback status={get(fields, 'city')} />} label="Cidade" col="3" helper="Cidade onde Mora" required options={cities} onValueChange={value => onValueChange('city', value)} />
        </Fragment>
      </FieldSet>
    );
  }
}

Endereco.propTypes = {
  formApi: PropTypes.shape({
    setValue: PropTypes.func,
    getState: PropTypes.func,
  }).isRequired,
  scope: PropTypes.string,
  fiador: PropTypes.string,
  fields: PropTypes.shape({
    zip_code: PropTypes.oneOf(['ok', 'pending', 'saving', 'error']),
    street_address: PropTypes.oneOf(['ok', 'pending', 'saving', 'error']),
    address_number: PropTypes.oneOf(['ok', 'pending', 'saving', 'error']),
    complement_address: PropTypes.oneOf(['ok', 'pending', 'saving', 'error']),
    neighborhood: PropTypes.oneOf(['ok', 'pending', 'saving', 'error']),
    state: PropTypes.oneOf(['ok', 'pending', 'saving', 'error']),
    city: PropTypes.oneOf(['ok', 'pending', 'saving', 'error']),
  }),
  onValueChange: PropTypes.func,
  title: PropTypes.string,
};

Endereco.defaultProps = {
  fiador: undefined,
  fields: {},
  scope: undefined,
  onValueChange: () => null,
  title: 'Endereço',
};

export default Endereco;
