import React, { Fragment } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import Rodal from 'rodal';
import { Link } from 'react-router-dom';
import { Form } from 'informed';
import { formatCPF } from '../../lib/utils';
import { InputTypeText, InputDropdownStatus, InputDropdownCourse } from '../elements';
import { API_URL } from '../../lib/const';
import { getAxiosConfig } from '../../lib/auth';
import { FinancersBase } from '../components';
import { fetchPersonalDataApprovals } from '../services/personal_data_service';
import withTable, { TableContext } from '../hoc/withTable';
import Table from '../components/Table';
import InputDropdownContractStatus from '../elements/InputDropDownContractStatus';
import InputDropdownPaymentStatus from '../elements/InputDropDownPaymentStatus';

class Financers extends FinancersBase {
  state = {
    data: [],
    statusOptions: [
      { value: 1, label: 'Aprovado' },
      { value: 2, label: 'Em análise' },
    ],
    showModal: false,
    token: null,
    loading: true,
    hasPendencies: false,
    pendencies: [],
    isFilterShowing: false,
  }

  // fetchData(form) {
  //   fetchPersonalDataApprovals
  //     .then(response => this.setState({
  //       data: response.data.results,
  //       loading: false,
  //     })).catch(this.handleError);
  // }

  fetchPendencies(id) {
    axios.get(`${API_URL}/onboarding/pendencies`,
      {
        params: {
          enrollment: id,
        },
        ...getAxiosConfig(),
      }).then(response => this.setState({ pendencies: response.data })).catch(this.handleError);
  }

  renderPendencies() {
    const { pendencies } = this.state;

    return pendencies.map(p => (
      <div className="pendencies">
        <div className="pendencies-header">
          <label className="pendencies-header-title"><strong>{p.step}</strong></label> <br />
        </div>
        <div className="pendencies-body">
          {p.description}
        </div>
        <div className="pendencies-date">{p.created_at}</div>
      </div>
    ));
  }

  showPendencies(id) {
    this.setState({
      showModal: true,
    }, () => {
      this.fetchPendencies(id);
    });
  }

  changeFilter() {
    const { isFilterShowing } = this.state;
    this.setState({ isFilterShowing: !isFilterShowing });
  }

  get columns() {
    return [
      {
        Header: 'Nome',
        accessor: 'real_name',
        width: 200,
        Cell: d => (
          <Link
            style={{
              overflow: 'visible',
              whiteSpace: 'normal',
            }}
            to={`/financers/${get(d, 'original.enrollment.token')}`}
          >
            <p>{d.value}</p>
            {formatCPF(d)}
          </Link>
        ),
      },
      {
        Header: 'Curso',
        accessor: 'enrollment.onboarding.course.name',
        width: 210,
        Cell: d => (
          <div>
            <p className="m-0">{get(d, 'value')}</p>
            <p className="m-0">{get(d, 'original.enrollment.onboarding.field_step')}º Ano/Período de promoção</p>
          </div>
        ),
      },
      {
        Header: 'Data de Envio',
        accessor: 'enrollment.sent_at',
        width: 140,
      },
      {
        Header: 'Plano de pagamento',
        accessor: 'finance_plan',
        width: 150,
        Cell: d => (
          <div
            style={{
              overflow: 'visible', whiteSpace: 'normal',
            }}
          >
            <p>{d.value}</p>
            <p className="m-0">Contrato <span style={{ textTransform: 'lowercase' }}>{get(d, 'original.contract_type')}</span></p>
          </div>
        ),
      },
      {
        Header: 'Forma de Ingresso',
        accessor: 'enrollment.onboarding.admission.name',
        width: 150,
        Cell: d => (
          <div
            style={{
              overflow: 'visible', whiteSpace: 'normal',
            }}
          >
            <p>{d.value}</p>
            <p className="m-0"><span style={{ textTransform: 'lowercase' }}>{get(d, 'name')}</span></p>
          </div>
        ),
      },
      {
        Header: 'Status do Candidato',
        accessor: 'enrollment.status.name',
        width: 143,
        Cell: d => (
          <div
            style={{
              overflow: 'visible', whiteSpace: 'normal',
            }}
          >
            {d.value}
          </div>
        ),
      },
      {
        Header: 'Status do contrato',
        accessor: 'enrollment.contract_status.name',
        width: 130,
        Cell: d => (
          <div
            style={{
              overflow: 'visible', whiteSpace: 'normal',
            }}
          >
            {d.value}
          </div>
        ),
      },
      {
        Header: 'Status do pagamento',
        accessor: 'enrollment.payment_status.name',
        width: 150,
        Cell: d => (
          <div
            style={{
              overflow: 'visible', whiteSpace: 'normal',
            }}
          >
            {d.value}
          </div>
        ),
      },
    ];
  }

  render() {
    const {
      showModal, pendencies, isFilterShowing,
    } = this.state;
    const { onFilterChange } = this.props;

    return (
      <section>
        <div className="list-base list-base-width" style={{ height: '100vh' }}>
          <div className="d-flex align-items-center position-relative">
            <h2 style={{ fontSize: '1.35rem' }}>Aprovação - Financeira</h2>
            <button type="button" onClick={() => this.changeFilter()} className="btn-invisible table-filters">Mais filtros {isFilterShowing ? '▲' : '▼'}</button>
          </div>
          <Form
            onValueChange={filters => onFilterChange(filters)}
          >
            <div className="row">
              <InputTypeText
                field="real_name"
                label="Nome"
                col="6"
              />
              <InputTypeText
                field="cpf"
                label="CPF"
                col="6"
              />
            </div>
            <div className="row">
              {
                isFilterShowing
                && (
                <Fragment>
                  <InputTypeText
                    field="field_step"
                    label="Período"
                    col="6"
                  />
                  <InputDropdownStatus
                    col="6"
                  />
                  <InputDropdownContractStatus
                    col="6"
                  />
                  <InputDropdownPaymentStatus
                    col="6"
                  />
                  <InputDropdownCourse
                    col="6"
                  />
                </Fragment>
                )
              }
            </div>
          </Form>
          <TableContext.Consumer>
            {props => <Table columns={this.columns} {...props} />}
          </TableContext.Consumer>
        </div>
        <Rodal visible={showModal} onClose={() => this.setState({ showModal: false })} width="800" height="600">
          <div className="modal-header">
            <h5>Lista de Pendências:</h5>
          </div>
          <div className="modal-body-pendencie">
            {
              pendencies.length > 0
              && this.renderPendencies()
            }
          </div>
        </Rodal>
      </section>
    );
  }
}

export default withTable(fetchPersonalDataApprovals)(Financers);
