import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const EditingButtons = ({ onEdit, loading, onSubmit }) => {
  if (loading) {
    return (
      <button
        disabled
        type="button"
        className="float-button"
       >
        <FontAwesomeIcon
          spin
          icon="spinner"
        />
      </button>
    )
  }

  return (
    <div className="btn-actions">
      <button
        type="button"
        className="float-button mr-6"
        disabled={loading}
        onClick={() => onEdit()}
      >
        <FontAwesomeIcon
          icon="times"
        />
      </button>
      <button
        className="float-button"
        type="submit"
        disabled={loading}
        onClick={() => onSubmit()}
      >
        <FontAwesomeIcon
          icon="check"
        />
      </button>
    </div>
  )
};

EditingButtons.propTypes = {
  onEdit: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

EditingButtons.defaultProps = {
  onEdit: () => {},
  onSubmit: () => {},
  loading: false,
}

export default EditingButtons;
