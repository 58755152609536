import axios from 'axios';
import { API_URL } from '../../../../lib/const';


export const setProcessInvites = (id, params = {}) => (
  axios.put(`${API_URL}/invite-processes/${id}/set_invites`, params)
    .then(response => response.data)
);

export const sendProcessInvites = (id, params = {}) => (
  axios.put(`${API_URL}/invite-processes/${id}/send_invites`, params)
    .then(response => response.data)
);

export const sendInvite = (id, params = {}) => (
  axios.put(`${API_URL}/invite/${id}/send_invite`, params)
    .then(response => response.data)
);

export const updateInviteProcess = (id, params = {}) => (
  axios.put(`${API_URL}/invite-processes/${id}`, params)
    .then(response => response.data)
);
