import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import AutosaveFeedback from '../components/autoSaveFeedBack';
import {
  InputTypeText, InputCell, InputTel, FieldSet,
} from '../elements';

class Contato extends React.Component {
  render() {
    const {
      fiador, fields, onValueChange, title,
    } = this.props;

    return (
      <FieldSet title={fiador ? `Dados de Contato do Fiador ${fiador}` : title}>
        <InputTypeText autosaveFeedBack={<AutosaveFeedback status={get(fields, 'email')} />} field="email" type="email" label="E-mail" col="4" required onValueChange={value => onValueChange('email', value)} />
        <InputCell autosaveFeedBack={<AutosaveFeedback status={get(fields, 'phone_number')} />} field="phone_number" label="Telefone 1" col="4" helper="Ex: (31) 99999999" required onValueChange={value => onValueChange('phone_number', value)} />
        <InputCell autosaveFeedBack={<AutosaveFeedback status={get(fields, 'landline')} />} field="landline" label="Telefone 2" col="4" helper="Ex: (31) 99999999" onValueChange={value => onValueChange('landline', value)} />
        {!fiador && (<InputTel autosaveFeedBack={<AutosaveFeedback status={get(fields, 'extra_phone_number')} />} field="extra_phone_number" label="Telefone 3" col="4" helper="Ex: (31) 99999999" onValueChange={value => onValueChange('extra_phone_number', value)} />)}
      </FieldSet>
    );
  }
}

Contato.propTypes = {
  fiador: PropTypes.string,
  fields: PropTypes.shape({
    email: PropTypes.oneOf(['ok', 'pending', 'saving', 'error']),
    phone_number: PropTypes.oneOf(['ok', 'pending', 'saving', 'error']),
    landline: PropTypes.oneOf(['ok', 'pending', 'saving', 'error']),
    extra_phone_number: PropTypes.oneOf(['ok', 'pending', 'saving', 'error']),
  }),
  onValueChange: PropTypes.func,
  title: PropTypes.string,
};

Contato.defaultProps = {
  fiador: undefined,
  fields: {},
  onValueChange: () => null,
  title: 'Dados de Contato',
};

export default Contato;
