import React, { Fragment } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { API_URL } from '../../lib/const';
import { Card, Ball, InputDocumentFile } from '../elements';
import { Modal } from '.';

class Document extends React.Component {
  state = {
    showModal: false,
    content: [],
    fileSrc: null,
  }

  showModal = (file) => {
    axios.get(`${API_URL}/uploads/${file.key}`)
      .then(response => this.setState({ showModal: true, content: file, fileSrc: get(response, 'data.url', null) }));
  }

  onClose = () => this.setState({
    showModal: false,
  })

  handleUpload = (event) => {
    const { onUpload } = this.props;
    const file = get(event, 'target.files.0');
    if (file) {
      this.setState({ currentFile: file.name });
      onUpload(event).then(() => {
        this.setState({ currentFile: null });
      });
    } else {
      this.setState({ currentFile: null });
    }
  }

  render() {
    const {
      showModal,
      content,
      currentFile,
      fileSrc,
    } = this.state;

    const {
      label,
      documento,
      onRemove,
      progress
    } = this.props;

    return (
      <Fragment>
        <div className="col-sm-12 col-md-6 mt-5">
          <Card title={label}>
            {documento ? (
              <InputDocumentFile
                name={documento.name}
                showModal={() => this.showModal(documento)}
                removeFile={() => onRemove(documento.key)}
              />
            ) : (
              <p className="documents-none">Nenhum documento anexado</p>
            )}
            {currentFile && (
              <InputDocumentFile
                name={currentFile}
                progress={progress}
              />
            )}
            <div className="documents-upload">
              <button type="button" className="btn-none">
                <input type="file" className="input-file" onChange={this.handleUpload} />
                <Ball icon="upload" small bgColor="white" iconWidth="18" />
              </button>
              <div className="documents-upload-description">
                <p>
                  Fazer Upload
                </p>
                <p>
                  Tipos de arquivo aceitos: PDF e imagens (JPG e PNG)
                </p>
              </div>
            </div>
          </Card>
        </div>
        <Modal visible={showModal} onClose={this.onClose} title={content.name} src={fileSrc} />
      </Fragment>
    );
  }
}

export default Document;
