import React, { Component, Fragment } from 'react';
import * as Talk from 'talkjs';
import { CHAT_ID } from '../../lib/const';

class PopupChat extends Component {
  constructor(props) {
    super(props);
    this.talkSession = undefined;
    this.state = {
      numeroNotificacoes: 0,
      hideChatButton: false,
    };
  }

  componentWillUnmount() {
    if (this.popup) {
      this.popup.destroy();
    }
  }

  componentDidMount() {
    const { currentUser, supportUser, signature } = this.props;
    Talk.ready
      .then(() => {
        const me = new Talk.User(currentUser);

        this.talkSession = new Talk.Session({
          appId: CHAT_ID,
          me,
          signature,
        });
        const other = new Talk.User(supportUser);
        const users = [];
        users.push(supportUser.id);
        users.push(currentUser.id);
        const conversationId = users.sort().join('_');
        const conversation = this.talkSession.getOrCreateConversation(conversationId);
        conversation.setParticipant(me);
        conversation.setParticipant(other);
        const popup = this.talkSession.createPopup(conversation, {
          keepOpen: false,
          showCloseInHeader: true,
          launcher: 'never',
        });
        popup.mount({ show: false });
        popup.on('close', () => this.setState({ hideChatButton: false }));
        this.popup = popup;
        this.talkSession.unreads.on('change', (conversationIds) => {
          this.counter = conversationIds.length;
          this.setState({
            numeroNotificacoes: this.counter,
          });
        });

        const button = this.container;
        button.addEventListener('click', (e) => {
          e.preventDefault();
          this.setState({ hideChatButton: true }, popup.show());
        });
      });
  }

  render() {
    const { numeroNotificacoes, hideChatButton } = this.state;

    return (
      <Fragment>
      <div
        ref={(c) => { this.container = c; }}
        className="chat-popup-button"
        style={{ display: hideChatButton ? 'none' : 'initial' }}
      >
          <i className="far fa-comment mr-2" />
          <span>CHAT</span>
        {
          numeroNotificacoes !== 0 && (
            <p className="chat-popup-counter">{numeroNotificacoes}</p>
          )
        }
      </div>
      </Fragment>
    );
  }
}

export default PopupChat;
