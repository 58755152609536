import React from 'react';
import PropType from 'prop-types';
import { get } from 'lodash';
import { FieldSet } from '../elements';
import { API_URL } from '../../lib/const';
import AutosaveFeedback from './autoSaveFeedBack';
import DownloadButton from './DownloadButton';

class ContractMonitor extends React.Component {
  render() {
    const {
      token, contract, showResubmit, resubmitting, onResubmit,
    } = this.props;

    const nrSigns = get(contract, 'recipients', []).reduce((acc, current) => acc + current.is_signed, 0);
    const nrSigners = get(contract, 'recipients', []).length;
    return (
      <>
        {contract && (
          <>
            <FieldSet title="Contrato" />
            <div className="row">
              <div className="col-md-12 col-sm-12 mt-3">
                <div className="mb-4 d-flex justify-content-between align-items-center">
                  <h4>Assinaturas ({nrSigns} / {nrSigners})</h4>
                  <em>Última atualização: {contract.updated_at}</em>
                </div>
                {contract.recipients.map(recipient => (
                  <div className="d-flex" style={{ textTransform: 'uppercase' }}>
                    <p className="mr-2">{recipient.name} </p> <AutosaveFeedback status={`${recipient.is_signed ? 'ok' : ''}`} />
                  </div>
                ))}
              </div>
            </div>
            <DownloadButton label="Baixar Contrato" href={`${API_URL}/contracts/enrollment/${token}/contract`} />
            {showResubmit && (
              <div className="col-md-4 p-0 offset-md-8 col-sm-12">
                <button type="button" className="btn btn-primary mt-5" disabled={resubmitting} onClick={onResubmit}>Reenviar contrato</button>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

ContractMonitor.propTypes = {
  token: PropType.string.isRequired,
  contract: PropType.shape({
    id: PropType.number,
    status_date: PropType.string,
    recipients: PropType.arrayOf(PropType.shape({
      name: PropType.string,
      is_signed: PropType.bool,
    })),
  }),
  showResubmit: PropType.bool,
  resubmitting: PropType.bool,
  onResubmit: PropType.func,
};

ContractMonitor.defaultProps = {
  contract: undefined,
  showResubmit: false,
  resubmitting: false,
  onResubmit: () => null,
};

export default ContractMonitor;
