import React from 'react';

class NotFound extends React.Component {
  render() {
    return (
      <div className="not-found">
        <h1>404</h1>
        <h1>NotFound</h1>
      </div>
    );
  }
}

export default NotFound;
