import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCopy as farCopy,
} from '@fortawesome/free-regular-svg-icons';
import {
  faGraduationCap,
  faCheck,
  faChevronRight,
  faChevronLeft,
  faBell,
  faChevronUp,
  faChevronDown,
  faClock,
  faCircle,
  faSignOutAlt,
  faEdit,
  faPlusCircle,
  faTimesCircle,
  faEye,
  faTimes,
  faMinusCircle,
  faArrowLeft,
  faArrowRight,
  faInfoCircle,
  faSpinner,
  faAsterisk,
  faBook,
  faBookOpen,
  faHome,
  faPlus,
  faExclamationCircle,
  faFile,
  faPaperclip,
  faCheckCircle,
  faTrashAlt,
  faSyncAlt,
  faColumns,
  faMoneyCheck,
  faQuestion,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faColumns,
  faGraduationCap,
  faCheck,
  faChevronRight,
  faChevronLeft,
  faBell,
  faChevronUp,
  faChevronDown,
  faClock,
  faCircle,
  faSignOutAlt,
  faEdit,
  faPlusCircle,
  faTimesCircle,
  faEye,
  faTimes,
  faMinusCircle,
  faArrowLeft,
  faArrowRight,
  faInfoCircle,
  faSpinner,
  faAsterisk,
  faBook,
  faBookOpen,
  faHome,
  faPlus,
  faExclamationCircle,
  faFile,
  faPaperclip,
  faCheckCircle,
  faTrashAlt,
  faSyncAlt,
  faMoneyCheck,
  faQuestion,
  faDollarSign,
  farCopy,
);
