import React from 'react';
import { fetchContractStatus } from '../services/options_service';
import { InputTypeDropdown } from '.';

class InputDropdownContractStatus extends React.Component {
  state = {
    options: [],
  }

  componentDidMount() {
    this.fetchContractStatus();
  }

  fetchContractStatus = () => {
    fetchContractStatus()
      .then((data) => {
        this.setState({
          options: data,
        });
      });
  }

  render() {
    const { ...rest } = this.props;
    const { options } = this.state;

    return (
      <InputTypeDropdown
        field="contract_status"
        label="Status do Contrato"
        options={options}
        {...rest}
      />
    );
  }
}

export default InputDropdownContractStatus;
