import React from 'react';
import { Icon } from './';

class NotificationItem extends React.Component {
  render() {
    const { itemName, itemDescription } = this.props;
    return(
      <div className="notification-base">
        <Icon name="bell" white={false} />
        <div className="notification-description">
          <small className="color-dim">{ itemName }</small><br />
          { itemDescription }
        </div>
        {/*<button href="#" className="btn btn-primary">{ buttonMessage }</button>*/}
      </div>
    );
  }
}

export default NotificationItem;
