import debounce from 'debounce-promise';
import { get } from 'lodash';
import Axios from 'axios';
import { API_URL } from '../../../lib/const';


const fetchRecipients = debounce(params => (
  Axios.get(`${API_URL}/personal/personal-data/approvals`, { params })
    .then(response => response.data.results.map(p => ({
      value: get(p, 'enrollment.id'),
      label: p.real_name,
    })))
), 500);

export default fetchRecipients;
