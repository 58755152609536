import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Form } from "informed";
import { debounce, get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { API_URL } from "../../lib/const";
import {
  FieldSet,
  InputBirth,
  InputCell,
  InputCpf,
  InputTypeDropdown,
  InputTypeText,
  Spinner,
} from "../elements";
import InputDropdownRace from "../elements/InputDropdownRace";
import InputDropdownInstitution from "../elements/InputTypeDropdownInstitution";
import { Contato, Endereco } from "../forms";
import {
  fetchCities,
  fetchCountries,
  fetchDisabilities,
  fetchGenders,
  fetchHandicapOptions,
  fetchInstitutions,
  fetchMaritalStatuses,
  fetchNationalities,
  fetchSpecialNeeds,
  fetchStates,
  fetchRaceTypes,
} from "../services/options_service";
import {
  fetchPersonalData,
  validatePersonalData,
} from "../services/personal_data_service";
import { handleUpload } from "../services/upload_service";
import EducationForm from "../v2/personal/EducationForm";
import AutosaveFeedback from "./autoSaveFeedBack";
import ComponentWithToken from "./CompontentWithToken";
import DisabilitiesForm from "./DisabilitiesForm";
import HandicapForm from "./HandicapForm";
import ProfilePicture from "./ProfilePicture";
import SpecialNeedsForm from "./SpecialNeedsForm";

class PersonalDataStep extends ComponentWithToken {
  state = {
    loading: true,
    countries: [],
    states: [],
    cities: [],
    profilePicture: undefined,
    profileKey: null,
    dbCep: null,
    uploadingProfileImage: false,
    errors: [],
    nationalities: [],
    maritalStatuses: [],
    genders: [],
    disabilities: [],
    handicapOptions: [],
    specialNeeds: [],
    selectedDisabilities: [],
    selectedSpecialNeeds: [],
    fields: {},
    fathers_contact_required: false,
    mothers_contact_required: false,
    isVisible: "none",
    isDisable: false,
    selectInstitutionPlaceHolder: "",
  };

  previousErrors = {};

  componentDidMount() {
    Promise.all([this.fetchPersonalData(), this.fetchOptions()]).then(() =>
      this.setState({ loading: false })
    );
  }

  fetchPersonalData = () =>
    fetchPersonalData(this.token).then((data) => {
      this.formApi.setValues(data);
      this.setState({
        selectedSpecialNeeds: data.special_needs,
        selectedDisabilities: data.disabilities,
        profilePicture: data.profile_img_url || undefined,
        fathers_contact_required: data.fathers_contact_required,
        mothers_contact_required: data.mothers_contact_required,
      });
      if (data.birth_state) {
        this.fetchCities(data.birth_state);
      }
    });

  fetchCities = (state) =>
    fetchCities(state).then((response) => this.setState({ cities: response }));

  fetchOptions = () => {
    const options = [
      {
        field: "nationalities",
        fetchFunction: fetchNationalities,
      },
      {
        field: "countries",
        fetchFunction: fetchCountries,
      },
      {
        field: "states",
        fetchFunction: fetchStates,
      },
      {
        field: "genders",
        fetchFunction: fetchGenders,
      },
      {
        field: "maritalStatuses",
        fetchFunction: fetchMaritalStatuses,
      },
      {
        field: "handicapOptions",
        fetchFunction: fetchHandicapOptions,
      },
      {
        field: "disabilities",
        fetchFunction: fetchDisabilities,
      },
      {
        field: "specialNeeds",
        fetchFunction: fetchSpecialNeeds,
      },
      {
        field: "institutions",
        fetchFunction: fetchInstitutions,
      },
      {
        field: 'race',
        fetchFunction: fetchRaceTypes,
      },
    ];
    return Promise.all([
      options.map((option) =>
        option.fetchFunction().then((response) =>
          this.setState({
            [option.field]: response,
          })
        )
      ),
    ]);
  };

  handleInstitutions = (unlisted_institution) => {
    if (unlisted_institution) {
      this.formApi.setValue("institution", 2);
      this.setState({ isVisible: "block", isDisable: true });
    } else {
      this.setState({ isVisible: "none" });
    }
  };

  handleInstitutionNotFound = () =>
    this.state.isVisible === "none"
      ? this.setState({
          isVisible: "block",
          isDisable: true,
          selectInstitutionPlaceHolder: "Outros",
        })
      : this.setState({
          isVisible: "none",
          isDisable: false,
          selectInstitutionPlaceHolder: "",
        });

  validatePersonalData = () => {
    this.setState({ loading: true });
    const { values } = this.formApi.getState();
    const { onClick } = this.props;
    const { selectedDisabilities, selectedSpecialNeeds } = this.state;
    validatePersonalData(this.token, {
      ...values,
      institution: values.institution ? values.institution.value : null,
      disabilities: selectedDisabilities,
      special_needs: selectedSpecialNeeds,
    })
      .then(() => this.setState({ loading: false }, onClick))
      .catch((error) => {
        this.setState({ loading: false });
        const status = get(error, "response.status");
        if (status === 400) {
          const errors = get(error, "response.data");
          Object.keys(this.previousErrors).forEach((fieldName) => {
            if (this.formApi.fieldExists(fieldName)) {
              this.formApi.setError(fieldName, null);
            }
          });

          this.previousErrors = { ...errors };

          Object.entries(errors).forEach((e) => {
            if (this.formApi.fieldExists(e[0])) {
              this.formApi.setError(e[0], e[1]);
            }
          });
        }
      });
  };

  uploadProfilePicture = (event) => {
    const file = get(event, "target.files.0");
    this.setState({ uploadingProfileImage: true });
    this.updateFeedBack("profile_img_key", "saving");
    if (file) {
      handleUpload(file)
        .then((response) => {
          const { key } = response.data;
          const { values } = this.formApi.getState();
          values.profile_img_key = key;
          this.formApi.setValues({ ...values });
          this.setState(
            {
              uploadingProfileImage: false,
              profilePicture: URL.createObjectURL(file),
            },
            () => this.partialSavePersonalData("profile_img_key", key)
          );
        })
        .catch(() => {
          this.setState({ uploadingProfileImage: false });
          this.updateFeedBack("profile_img_key", "error");
        });
    }
  };

  hasBrazilianNaturalization = (formState) =>
    +formState.values.nationality === 1 || +formState.values.nationality === 2;

  isBrazilian = (formState) => +formState.values.nationality === 1;

  IsAssumedNameRequired = (formState) => formState.values.assumed_name_required;

  displayMilitaryCertificate = (formState) => {
    const { genders } = this.state;
    const gender = genders.find((g) => +formState.values.gender === +g.id);
    return gender && gender.check_military_draft;
  };

  displayDisabilities = (formState) => {
    const { handicapOptions } = this.state;
    const handicapOption = handicapOptions.find(
      (g) => +formState.values.handicap === +g.id
    );
    return handicapOption && handicapOption.check_disabilities;
  };

  handleDisabilityChange = (event, disabilityId) => {
    const { selectedDisabilities } = this.state;
    let disabilities = [];

    if (event.target.checked) {
      disabilities = [...selectedDisabilities, disabilityId];
    } else {
      disabilities = selectedDisabilities.filter((d) => d !== disabilityId);
    }
    this.setState({ selectedDisabilities: disabilities }, () =>
      this.partialSavePersonalData("disabilities", disabilities)
    );
  };

  handleSpecialNeedChange = (event, disabilityId) => {
    const { selectedSpecialNeeds } = this.state;
    let specialNeeds = [];

    if (event.target.checked) {
      specialNeeds = [...selectedSpecialNeeds, disabilityId];
    } else {
      specialNeeds = selectedSpecialNeeds.filter((d) => d !== disabilityId);
    }

    this.setState({ selectedSpecialNeeds: specialNeeds }, () =>
      this.partialSavePersonalData("special_needs", specialNeeds)
    );
  };

  updateFeedBack = (field, status) => {
    const { fields } = this.state;
    fields[field] = status;
    this.setState({ fields });
  };

  partialSavePersonalData = (field, value) => {
    this.updateFeedBack(field, "saving");
    axios
      .patch(`${API_URL}/personal/personal-data/${this.token}`, {
        [field]: value,
      })
      .then(() => {
        this.updateFeedBack(field, "ok");
        if (this.formApi.fieldExists(field)) {
          this.formApi.setError(field, null);
        }
      })
      .catch((error) => {
        this.updateFeedBack(field, "error");
        const status = get(error, "response.status");
        if (status === 400) {
          const response = get(error, "response.data");
          this.formApi.setError(field, response[field]);
        }
      });
  };

  debouncedPartialSave = debounce(this.partialSavePersonalData, 400);

  render() {
    const {
      profilePicture,
      loading,
      nationalities,
      countries,
      states,
      cities,
      genders,
      maritalStatuses,
      handicapOptions,
      disabilities,
      selectedDisabilities,
      selectedSpecialNeeds,
      specialNeeds,
      fields,
      uploadingProfileImage,
      fathers_contact_required,
      mothers_contact_required,
      isDisable,
      selectInstitutionPlaceHolder,
    } = this.state;

    const { isAcademic, isNAAP, onEdit } = this.props;
    return (
      <Form
        id="dado-pessoal"
        getApi={(api) => {
          this.formApi = api;
        }}
      >
        {({ formApi, formState }) => (
          <>
            <Spinner active={loading}>
              {!isNAAP && (
                <>
                  <ProfilePicture
                    loading={uploadingProfileImage}
                    src={profilePicture}
                    onUpdate={this.uploadProfilePicture}
                    error={get(this.previousErrors, "profile_img_key[0]")}
                    autosaveFeedBack={
                      <AutosaveFeedback
                        status={get(fields, "profile_img_key")}
                      />
                    }
                  />
                  <FieldSet>
                    <InputTypeText
                      autosaveFeedBack={
                        <AutosaveFeedback status={get(fields, "real_name")} />
                      }
                      disabled={!isAcademic}
                      field="real_name"
                      label="Nome"
                      col="6"
                      helper="Seu nome"
                      onValueChange={(value) =>
                        this.debouncedPartialSave("real_name", value)
                      }
                    />
                    {this.IsAssumedNameRequired(formState) && (
                      <InputTypeText
                        autosaveFeedBack={
                          <AutosaveFeedback
                            status={get(fields, "assumed_name")}
                          />
                        }
                        field="assumed_name"
                        label="Nome Social"
                        col="6"
                        helper="Seu nome social"
                        onValueChange={(value) =>
                          this.debouncedPartialSave("assumed_name", value)
                        }
                      />
                    )}
                    <InputTypeDropdown
                      autosaveFeedBack={
                        <AutosaveFeedback status={get(fields, "nationality")} />
                      }
                      options={nationalities}
                      field="nationality"
                      label="Nacionalidade"
                      col="3"
                      required
                      onValueChange={(value) =>
                        this.debouncedPartialSave("nationality", value)
                      }
                    />
                    {!this.isBrazilian(formState) && (
                      <>
                        <InputTypeDropdown
                          autosaveFeedBack={
                            <AutosaveFeedback
                              status={get(fields, "birth_country")}
                            />
                          }
                          options={countries}
                          field="birth_country"
                          label="País de Origem"
                          col="3"
                          helper="Ex: Brasil"
                          required
                          onValueChange={(value) =>
                            this.debouncedPartialSave("birth_country", value)
                          }
                        />
                      </>
                    )}
                    {this.isBrazilian(formState) ? (
                      <>
                        <InputTypeDropdown
                          autosaveFeedBack={
                            <AutosaveFeedback
                              status={get(fields, "birth_state")}
                            />
                          }
                          options={states}
                          field="birth_state"
                          label="UF de Nascimento"
                          col="3"
                          onChange={(e) => this.fetchCities(e.target.value)}
                          disabled={false}
                          required
                          onValueChange={(value) =>
                            this.debouncedPartialSave("birth_state", value)
                          }
                        />
                        <InputTypeDropdown
                          autosaveFeedBack={
                            <AutosaveFeedback
                              status={get(fields, "birth_city")}
                            />
                          }
                          options={cities}
                          field="birth_city"
                          label="Naturalidade"
                          col="3"
                          helper="Cidade de Nascimento"
                          disabled={false}
                          required
                          onValueChange={(value) =>
                            this.debouncedPartialSave("birth_city", value)
                          }
                        />
                      </>
                    ) : (
                      <>
                        <InputTypeText
                          autosaveFeedBack={
                            <AutosaveFeedback
                              status={get(fields, "foreign_birth_state")}
                            />
                          }
                          field="foreign_birth_state"
                          label="UF de Nascimento"
                          col="3"
                          required
                          onValueChange={(value) =>
                            this.debouncedPartialSave(
                              "foreign_birth_state",
                              value
                            )
                          }
                        />
                        <InputTypeText
                          autosaveFeedBack={
                            <AutosaveFeedback
                              status={get(fields, "foreign_birth_city")}
                            />
                          }
                          field="foreign_birth_city"
                          label="Naturalidade"
                          col="3"
                          helper="Cidade de Nascimento"
                          required
                          onValueChange={(value) =>
                            this.debouncedPartialSave(
                              "foreign_birth_city",
                              value
                            )
                          }
                        />
                      </>
                    )}
                    <InputTypeDropdown
                      autosaveFeedBack={
                        <AutosaveFeedback
                          status={get(fields, "high_school_graduation_country")}
                        />
                      }
                      options={countries}
                      field="high_school_graduation_country"
                      label="País de Conclusão do Ensino Médio"
                      col="6"
                      helper="Ex: Brasil"
                      required
                      onValueChange={(value) =>
                        this.debouncedPartialSave(
                          "high_school_graduation_country",
                          value
                        )
                      }
                    />
                    {this.hasBrazilianNaturalization(formState) ? (
                      <InputCpf
                        autosaveFeedBack={
                          <AutosaveFeedback status={get(fields, "cpf")} />
                        }
                        field="cpf"
                        label="CPF"
                        col="3"
                        helper="Ex: 000.000.000-00"
                        required
                        onValueChange={(value) =>
                          this.debouncedPartialSave("cpf", value)
                        }
                      />
                    ) : (
                      <InputTypeText
                        autosaveFeedBack={
                          <AutosaveFeedback status={get(fields, "rne")} />
                        }
                        field="rne"
                        label="RNE"
                        col="3"
                        numbers="15"
                        required
                        onValueChange={(value) =>
                          this.debouncedPartialSave("rne", value)
                        }
                      />
                    )}
                    <InputBirth
                      autosaveFeedBack={
                        <AutosaveFeedback status={get(fields, "birth_date")} />
                      }
                      field="birth_date"
                      label="Data de Nascimento"
                      col="3"
                      helper="Ex: 18/12/2001 - (Mes/Dia/Ano)"
                      required
                      onValueChange={(value) =>
                        this.debouncedPartialSave("birth_date", value)
                      }
                    />
                    <InputTypeDropdown
                      autosaveFeedBack={
                        <AutosaveFeedback status={get(fields, "gender")} />
                      }
                      options={genders}
                      field="gender"
                      label="Sexo"
                      col="3"
                      required
                      onValueChange={(value) =>
                        this.debouncedPartialSave("gender", value)
                      }
                    />
                    <InputTypeDropdown
                      autosaveFeedBack={
                        <AutosaveFeedback
                          status={get(fields, "marital_status")}
                        />
                      }
                      options={maritalStatuses}
                      field="marital_status"
                      label="Estado Civil"
                      col="3"
                      required
                      onValueChange={(value) =>
                        this.debouncedPartialSave("marital_status", value)
                      }
                    />
                    {this.hasBrazilianNaturalization(formState) && (
                      <>
                        <InputTypeText
                          autosaveFeedBack={
                            <AutosaveFeedback status={get(fields, "rg")} />
                          }
                          field="rg"
                          label="RG"
                          col="3"
                          required
                          onValueChange={(value) =>
                            this.debouncedPartialSave("rg", value)
                          }
                        />
                        <InputTypeText
                          maxLength="11"
                          autosaveFeedBack={
                            <AutosaveFeedback
                              status={get(fields, "rg_issuing_body")}
                            />
                          }
                          field="rg_issuing_body"
                          label="Órgão Emissor"
                          col="3"

                          required
                          onValueChange={(value) =>
                            this.debouncedPartialSave("rg_issuing_body", value)
                          }
                        />
                        <InputTypeDropdown
                          autosaveFeedBack={
                            <AutosaveFeedback
                              status={get(fields, "rg_issuing_body_state")}
                            />
                          }
                          options={states}
                          field="rg_issuing_body_state"
                          label="UF do Órgão Emissor"
                          col="3"
                          disabled={false}
                          required
                          onValueChange={(value) =>
                            this.debouncedPartialSave("rg_issuing_body_state", value)
                          }
                        />
                        <InputBirth
                          autosaveFeedBack={
                            <AutosaveFeedback
                              status={get(fields, "rg_issue_date")}
                            />
                          }
                          field="rg_issue_date"
                          label="Data de Expedição do RG"
                          col="3"
                          required
                          onValueChange={(value) =>
                            this.debouncedPartialSave("rg_issue_date", value)
                          }
                        />
                        <InputTypeText
                          autosaveFeedBack={
                            <AutosaveFeedback
                              status={get(fields, "electoral_card")}
                            />
                          }
                          field="electoral_card"
                          label="Titulo de Eleitor"
                          col="3"
                          required
                          onValueChange={(value) =>
                            this.debouncedPartialSave("electoral_card", value)
                          }
                        />
                        <InputDropdownRace
                          field="race"
                          autosaveFeedBack={
                            <AutosaveFeedback status={get(fields, 'race')} />
                          }
                          col="6"
                          required
                          onValueChange={value => this.debouncedPartialSave('race', value)
                          }
                        />
                        {this.displayMilitaryCertificate(formState) && (
                          <InputTypeText
                            autosaveFeedBack={
                              <AutosaveFeedback
                                status={get(fields, "military_certificate")}
                              />
                            }
                            field="military_certificate"
                            label="Certificado Militar"
                            col="3"
                            required
                            onValueChange={(value) =>
                              this.debouncedPartialSave(
                                "military_certificate",
                                value
                              )
                            }
                          />
                        )}
                      </>
                    )}
                  </FieldSet>
                  <FieldSet title="Filiação">
                    <InputTypeText
                      field="fathers_name"
                      autosaveFeedBack={
                        <AutosaveFeedback
                          status={get(fields, "fathers_name")}
                        />
                      }
                      label="Nome do Pai"
                      helper="Nome completo do seu pai"
                      col="6"
                      onValueChange={(value) =>
                        this.debouncedPartialSave("fathers_name", value)
                      }
                    />
                    <InputCpf
                      autosaveFeedBack={
                        <AutosaveFeedback status={get(fields, "fathers_cpf")} />
                      }
                      field="fathers_cpf"
                      label="CPF do Pai"
                      col="6"
                      required={
                        get(formState, "values.fathers_name") &&
                        fathers_contact_required
                      }
                      helper="CPF do seu pai"
                      onValueChange={(value) =>
                        this.debouncedPartialSave("fathers_cpf", value)
                      }
                    />
                    <InputTypeText
                      field="fathers_email"
                      autosaveFeedBack={
                        <AutosaveFeedback
                          status={get(fields, "fathers_email")}
                        />
                      }
                      label="Email do Pai"
                      required={
                        get(formState, "values.fathers_name") &&
                        fathers_contact_required
                      }
                      helper="Email do seu pai"
                      col="6"
                      onValueChange={(value) =>
                        this.debouncedPartialSave("fathers_email", value)
                      }
                    />
                    <InputCell
                      autosaveFeedBack={
                        <AutosaveFeedback
                          status={get(fields, "fathers_phone")}
                        />
                      }
                      field="fathers_phone"
                      label="Telefone do Pai"
                      required={
                        get(formState, "values.fathers_name") &&
                        fathers_contact_required
                      }
                      col="6"
                      helper="Telefone do seu pai"
                      onValueChange={(value) =>
                        this.debouncedPartialSave("fathers_phone", value)
                      }
                    />

                    <InputTypeText
                      field="mothers_name"
                      autosaveFeedBack={
                        <AutosaveFeedback
                          status={get(fields, "mothers_name")}
                        />
                      }
                      label="Nome da Mãe"
                      helper="Nome completo da sua mãe"
                      col="6"
                      required
                      onValueChange={(value) =>
                        this.debouncedPartialSave("mothers_name", value)
                      }
                    />
                    <InputCpf
                      autosaveFeedBack={
                        <AutosaveFeedback status={get(fields, "mothers_cpf")} />
                      }
                      field="mothers_cpf"
                      label="CPF do Mãe"
                      col="6"
                      helper="CPF da sua mãe"
                      required={mothers_contact_required}
                      onValueChange={(value) =>
                        this.debouncedPartialSave("mothers_cpf", value)
                      }
                    />
                    <InputTypeText
                      field="mothers_email"
                      autosaveFeedBack={
                        <AutosaveFeedback
                          status={get(fields, "mothers_email")}
                        />
                      }
                      label="Email do Mãe"
                      helper="Email da sua mãe"
                      col="6"
                      required={mothers_contact_required}
                      onValueChange={(value) =>
                        this.debouncedPartialSave("mothers_email", value)
                      }
                    />
                    <InputCell
                      autosaveFeedBack={
                        <AutosaveFeedback
                          status={get(fields, "mothers_phone")}
                        />
                      }
                      field="mothers_phone"
                      label="Telefone do Mãe"
                      col="6"
                      helper="Telefone da sua mãe"
                      required={mothers_contact_required}
                      onValueChange={(value) =>
                        this.debouncedPartialSave("mothers_phone", value)
                      }
                    />
                  </FieldSet>
                  <EducationForm
                    fields={fields}
                    onValueChange={this.debouncedPartialSave}
                    formApi={formApi}
                  >
                    <InputDropdownInstitution
                      field="institution"
                      autosaveFeedBack={
                        <AutosaveFeedback status={get(fields, "institution")} />
                      }
                      col="6"
                      disabled={isDisable}
                      helper={selectInstitutionPlaceHolder}
                      required
                      onValueChange={(value) =>
                        this.debouncedPartialSave("institution", value.value)
                      }
                    />
                    <div className="radio-group col-md-12 col-sm-12">
                      <label className="input-check-type">
                        {"Não encontrei minha instituição"}
                        <input
                          type="checkbox"
                          className="checkbox"
                          id="no_institution"
                          checked={isDisable}
                          onChange={() => this.handleInstitutionNotFound()}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </EducationForm>
                  <div
                    style={{
                      display: `${this.state.isVisible}`,
                    }}
                  >
                    <InputTypeText
                      field="unlisted_institution"
                      autosaveFeedBack={
                        <AutosaveFeedback
                          status={get(fields, "unlisted_institution")}
                        />
                      }
                      label="Instituição"
                      helper="Instituição onde estuda ou estudou"
                      col="6"
                      required
                      onValueChange={(value) => {
                        this.debouncedPartialSave(
                          "unlisted_institution",
                          value
                        );
                      }}
                    />
                  </div>
                  <Contato
                    fields={fields}
                    onValueChange={this.debouncedPartialSave}
                  />
                  <Endereco
                    fields={fields}
                    onValueChange={this.debouncedPartialSave}
                    formApi={formApi}
                    dbCep={formState.zip_code}
                  />
                </>
              )}
              <FieldSet title="Outras Informações" />
              <HandicapForm
                autosaveFeedBack={
                  <AutosaveFeedback status={get(fields, "handicap")} />
                }
                options={handicapOptions}
                error={get(formState, "errors.handicap[0]")}
                onValueChange={this.debouncedPartialSave}
              />
              {this.displayDisabilities(formState) && (
                <>
                  <DisabilitiesForm
                    options={disabilities}
                    selectedDisabilities={selectedDisabilities}
                    error={get(formState, "errors.disabilities[0]")}
                    handleChange={this.handleDisabilityChange}
                  />
                  <SpecialNeedsForm
                    options={specialNeeds}
                    selectedDisabilities={selectedDisabilities}
                    selectedSpecialNeeds={selectedSpecialNeeds}
                    error={get(formState, "errors.special_needs[0]")}
                    handleChange={this.handleSpecialNeedChange}
                  />
                </>
              )}
              <hr />
              <div className="text-right">
                <div className="col-md-4 p-0 offset-md-8 col-sm-12">
                  {!isAcademic && (
                    <div className="btn-actions">
                      <button
                        className="btn btn-black mr-2"
                        type="submit"
                        onClick={() => this.props.backClick()}
                      >
                        Voltar
                      </button>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => this.validatePersonalData(formApi)}
                      >
                        {loading ? "Carregando..." : "Próximo"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Spinner>
            {isAcademic && (
              <div className="btn-actions">
                <button
                  type="button"
                  className="float-button mr-6"
                  disabled={loading}
                  onClick={() => onEdit()}
                >
                  <FontAwesomeIcon icon="times" />
                </button>
                <button
                  type="button"
                  className="float-button"
                  disabled={loading}
                  onClick={() => this.validatePersonalData(formApi)}
                >
                  <FontAwesomeIcon icon="check" />
                </button>
              </div>
            )}
          </>
        )}
      </Form>
    );
  }
}


PersonalDataStep.propTypes = {
  onClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
};

PersonalDataStep.defaultProps = {
  onEdit: () => {},
};

export default withRouter(PersonalDataStep);
