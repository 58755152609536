import React from 'react';
import ReactTable from 'react-table';
import { tableTextFilterMethod } from '../../lib/utils';

const translations = {
  previousText: 'anterior',
  nextText: 'próximo',
  loadingText: 'carregando...',
  noDataText: 'nenhum dado encontrado',
  pageText: 'pág.',
  ofText: 'de',
  rowsText: 'linhas por página',
};

const Table = props => (
  <ReactTable
    defaultFilterMethod={tableTextFilterMethod}
    className="table-default"
    {...props}
    {...translations}
  />
);

export default Table;
