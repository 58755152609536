import React, { useEffect, useReducer } from 'react';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Spinner, FieldSet } from '../elements';
import PersonalDataSummary from '../enrollment/components/PersonalDataSummary';
import HealthDetailReducer from '../health/reducer/HealthDetailReducer';
import { fetchPersonalDataSummary } from '../services/personal_data_service';
import { fetchHealthData } from '../services/health_services';
import { fetchDocumentTypes, fetchDocuments } from '../services/documents_services';
import { API_URL } from '../../lib/const';
import Modal from '../components/ConfirmationModal';
import { DOCUMENT_TYPE } from '../../lib/config';
import { PersonalDataStep } from '../components';

const NAAPDetailsPage = ({ match }) => {
  const initialState = {
    loadingPage: true,
    isEditing: false,
    healthData: {},
    personalData: {},
    document: {},
    confirmationModal: false,
  };

  const [state, dispatch] = useReducer(HealthDetailReducer, initialState);
  const { token } = match.params;
  const {
    loadingPage,
    personalData,
    isEditing,
    confirmationModal,
  } = state;

  const setLoadingPage = isLoading => dispatch({
    type: 'SET_LOADING_PAGE',
    dispatch: isLoading,
  });

  const handleData = () => {
    setLoadingPage(true);
    axios.all([
      fetchPersonalDataSummary(token),
      fetchHealthData(token),
      fetchDocumentTypes(token),
      fetchDocuments(token),
    ])
      .then(([personalDataSummary, healthDataSummary, documentTypesSummary, documentsSummary]) => {
        dispatch({
          type: 'SET_DOCUMENTS',
          payload: documentsSummary.documents.filter(d => d.document_type === DOCUMENT_TYPE.VACCINE_CARD),
        });
        dispatch({
          type: 'SET_DOCUMENT_TYPES',
          payload: documentTypesSummary,
        });
        dispatch({
          type: 'SET_HEALTH_DATA',
          payload: healthDataSummary,
        });
        dispatch({
          type: 'SET_PERSONAL_DATA',
          payload: personalDataSummary,
        });
        setLoadingPage(false);
      })
      .catch(() => {
        setLoadingPage(false);
      });
  };

  const handleModal = () => {
    dispatch({ type: 'SET_CONFIRMATION_MODAL' });
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleEdit = () => {
    if (isEditing) {
      fetchHealthData(token)
        .then(data => (
          dispatch({
            type: 'SET_HEALTH_DATA',
            payload: data,
          })
        ));
    }
    dispatch({ type: 'SET_EDITING' });
  };

  const canApprove = () => {
    const naap_status = get(personalData, 'enrollment.naap_status', {});
    return (get(naap_status, 'id') === 3);
  };

  const handleApproveData = async () => {
    await axios.post(`${API_URL}/onboarding/enrollments/${token}/approve_naap_data`);
    toast.success('Aluno aprovado com sucesso!');
    dispatch({ type: 'SET_CONFIRMATION_MODAL' });
    handleData();
  };

  const handleSavePersonal = () => {
    dispatch({ type: 'SET_EDITING' });
    handleData();
  };

  return (
    <Spinner active={loadingPage}>
      <div className="one-page-only">
        {
          isEditing ? (
            <>
              <PersonalDataStep
                isAcademic
                isNAAP
                onClick={handleSavePersonal}
                onEdit={handleEdit}
              />
            </>
          ) : (
            <PersonalDataSummary personalData={personalData} />
          )
        }
        {
          (!isEditing && canApprove()) && (
            <button
              type="button"
              className="float-button"
              onClick={handleEdit}
            >
              <FontAwesomeIcon
                icon="edit"
              />
            </button>
          )
        }
        {
          canApprove() && (
            <div className="col-md-4 mt-4 p-0 col-sm-12">
              <div className="btn-actions">
                <button
                  className="btn btn-primary"
                  disabled={isEditing}
                  type="button"
                  onClick={() => handleModal()}
                >
                  {isEditing ? 'Editando' : 'Aprovar'}
                </button>
              </div>
            </div>
          )
        }
      </div>
      <Modal
        visible={confirmationModal}
        title="Confirmação"
        onCancel={() => dispatch({ type: 'SET_CONFIRMATION_MODAL' })}
        onConfirm={handleApproveData}
      >
        Você tem certeza que deseja aprovar este aluno?
      </Modal>
    </Spinner>
  );
};

export default withRouter(NAAPDetailsPage);
