import React from 'react';
import PropTypes from 'prop-types';

const DownloadButton = ({ href, label, filename }) => (
  <div className="w-50 mt-3">
    <a download={filename} href={href} target="_blank" rel="noopener noreferrer" className="btn btn-black link-unstyled">{label}</a>
  </div>
);

DownloadButton.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  filename: PropTypes.string,
};

DownloadButton.defaultProps = {
  filename: true,
};

export default DownloadButton;
