import { ApolloProvider } from '@apollo/react-hooks';
import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import client from './app/apollo_client';
import { StudentRoute } from './app/elements';
import { EndProject, Enroll, Faq, Login, NotFound } from './app/pages';
import './app/register-fa-icons';
import MessageState from './app/v2/common/states/MessageState';
import './assets/css/main.css';
import Main from './Main';

const App = () => {
  useEffect(() => {
    const onSuccess = (response: AxiosResponse) => response;
    const onError = (e: AxiosError) => {
      return Promise.reject(e);
    };
    axios.interceptors.response.use(onSuccess, onError);
  }, []);

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <MessageState>
          <>
          <ToastContainer />
            <Switch>
              <Route path="/404" component={NotFound} />
              <Route path="/login" component={Login} />
              <StudentRoute exact path="/inscrever/:token" component={Enroll} />
              <StudentRoute exact path="/faq/:token" component={Faq} />
              <StudentRoute exact path="/encerramento" component={EndProject} />
              <Main />
            </Switch>
          </>
        </MessageState>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
