import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { withRouter } from 'react-router-dom';
import ComponentWithToken from './CompontentWithToken';
import approveTermOfAbsence from '../services/term_of_absence_services';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class TermOfAbsence extends ComponentWithToken {
  onClick = () => {
    const { onClick } = this.props;
    approveTermOfAbsence(this.token).then(() => onClick());
  }

  render() {
    return (
      <>
        <Document
          file="https://emails-service-templates.s3-sa-east-1.amazonaws.com/termo_matricula_fora_do_prazo.pdf"
          error="Não foi possível carregar o PDF."
          loading="Carregando..."
          noData="Não existe PDF."
          className="mb-5 pdf-viewer"
        >
          <Page pageNumber={1} />
        </Document>
        <div className="d-flex justify-content-end responsive-button-container">
          <button type="button" className="btn btn-primary col-3" onClick={this.onClick}>Aceitar e continuar</button>
        </div>
      </>
    );
  }
}

export default withRouter(TermOfAbsence);
