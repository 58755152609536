import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Form } from 'informed';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSSTransition } from 'react-transition-group';
import { ENROLLMENT_STATUS } from '../../../lib/config';
import { API_URL } from '../../../lib/const';
import Modal from '../../components/ConfirmationModal';
import { InputTypeArea, Spinner } from '../../elements';
import DocumentsDetailReducer from '../../reducers/DocumentsDetailReducer';
import { fetchDocuments, fetchDocumentTypesWithEquivalents, sendDocuments } from '../../services/documents_services';
import { fetchPersonalDataSummary } from '../../services/personal_data_service';
import { handleDocumentUpload } from '../../services/upload_service';
import DocumentsDetailsForm from '../components/DocumentsDetailsForm';
import DocumentsDetailsView from '../components/DocumentsDetailsView';

const DocumentsDetailPage = ({
  match,
  history,
}) => {
  const initialState = {
    loadingPage: false,
    isEditing: false,
    showPendencies: false,
    hasPendencies: false,
    loadingPendencies: false,
    pendencies: [],
    errors: {},
    personalDocumentsTypes: [],
    personalDocuments: [],
    personalData: {},
    form: {},
    pendingDocument: null
  };
  const [state, dispatch] = useReducer(DocumentsDetailReducer, initialState);
  const {
    loadingPage,
    personalData,
    loadingPendencies,
    personalDocumentsTypes,
    personalDocuments,
    isEditing,
    confirmationModal,
    showPendencies,
    hasPendencies,
    form,
    pendencies,
    errors,
    pendingDocument
  } = state;

  const { token } = match.params;

  const fetchPersonalData = () => {
    fetchPersonalDataSummary(token)
      .then((personalDataSummary) => {
        dispatch({
          type: 'SET_PERSONAL_DATA',
          payload: personalDataSummary,
        });
      });
  };



  const fetchPersonalDocuments = () => {
    fetchDocuments(token).then(res => dispatch({ type: 'SET_PERSONAL_DOCUMENTS', payload: res.documents }));
  };

  const fetchPendencies = (id) => {
    const dadosPessoais = 'Dados pessoais';
    dispatch({ type: 'SET_LOADING_PENDENCIES' });
    axios.get(`${API_URL}/onboarding/pendencies`,
      {
        params: {
          enrollment: id,
        },
      }).then((response) => {
        const activePendencies = response.data.filter(f => !f.resolved_at);
        dispatch({
          type: 'SET_PENDENCIES_LIST',
          payload: response.data.filter(p => p.step === dadosPessoais),
        });
        if (activePendencies.length > 0) {
          dispatch({ type: 'SET_PENDENCIES_LIST_VISIBILITY', payload: true });
        }
      })
      .catch(() => {
        dispatch({ type: 'SET_LOADING_PENDENCIES' });
      });
  };

  const fetchData = () => {
    dispatch({ type: 'SET_LOADING_PAGE', payload: true });
    axios.all([
      fetchPersonalDataSummary(token),
      fetchDocumentTypesWithEquivalents(token),
      fetchDocuments(token),
    ])
      .then(([personalDataSummary, documentsTypes, documents]) => {
        fetchPendencies(personalDataSummary.enrollment.id);
        dispatch({
          type: 'SET_PERSONAL_DATA',
          payload: personalDataSummary,
        });
        dispatch({
          type: 'SET_PERSONAL_DOCUMENTS_TYPES',
          payload: documentsTypes,
        });
        dispatch({
          type: 'SET_PERSONAL_DOCUMENTS',
          payload: documents.documents,
        });
      });
  };

  const sendPendencies = () => {
    dispatch({ type: 'SET_LOADING_PENDENCIES' });
    axios.post(`${API_URL}/onboarding/enrollments/${token}/pendency`)
      .then(() => {
        fetchData();
        toast.success('Pendências enviadas com sucesso!');
        dispatch({ type: 'SET_LOADING_PENDENCIES' });
        dispatch({ type: 'SET_PENDENCIES_LIST_VISIBILITY', payload: false });
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao enviar a(s) pendência(s). Por favor tente novamente.');
        dispatch({ type: 'SET_LOADING_PENDENCIES' });
      });
  };

  const handleUpload = async (event, document_type) => {
    const file = get(event, 'target.files.0');
    try {
      const response = await handleDocumentUpload(file, document_type);
      const { key } = response;
      const newPersonalDocument = { key, document_type, name: file.name };
      dispatch({
        type: 'ADD_PERSONAL_DOCUMENTS',
        payload: newPersonalDocument,
      });
    } catch (error) {
      toast.error('Ocorreu um erro ao enviar arquivo. Tente novamente.');
    }
  };

  const handleRemoveDocument = (key) => {
    const newListDocuments = personalDocuments.filter(d => d.key !== key);
    dispatch({
      type: 'REMOVE_PERSONAL_DOCUMENTS',
      payload: newListDocuments,
    });
  };

  const handlePendency =  async () => {
    const dadosPessoais = 2;

    if(pendingDocument){
      await axios.patch(`${API_URL}/documents/documents-data/${token}/review_document`, { document_id: pendingDocument.id, approved: false })
        .then(() => {
          toast.success('Documento reprovado!')
          fetchPersonalDocuments(token)
        })
        .catch(e => {
          console.error(e)
          return toast.error("Algo deu errado.")
        })
    }

    await axios
      .post(`${API_URL}/onboarding/pendencies`, { ...form, step: dadosPessoais, enrollment: personalData.enrollment.id })
      .then(() => {
        dispatch({ type: 'SET_PENDENCIES_LIST_VISIBILITY', payload: false });
        dispatch({ type: 'CLOSE_PENDENCIES_VISIBILITY' });
        fetchData();
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao criar pendência. Por favor tente novamente mais tarde');
      });
  };

  const handleDocumentApproval = async (doc) =>{
    if (!window.confirm('Tem certeza de que deseja aprovar o documento')) return

    await axios.patch(`${API_URL}/documents/documents-data/${token}/review_document`, { document_id: doc.id, approved: true })
      .then(res => {
        toast.success('Documento aprovado!')
        fetchPersonalDocuments(token)
      })
      .catch(e => {
        console.error(e)
        return toast.error("Algo deu errado.")
      })
  }


  const handleDocumentReproval = async (p_document) => {
    dispatch({ type: 'OPEN_PENDENCIES_VISIBILITY', payload:p_document })
  }

  const getPersonalDocuments = document_type => personalDocuments.filter(d => d.document_type === document_type);

  const handleSavePersonal = async () => {
    dispatch({ type: 'SET_LOADING_PAGE', payload: true });
    try {
      await sendDocuments(token, { documents: personalDocuments });
      window.scroll(0, 0);
      dispatch({ type: 'SET_EDITING' });
      fetchPersonalData();
      fetchPersonalDocuments();
    } catch (err) {
      toast.error('Ocorreu um erro ao editar o formulário.');
      dispatch({ type: 'SET_DOCUMENTS_ERROR', payload: get(err, 'response.data') });
    }
    dispatch({ type: 'SET_LOADING_PAGE', payload: false });
  };

  const handleApproveData = async () => {
    await axios.post((`${API_URL}/documents/documents-data/${token}/approve_data`));
    toast.success('Aluno aprovado com sucesso!');
    dispatch({ type: 'SET_CONFIRMATION_MODAL' });
    fetchData();
  };

  const handleModal = () => {
    dispatch({ type: 'SET_CONFIRMATION_MODAL' });
  };

  const handleEdit = () => {
    dispatch({ type: 'SET_EDITING' });
  };

  const canApprove = () => {
    const document_status = get(personalData, 'enrollment.document_status', {});
    const status = get(personalData, 'enrollment.status', {});
    return (get(document_status, 'id') === 3 && [ENROLLMENT_STATUS.PAYMENT_REVIEWING, ENROLLMENT_STATUS.REVIEWING, ENROLLMENT_STATUS.WAITING_PAYMENT].includes(status.id));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      <Spinner active={loadingPage}>
        <div className="one-page-only mb-4">
          {
            (!isEditing && canApprove()) && (
              <>
                <button
                  type="button"
                  className="float-button mr-6"
                  onClick={() => handleEdit()}
                >
                  <FontAwesomeIcon
                    spin={loadingPage}
                    icon={loadingPage ? 'spinner' : 'edit'}
                  />
                </button>
                <button
                  type="button"
                  className="float-button"
                  onClick={() => dispatch({ type: 'OPEN_PENDENCIES_VISIBILITY' })}
                >
                  <FontAwesomeIcon
                    spin={loadingPage}
                    icon={loadingPage ? 'spinner' : 'plus'}
                  />
                </button>
              </>
            )
          }
          {
            isEditing
              ? (
                <DocumentsDetailsForm
                  onSavePersonal={handleSavePersonal}
                  personalDocumentsTypes={personalDocumentsTypes}
                  onUpload={handleUpload}
                  onRemoveDocument={handleRemoveDocument}
                  getPersonalDocuments={getPersonalDocuments}
                  onEdit={handleEdit}
                  errors={errors}
                />
              ) : (
                <DocumentsDetailsView
                  onApproveDocument={handleDocumentApproval}
                  onReproveDocument={handleDocumentReproval}
                  personalData={personalData}
                  personalDocumentsTypes={personalDocumentsTypes}
                  personalDocuments={personalDocuments}
                  pendencies={pendencies}
                  loadingPendencies={loadingPendencies}
                />
              )
          }
          <div className="btn-actions offset-md-8 mb-4 col-md-4 mt-4 p-0 col-sm-12">
            <button type="button" className="btn btn-black mr-2" onClick={() => history.push('/academic')}>Voltar</button>
            {
              (pendencies.filter(f => !f.resolved_at).length > 0 || hasPendencies)
              && (
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={get(personalData, 'enrollment.status.id') === 3 || loadingPendencies}
                  onClick={sendPendencies}
                >
                  Enviar todas pendências
                </button>
              )
            }
            {
              (canApprove() && !hasPendencies) && (
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isEditing}
                  onClick={() => handleModal()}
                >
                  {isEditing ? 'Editando' : 'Aprovar'}
                </button>
              )
            }
          </div>
        </div>
        <Modal
          visible={confirmationModal}
          title="Confirmação"
          onCancel={() => dispatch({ type: 'SET_CONFIRMATION_MODAL' })}
          onConfirm={handleApproveData}
        >
          Você tem certeza que deseja aprovar este aluno?
        </Modal>
        <CSSTransition
          in={showPendencies}
          timeout={300}
          classNames="expand-animation"
          unmountOnExit
        >
          <aside className="financer-float">
            <button
              className="btn-none float-right"
              type="button"
              onClick={() => dispatch({ type: 'CLOSE_PENDENCIES_VISIBILITY' })}
            >
              <FontAwesomeIcon icon="times" />
            </button>
            <h4 className="mt-3 bt-3">Pendências</h4>
            <hr />
            <Form
              id="pendencias"
              onValueChange={values => dispatch({ type: 'SET_FORM', payload: values })}
            >
              <InputTypeArea
                field="description"
                initialValue={pendingDocument ? `Pendência para documento: ${pendingDocument.document_type} \n` : ""}
                label="Descrição"
                row="10"
              />
              <button type="submit" className="btn btn-primary" onClick={() => handlePendency()}>Criar Pendência</button>
            </Form>
          </aside>
        </CSSTransition>
      </Spinner>
    </div>
  );
};

DocumentsDetailPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.string,
  }).isRequired,
};

export default withRouter(DocumentsDetailPage);
