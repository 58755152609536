import React from 'react';
import PropTypes from 'prop-types';


const Container = ({ title, children }) => (
  <div className="container">
    <div className="summary-header">
      <h2 style={{ fontSize: '1.25rem', marginTop: '4rem' }}>{title}</h2>
    </div>
    {children}
  </div>
);

Container.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Container;
