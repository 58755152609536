import React from 'react';

class Sidebar extends React.Component {
  render() {
    return(
      <aside className="sidebar-base">
        <div className="sidebar-avatar">
          <figure>
            {/* <img
            /> */}
          </figure>
          <div className="">Nome da Pessoa</div>
        </div>
        <ul className="">
          <li className="">Teste</li>
        </ul>
      </aside>
    );
  }
}

export default Sidebar;
