import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputTypeText, InputTel } from '../elements';

const HealthContacts = ({
  onRemoveContacts,
  index,
}) => (
  <div className="row">
    <InputTypeText
      col="6"
      label="Nome"
      field="name"
    />
    <InputTel
      col="4"
      label="Telefone"
      field="landline_number"
    />
    <button
      type="button"
      className="btn-none"
      onClick={() => onRemoveContacts(index)}
    >
      <div className="navigation-notification-style">
        <FontAwesomeIcon icon="times-circle" />
      </div>
    </button>
    <InputTypeText
      col="6"
      label="Parentesco"
      field="relationship"
    />
    <InputTel
      col="4"
      label="Celular"
      field="phone_number"
    />
  </div>
);

export default HealthContacts;
