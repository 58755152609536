import axios from 'axios';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from '.';
import { DOCUMENTS } from '../../lib/config';
import { Ball, Icon } from '../elements';


class DocumentViewer extends React.Component {
  state = {
    showModal: false,
    fileSrc: undefined,
    modalName: false,
    pendingDocument: {}
  }

  handleModal = document => axios.get(document.url)
    .then(response => this.setState({
      showModal: true,
      fileSrc: get(response, 'data.url'),
      modalName: document.name,
    })).catch(() => this.setState({ showModal: false }));


  render() {

    const { documents, centralizeContent, onDocumentApproval, onDocumentReproval } = this.props;
    const { showModal, fileSrc, modalName } = this.state;
    return (
      <>
        <div className="documents-base row mb-5 d-flex align-items-strech" style={{ justifyContent: centralizeContent ? 'center' : 'start' }}>
          {documents.map(document => (
            <div className="col-sm-12 col-md-6 mt-5">
              <div className="documents-uploaded" style={{ backgroundColor: 'rgb(3, 159, 151)', color: 'white' }}>
                <div className="d-flex align-items-center">
                  <div className="documents-length-0">
                    {get(DOCUMENTS.TYPE.filter(type => type.id === document.document_type), '[0].label', 'Documento')} - {document.name}
                    <label className="d-block font-italic text-sm" style={{ fontSize: '0.9em' }}>Enviado em: {document.uploaded_at}</label>
                  </div>
                  <button className="btn-none" type="button" onClick={() => this.handleModal(document)}>
                    <Ball
                      icon="view"
                      extraSmall
                      bgColor="white"
                      iconWidth="16"
                    />
                  </button>
                  {/* <div
                    style={{
                      flexDirection: 'column',
                      pointerEvents: document.approved === null ?   "auto" : "none",
                      opacity: document.approved === null ?  "1" : "0.5"
                    }}
                    className="d-flex align-items-center justify-content-start"
                  >
                    {document.approved === null || document.approved === true ? <button
                      onClick={() => onDocumentApproval(document)}
                      className="btn-none">
                      <Icon
                        name="check"
                      />
                    </button> : null}

                    {document.approved === null || document.approved === false ? <button
                      onClick={() => onDocumentReproval(document)}
                      className="btn-none"
                      disabled={document.approved}
                    >
                      <Icon
                        name="error"
                        width="20px"
                        fill="#fff"
                        style={{ cursor: "none" }}
                      />
                    </button> : null}

                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
        <Modal visible={showModal} onClose={() => this.setState({ showModal: false })} title={modalName} src={fileSrc} />
      </>
    );
  }
}

DocumentViewer.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    document_type: PropTypes.number,
    uploaded_at: PropTypes.string,
  })),
  centralizeContent: PropTypes.bool,
};

DocumentViewer.defaultProps = {
  documents: [{
    document_type: null,
  }],
  centralizeContent: false,
};

export default DocumentViewer;
