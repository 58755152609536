import React from 'react';
import { get } from 'lodash';
import { withFormApi, Scope } from 'informed';
import PropTypes from 'prop-types';
import ComponentWithToken from './CompontentWithToken';
import {
  InputTypeText, FieldSet, InputTypeDropdown, Spinner, InputCpf,
} from '../elements';
import {
  fetchNationalities,
  fetchMaritalStatuses,
  fetchStates,
  fetchCities,
} from '../services/options_service';
import { Endereco, Contato } from '../forms';
import AutosaveFeedback from './autoSaveFeedBack';


class FinanceOfficerForm extends ComponentWithToken {
  state = {
    loading: true,
    countries: [],
    states: [],
    cities: [],
    profilePicture: undefined,
    profileKey: null,
    dbCep: null,
    uploadingProfileImage: false,
    errors: [],
    nationalities: [],
    maritalStatuses: [],
    genders: [],
    disabilities: [],
    handicapOptions: [],
    specialNeeds: [],
    selectedDisabilities: [],
    selectedSpecialNeeds: [],
    fields: {},
  }

  previousErrors = {}

  componentDidMount() {
    this.fetchOptions()
      .then(() => this.setState({ loading: false }));
  }

  fetchCities = state => fetchCities(state).then(response => this.setState({ cities: response }));

  fetchOptions = () => {
    const options = [
      {
        field: 'nationalities',
        fetchFunction: fetchNationalities,
      },
      {
        field: 'maritalStatuses',
        fetchFunction: fetchMaritalStatuses,
      },
      {
        field: 'states',
        fetchFunction: fetchStates,
      },
    ];
    return Promise.all([
      options.map(option => option.fetchFunction().then(response => this.setState({
        [option.field]: response,
      }))),
    ]);
  };

  render() {
    const {
      loading, nationalities, maritalStatuses, fields,
    } = this.state;

    const { onChange, formApi } = this.props;
    const formState = formApi.getState();

    return (
      <>
        <Spinner active={loading}>
          <FieldSet title="Responsável Financeiro">
            <Scope scope="finance_officer">
              <InputTypeText autosaveFeedBack={<AutosaveFeedback status={get(fields, 'real_name')} />} field="real_name" label="Nome" col="6" helper="Seu nome" onValueChange={value => onChange('real_name', value)} />
              <InputTypeDropdown autosaveFeedBack={<AutosaveFeedback status={get(fields, 'nationality')} />} options={nationalities} field="nationality" label="Nacionalidade" col="3" required onValueChange={value => onChange('nationality', value)} />
              <InputCpf autosaveFeedBack={<AutosaveFeedback status={get(fields, 'cpf')} />} field="cpf" label="CPF" col="3" helper="Ex: 000.000.000-00" required onValueChange={value => onChange('cpf', value)} />
              <InputTypeDropdown autosaveFeedBack={<AutosaveFeedback status={get(fields, 'marital_status')} />} options={maritalStatuses} field="marital_status" label="Estado Civil" col="3" required onValueChange={value => onChange('marital_status', value)} />
              <InputTypeText autosaveFeedBack={<AutosaveFeedback status={get(fields, 'rg')} />} field="rg" label="RG" col="3" required onValueChange={value => onChange('rg', value)} />
              <Endereco fields={fields} scope="finance_officer" title="Endereço do Responsável Financeiro" onValueChange={onChange} formApi={formApi} dbCep={formState.zip_code} />
              <Contato fields={fields} title="Dados de Contato do Responsável Financeiro" onValueChange={onChange} />
            </Scope>
          </FieldSet>
        </Spinner>
      </>
    );
  }
}

FinanceOfficerForm.propTypes = {
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  financeOfficer: PropTypes.shape(),
};

FinanceOfficerForm.defaultProps = {
  onChange: () => {},
  financeOfficer: {},
};

export default withFormApi(FinanceOfficerForm);
