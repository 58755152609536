import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CollapsedMenuItem from './CollapsedMenuItem';

class SidebarItem extends React.Component {
  state = {
    isOpen: true,
  };

  handleClick = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const {
      label,
      icon,
      children,
      collapsed,
    } = this.props;
    const { isOpen } = this.state;

    if (collapsed) {
      return <CollapsedMenuItem icon={icon} isOpen={isOpen} onClick={(this.handleClick)} />;
    }

    return (
      <Fragment>
        <li className="menu-element">
          <button type="button" onClick={!collapsed ? this.handleClick : null} className={`clickable ${isOpen ? 'active-menu' : ''}`}>
            <FontAwesomeIcon className="menu-icon" icon={icon} />
            <span className="mr-2">{label}</span>
            {
              !collapsed
              && (
                <FontAwesomeIcon className="chevron" icon={`chevron-${isOpen ? 'up' : 'down'}`} />
              )
            }
          </button>
          {isOpen && children && (
            <ul className="menu-list-internal animated fadeIn">
              {children}
            </ul>
          )}
        </li>
      </Fragment>
    );
  }
}

SidebarItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
  collapsed: PropTypes.bool,
};

SidebarItem.defaultProps = {
  label: '',
  icon: '',
  children: null,
  collapsed: false,
};

export default SidebarItem;
