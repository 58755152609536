import Axios from 'axios';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { createContext, ReactElement, useEffect, useReducer } from 'react';
import { API_URL } from '../../../../lib/const';
import { childrenIsFunction, IMessage, IMessageState, IMessageStateAction } from '../../types';

type MessageStateProps = {
  children: Function | ReactElement
}

const initialState: IMessageState = {
  fecthing: false,
  messages: [],
};

export const MessageContext = createContext<IMessageState>(initialState);

const reducer = (state: IMessageState, action: IMessageStateAction) => {
  switch (action.type) {
    case 'FETCH_MESSAGES':
      return { ...state, fecthing: true, };
    case 'FETCH_MESSAGES_MESSAGES_SUCCESS':
      return { ...state, fecthing: false, messages: action.payload };
    default:
      return state;
  }
};

const MessageState = ({ children }: MessageStateProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    (async () => {
      dispatch({ type: 'FETCH_MESSAGES' });
      const response = await Axios.get(`${API_URL}/messages`);
      dispatch({ type: 'FETCH_MESSAGES_MESSAGES_SUCCESS', payload: response.data });
    })();
  }, []);

  const getMessage = (name: string, context: undefined | 'RENEW_ENROLL' | 'ENROLL') => {
    const { messages } = state;
    const message = messages.find(
      (m: IMessage) => m.name === name && (m.context === context || context === undefined));

    return get(message, 'value');
  }

  const contextValue = {
    ...state,
    getMessage,
  }

  return (
    <MessageContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(state) : children}
    </MessageContext.Provider>
  );
};

MessageState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MessageState;
