import React from 'react';
import { fetchStatus } from '../services/options_service';
import { InputTypeDropdown } from '.';

class InputDropdownStatus extends React.Component {
  state = {
    options: [],
  }

  componentDidMount() {
    this.fetchStatus();
  }

  fetchStatus = () => {
    fetchStatus()
      .then((data) => {
        this.setState({
          options: data,
        });
      });
  }

  render() {
    const { ...rest } = this.props;
    const { options } = this.state;

    return (
      <InputTypeDropdown
        field="status"
        label="Status"
        options={options}
        {...rest}
      />
    );
  }
}

export default InputDropdownStatus;
