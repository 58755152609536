import PropTypes from 'prop-types';
import React from 'react';

class FieldSet extends React.Component {
  render() {
    const { title, children } = this.props;

    return (
      <div>
        {
        title && (
          <div className="field-set">
            <h3>{ title }</h3>
            <hr className="field-set-line" />
          </div>
        )}
        <div className="field-set-slot">
          { children }
        </div>
      </div>
    );
  }
}

FieldSet.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

FieldSet.defaultProps = {
  children: null,
};

export default FieldSet;
