import React from 'react';
import PropTypes from 'prop-types';

class KeyValue extends React.Component {
  render() {
    const { label, title, col } = this.props;

    return (
      <div className={`col-md-${col} col-sm-12 mb-3`}>
        <h6>{ label }</h6>
        <strong>{ title === '' ? '-' : title }</strong>
      </div>
    );
  }
}

KeyValue.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  col: PropTypes.string,
};

KeyValue.defaultProps = {
  title: '-',
  label: '',
  col: '4',
};

export default KeyValue;
