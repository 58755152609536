import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'informed';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { InputTypeDropdown, InputTypeArea } from '../../elements';
import { fetchSteps as fetchStepFunction } from '../../services/enrollment_services';


const CreatePendecyForm = ({
  onShowForm, showForm, onHideForm, onCreatePendency, onValueChange,
}) => {
  const [steps, setStep] = useState([]);

  const mapSteps = s => ({ value: s.id, label: s.name, ...s });

  useEffect(() => {
    const fetchSteps = async () => {
      const result = await fetchStepFunction();
      setStep(result.filter(step => step.has_pendency).map(mapSteps));
    };
    fetchSteps();
  }, []);

  return (
    <>
      <button type="button" className="float-button" onClick={onShowForm}>
        <i className="fa fa-plus" />
      </button>
      <CSSTransition
        in={showForm}
        timeout={300}
        classNames="expand-animation"
        unmountOnExit
      >
        <aside className="financer-float">
          <button className="btn-none float-right" type="button" onClick={onHideForm}>
            <FontAwesomeIcon icon="times" />
          </button>
          <h4 className="mt-3 bt-3">Pendências</h4>
          <hr />
          <Form
            id="pendencias"
            onValueChange={onValueChange}
          >
            <InputTypeDropdown field="step" options={steps} label="Selecione uma opção" />
            <InputTypeArea field="description" label="Descrição" row="10" />
            <button type="submit" className="btn btn-primary" onClick={onCreatePendency}>Criar Pendência</button>
          </Form>
        </aside>
      </CSSTransition>
    </>
  );
};

CreatePendecyForm.propTypes = {
  onShowForm: PropTypes.func.isRequired,
  onHideForm: PropTypes.func.isRequired,
  onCreatePendency: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  showForm: PropTypes.bool.isRequired,
};

export default CreatePendecyForm;
