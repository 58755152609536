import React, { Fragment } from 'react';


class WaitingProject extends React.Component {

  render() {
    return (
      <Fragment>
        <div className="login-background">
          <div className="manutencao-card shadow p-5 text-center">
          <div className="text-center mb-5">
            <img src={require('../../assets/img/logo-green.svg')} alt="notification" style={{ maxWidth: '18rem' }}/>
          </div>
          <div className="text-center  mb-3 ">
            <h3 className="mb-3" style={{ fontWeight: 100, color: '#777777'}}>O processo de Matrícula começará:</h3>
              <h3 style={{ fontWeight: 700, color: '#777777' }}> dia: 27/11 às 09:00 hrs</h3>
          </div>
        </div>
        </div>
      </Fragment>
    );
  }
}

export default WaitingProject;
