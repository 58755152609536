import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from '../../assets/img/logo.svg';
import { logOut } from '../../lib/auth';

class Header extends React.Component {
  handleLogout = () => {
    const { history } = this.props;
    logOut();
    history.push('/login');
  }

  render() {
    return (
      <div>
        <header className="navigation">
          <div className="navigation-adjustment">
            <Link to="/dashboard">
              <img src={logo} className="navigation-logo" alt="notification" width="200" />
            </Link>
            <div className="navigation-title-system">
              <span> ADMINISTRAÇÃO DO PROCESSO DE <strong>MATRÍCULA</strong></span>
            </div>
            <div className="navigation-menu">
              <div className="menu-item">
                <button type="button" onClick={this.handleLogout}>
                  <FontAwesomeIcon icon="sign-out-alt" />
                  <span className="helper">Logout</span>
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="navigation-space" />
      </div>
    );
  }
}

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(Header);
