import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from '../../../elements';


class CandidatesCard extends React.Component {
  render() {
    const { candidates, inviteProcess, onAddUserToProcess } = this.props;

    const inviteProcessEnrollmentsIds = get(inviteProcess, 'invites', []).map(i => i.enrollment_id)
    const availableCandidates = candidates.filter(c => {
      return !inviteProcessEnrollmentsIds.includes(c.enrollment_id)
    })

    return (
      <Card title="Candidatos">
        {availableCandidates.map(c => (
          <div
            onClick={() => onAddUserToProcess(c, inviteProcess)}
            className="d-flex justify-content-between align-items-center candidate-card"
          >
            <p className="m-0">{c.real_name} ({c.position || '-'})</p>
            {inviteProcess.id && (
              <FontAwesomeIcon
                className="green"
                size="2x"
                icon="chevron-right"
              />
            )}
          </div>
        ))}
      </Card>
    );
  }
}

CandidatesCard.propTypes = {
  candidates: PropTypes.arrayOf(PropTypes.shape({
    enrollment_id: PropTypes.number,
    real_name: PropTypes.string,
  })),
  inviteProcess: PropTypes.shape({
    id: PropTypes.number,
    invites: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  onAddUserToProcess: PropTypes.func,
};

CandidatesCard.defaultProps = {
  candidates: [],
  inviteProcess: {},
  onAddUserToProcess: () => {},
};

export default CandidatesCard;
