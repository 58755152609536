import React from 'react';
import PropTypes from 'prop-types';
import PersonalDataSummary from './PersonalDataSummary';
import { FieldSet } from '../../elements';
import DocumentsView from './DocumentsView';
import PendecyList from './PendecyList';

const DocumentsDetailsView = ({
  personalData,
  personalDocumentsTypes,
  personalDocuments,
  pendencies,
  loadingPendencies,
  onApproveDocument,
  onReproveDocument
}) => (
  <>
    <PersonalDataSummary personalData={personalData} />
    <FieldSet title="DOCUMENTOS" />
    <div className="documents-base row">
      <DocumentsView
        audittableDocuments
        personalDocuments={personalDocuments}
        personalDocumentsTypes={personalDocumentsTypes}
        onApproveDocument={onApproveDocument}
        onReproveDocument={onReproveDocument}
        auditPermission={true}
      />
    </div>
    <PendecyList
      pendencies={pendencies}
      loadingPendencies={loadingPendencies}
    />
  </>
);

DocumentsDetailsView.propTypes = {
  personalData: PropTypes.arrayOf(PropTypes.shape({
    profile_img_url: PropTypes.string,
    real_name: PropTypes.string,
    assumed_name: PropTypes.string,
    nationality: PropTypes.string,
    birth_country: PropTypes.string,
    birth_state: PropTypes.string,
    birth_city: PropTypes.string,
    cpf: PropTypes.string,
    rne: PropTypes.string,
    birth_date: PropTypes.string,
    marital_status: PropTypes.string,
    gender: PropTypes.string,
    rg_issuing_body: PropTypes.string,
    rg_issue_date: PropTypes.string,
    electoral_card: PropTypes.string,
    military_certificate: PropTypes.string,
    fathers_name: PropTypes.string,
    mothers_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    landline: PropTypes.string,
    extra_phone_number: PropTypes.string,
    zip_code: PropTypes.string,
    street_address: PropTypes.string,
    address_number: PropTypes.string,
    complement_address: PropTypes.string,
    neighborhood: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
  })),
  personalDocumentsTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  personalDocuments: PropTypes.arrayOf(PropTypes.shape({
    document_type: PropTypes.number,
    external_url: PropTypes.string,
    id: PropTypes.number,
    key: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  })),
  pendencies: PropTypes.arrayOf(PropTypes.object),
  loadingPendencies: PropTypes.bool,
};

DocumentsDetailsView.defaultProps = {
  personalData: [],
  personalDocumentsTypes: [],
  personalDocuments: [],
  pendencies: [],
  loadingPendencies: false,
  onApproveDocument: () => undefined
};

export default DocumentsDetailsView;
