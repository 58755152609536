import axios from 'axios';
import { get } from 'lodash';
import React from 'react';
import { API_URL } from '../../lib/const';
import { PopupChat, Step, Stepper } from '../components';
import ComponentWithToken from '../components/CompontentWithToken';
import NoticeStep from '../components/NoticeStep';
import TermOfAbsence from '../components/TermOfAbsence';
import PersonalDataStep from '../components/PersonalDataStep';
import { EndStep, EnrollmentSummaryForm, FinanceDataForm, FinanceDataProuniFies, Payment } from '../forms';
import DocumentsStep from '../forms/DocumentsStep';
import HealthRecord from '../forms/HealthRecord';
import { MessageContext } from '../v2/common/states/MessageState';
import EndProject from './EndProject';
import Term from './Term';
import ChatInbox from '../components/ChatInbox'

class Enroll extends ComponentWithToken {
  state = {
    steps: {},
    loading: true,
    loadingChat: true,
    loadingEnroll: true,
    current_step: 'NOTICE_STEP',
    steppers: [],
    closed_invite_process: false,
    gratuity: {},
    closedAt: null,
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchFlow();
    this.fetchStatus();
    this.fetchSteps(true);
    this.fetchChat();
  }

  get steps() {
    const { steppers } = this.state;
    return steppers.map(item => (
      {
        ...item,
        description: item.id === 'CONTRACT_STEP' ? this.paymentText() : item.description,
      }));
  }

  getChild = (step, back, next, showProof, gratuity) => {
    switch (step) {
      case 'TERM_OF_ABSENCE_STEP':
        return <TermOfAbsence onClick={() => this.jumpStep(next)} />;

      case 'PERSONAL_DATA_STEP':
        return <PersonalDataStep backClick={() => this.jumpStep(back)} onClick={() => this.jumpStep(next)} />;

      case 'FINANCE_DATA_STEP':
        if (showProof) {
          return <FinanceDataForm backClick={() => this.jumpStep(back)} onClick={() => this.jumpStep(next)} />;
        }
        return <FinanceDataProuniFies gratuity={gratuity} backClick={() => this.jumpStep(back)} onClick={() => this.jumpStep(next)} />;

      case 'SUMMARY_STEP':
        return <EnrollmentSummaryForm backClick={() => this.jumpStep(back)} onClick={() => this.jumpStep(next)} />;

      case 'CONTRACT_STEP':
        return <Payment token={this.token} backClick={() => this.jumpStep(back)} />;

      case 'FINALIZED_STEP':
        return <EndStep token={this.token} backClick={() => this.jumpStep(back)} />;

      case 'DOCUMENT_STEP':
        return <DocumentsStep backClick={() => this.jumpStep(back)} nextClick={() => this.jumpStep(next)} />;

      case 'HEALTH_STEP':
        return <HealthRecord backClick={() => this.jumpStep(back)} onNext={() => this.jumpStep(next)} />;

      case 'TERM_STEP':
        return <Term backClick={() => this.jumpStep(back)} onClick={() => this.jumpStep(next)} />;

      default:
        return <NoticeStep onClick={() => this.jumpStep(next)} />;
    }
  }

  fetchEndProcess() {
    const { history } = this.props;
    history.push('/encerramento');
  }

  paymentText() {
    const {
      status, showProof, ProofSent, kind,
    } = this.state;
    return (
      <MessageContext.Consumer>
        {
          ({ getMessage }) => {
            if (!showProof) {
              return getMessage('CONTRACT_WITHOUT_PAYMENT', kind);
            }
            if (status === 2) {
              return getMessage('CONTRACT_WITH_PAYMENT', kind);
            }
            if (status === 3) {
              return getMessage('CONTRACT_WITH_PENDENCIES', kind);
            }
            if (status === 5 || ProofSent) {
              return getMessage('CONTRACT_WITHOUT_PAYMENT', kind);
            }
            if (status === 6) {
              return 'Todas as etapas ja foram concluídas.';
            }
            return getMessage('CONTRACT_WITHOUT_PAYMENT', kind);
          }
        }
      </MessageContext.Consumer>
    );
  }

  jumpStep(step) {
    this.fetchSteps();
    this.fetchStatus();
    this.setState({ current_step: step });
  }

  fetchStatus() {
    this.setState({ loadingEnroll: true });
    axios.get(`${API_URL}/onboarding/enroll/${this.token}`)
      .then((response) => {
        localStorage.setItem('boleto_token', get(response, 'data.boleto_token'));
        this.setState({
          status: get(response, 'data.enrollment_status'),
          kind: get(response, 'data.kind') === 'enroll' ? 'ENROLL' : 'RENEW_ENROLL',
          showProof: get(response, 'data.show_proof'),
          ProofSent: get(response, 'data.proof_sent'),
          gratuity: get(response, 'data.gratuity'),
          closed_invite_process: get(response, 'data.closed_invite_process'),
          closedAt: get(response, 'data.closed_at'),
          loadingEnroll: false,
        });
      });
  }

  fetchChat() {
    axios.get(`${API_URL}/core/chat/${this.token}`)
      .then((response) => {
        this.setState({
          currentUser: get(response, 'data.current_user', {}),
          supportUser: get(response, 'data.support_user', {}),
          signature: get(response, 'data.signature', {}),
          loadingChat: false,
        });
      });
  }

  fetchSteps(updateCurrentStep = false) {
    const { history } = this.props;
    this.setState({ loading: true });
    axios.get(`${API_URL}/onboarding/enrollments/${this.token}/steps`)
      .then((response) => {
        this.setState({
          loading: false,
          steps: response.data,
        });
        if (updateCurrentStep) this.setState({ current_step: get(response.data, 'current_step', 'NOTICE_STEP') });
      })
      .catch(() => {
        history.push('/login');
      });
  }

  fetchFlow = () => {
    const { history } = this.props;
    this.setState({ loading: true });
    axios.get(`${API_URL}/onboarding/flow-and-stages/${this.token}`)
      .then((response) => {
        const steppers = response.data.stages.map(item => ({
          ...item,
          id: item.key,
        }));
        this.setState({ steppers });
      })
      .catch(() => {
        history.push('/login');
      });
  };

  renderStatus() {
    const { steps, current_step } = this.state;
    const default_steps = this.steps;
    const current_component_step = default_steps.filter(step => step.id === current_step)[0];
    if (get(current_component_step, 'step')) {
      return get(steps, current_component_step.step);
    }
    if (['SUMMARY_STEP', 'CONTRACT_STEP'].some(item => get(current_component_step, 'id') === item)) {
      return 'nonFieldStatus';
    }
    return null;
  }

  renderCurrentStep() {
    const { current_step, showProof, gratuity } = this.state;
    const default_steps = this.steps;
    if (current_step) {
      const current_component_step = default_steps.filter(step => step.id === current_step)[0];
      if (current_component_step) {
        return (
          <Step status={this.renderStatus()} label={current_component_step.label} description={current_component_step.description} ballLabel={current_component_step.position} active>
            {this.getChild(current_component_step.id, current_component_step.back_step, current_component_step.next_step, showProof, gratuity)}
          </Step>
        );
      }
    }

    return null;
  }

  render() {
    const {
      steppers, current_step, steps, loading, loadingChat, currentUser, supportUser, signature,
      loadingEnroll, closed_invite_process, closedAt,
    } = this.state;

    return (
      <>
        {!loadingChat && <ChatInbox sourceUser={currentUser} supportUser={supportUser} client="ENROLL"/>}
        {/* {!loadingChat && <PopupChat sourceUser={currentUser} supportUser={supportUser} signature={signature} />} */}
        {
          closed_invite_process ? (
            <EndProject closedAt={closedAt} />
          ) : (
            <div className="container">
              {steppers.length > 0 && <Stepper steppers={steppers} active={current_step} status={steps} />}
              {!(loading || loadingEnroll) && this.renderCurrentStep()}
            </div>
          )
        }
      </>
    );
  }
}

export default Enroll;
