import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio } from 'informed';


class HandicapForm extends React.Component {
  render() {
    const {
      error, options, onValueChange, autosaveFeedBack,
    } = this.props;
    return (
      <>
        <p className="dim">Possui alguma Deficiência, Transtorno Global do Desenvolvimento, ou Habilidades/Superdotação?</p>
        <div style={{ display: 'flex' }}>
          <RadioGroup style={{ display: 'flex' }} field="handicap" onValueChange={value => onValueChange('handicap', value)}>
            <div id="handicap-options" className="radio-group">
              {options.map(h => (
                <label key={h.key} className="input-radio-type" htmlFor={`handicap-${h.id}`}>{h.name}
                  <Radio value={h.id} id={`handicap-${h.id}`} />
                  <span className="radiomark" />
                </label>
              ))}
            </div>
          </RadioGroup>
          <div style={{ display: 'flex', marginTop: '5px' }}>
            {autosaveFeedBack}
          </div>
        </div>
        {error && <label className="red" htmlFor="handicap-options"> {error}</label>}
      </>
    );
  }
}

HandicapForm.propTypes = {
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      key: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  onValueChange: PropTypes.func,
  autosaveFeedBack: PropTypes.element,
};

HandicapForm.defaultProps = {
  error: undefined,
  onValueChange: () => null,
  autosaveFeedBack: null,
};

export default HandicapForm;
