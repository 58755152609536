import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StepHeader } from '../elements';

class Step extends React.Component {
  render() {
    const {
      label, description, ballLabel, children, onClick, active, status,
    } = this.props;
    return (
      <Fragment>
        <div className="mb-5">
          <div onClick={onClick}>
            <StepHeader
              label={label}
              description={description}
              ballLabel={ballLabel}
              colorBall={active ? 'white dark-silver' : 'medium-silver  dark-silver'}
              backgroundColor={active ? 'silver' : 'white'}
              status={status}
            />
          </div>
          {
            active && (
            <div className="step-content">
              <div className="step-content-slot">
                {children}
              </div>
            </div>
            )}
        </div>
      </Fragment>
    );
  }
}

Step.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  ballLabel: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Step.defaultProps = {
  label: '',
  description: '',
  ballLabel: '',
  children: null,
  status: null,
  onClick: () => null,
};

export default Step;
