import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NotificationItem } from '../elements';
import { CSSTransition } from 'react-transition-group';

class Notification extends React.Component {
  render() {
    const { showNotification, notifications, onClickTimes } = this.props;
    return(
      <Fragment>
        <CSSTransition
          in={showNotification}
          timeout={300}
          classNames="right-animation"
          unmountOnExit
        >
          <aside className="notification-side-menu">
            <button type="button" onClick={onClickTimes} className="float-right mr-3 btn-none">
              <i className="fa fa-times" />
            </button>
            <h5 className="ml-3 mt-3">Notificações:</h5>
            <hr />
            {
              notifications.length > 0 ? (
                notifications.map(notification => (
                  <NotificationItem itemName={notification.title} itemDescription={notification.description} buttonMessage="OK" />
                ))
              ) : (
                <div className="notification-base">Sem Notificações!</div>
              )
            }
          </aside>
        </CSSTransition>
      </Fragment>
    );
  }
}

Notification.propTypes = {
  showNotification: PropTypes.bool,
};

Notification.defaultProps = {
  showNotification: false,
};

export default Notification;
