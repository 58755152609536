import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { API_URL } from '../../lib/const';

export const handleUpload = async (file) => {
  const data = new FormData();
  data.append('file', file);
  data.append('is_profile_img', true);

  try {
    const response = await axios.post(`${API_URL}/uploads/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return ({ data: response.data, file });
  } catch (e) {
    toast.error(get(e, 'response.data.file[0]', 'Imagem enviada é inválida'), { position: toast.POSITION.BOTTOM_RIGHT });
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
    return Promise.reject(e);
  }
};

export const handleDocumentUpload = async (file, document_type, config = {}) => {
  const data = new FormData();
  data.append('file', file);
  data.append('document_type', document_type);

  try {
    const response = await axios.post(`${API_URL}/uploads/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: get(config, 'onUploadProgress', () => null),
    });
    return response.data;
  } catch (e) {
    toast.error(get(e, 'response.data.file[0]', 'Imagem enviada é inválida'), { position: toast.POSITION.BOTTOM_RIGHT });
    return Promise.reject(e);
  }
};

export const showModal = (file) => {
  axios.get(`${API_URL}/uploads/${file.key}`)
    .then(response => ({
      showModal: true,
      content: file,
      fileSrc: get(response, 'data.url', null),
    }));
};
