import React from 'react';
import ColumnRules from './ColumnRules';


const ImportDadosPessoaisColumnRules = () => {
  const rules = [
    {
      name: 'pc_fies',
      required: false,
      options: ['Informação da porcentagem de fies do aluno.'],
    },
    {
      name: 'tipo',
      required: true,
      options: ['Informação do tipo do processo (MATRICULA ou REMATRICULA).'],
    },
    {
      name: 'aluno',
      required: true,
      options: ['Informação do nome do aluno.'],
    },
    {
      name: 'anoseggrau',
      required: false,
      options: ['Informação do anoseggrau do aluno.'],
    },
    {
      name: 'bairro',
      required: false,
      options: ['Informação do bairro do aluno.'],
    },
    {
      name: 'cep',
      required: false,
      options: ['Informação do cep do aluno.'],
    },
    {
      name: 'certificadomilitar',
      required: false,
      options: ['Informação do certificado militar do aluno.'],
    },
    {
      name: 'cpf',
      required: false,
      options: ['Informação do cpf do aluno.'],
    },
    {
      name: 'cidade',
      required: false,
      options: ['Informação do cidade do aluno.'],
    },
    {
      name: 'complemento',
      required: false,
      options: ['Informação do complemento do aluno.'],
    },
    {
      name: 'curriculo',
      required: true,
      options: ['Informação do currículo do aluno.'],
    },
    {
      name: 'curso',
      required: true,
      options: ['Informação do curso do aluno.'],
    },
    {
      name: 'dtnasc',
      required: false,
      options: ['Informação da data nascimento do aluno.'],
    },
    {
      name: 'email',
      required: true,
      options: ['Informação do e-mail do aluno.'],
    },
    {
      name: 'emissoridentidade',
      required: false,
      options: ['Informação do emissor da identidade do aluno.'],
    },
    {
      name: 'endereco',
      required: false,
      options: ['Informação do endereço do aluno.'],
    },
    {
      name: 'estadocivil',
      required: false,
      options: ['Informação do estado civil do aluno.'],
    },
    {
      name: 'etapanatural',
      required: true,
      options: ['Informação da etapa natural do aluno.'],
    },
    {
      name: 'fies',
      required: false,
      options: ['Informação do fies do aluno.'],
    },
    {
      name: 'identidade',
      required: false,
      options: ['Informação da identidade do aluno.'],
    },
    {
      name: 'mae',
      required: false,
      options: ['Informação da mãe do aluno.'],
    },
    {
      name: 'nacionalidade',
      required: false,
      options: ['Informação da nacionalidade do aluno.'],
    },
    {
      name: 'naturalidade',
      required: false,
      options: ['Informação da naturalidade do aluno.'],
    },
    {
      name: 'numero',
      required: false,
      options: ['Informação do número do aluno.'],
    },
    {
      name: 'pai',
      required: false,
      options: ['Informação do pai do aluno.'],
    },
    {
      name: 'pais_2grau',
      required: false,
      options: ['Informação dos pais de 2º grau do aluno.'],
    },
    {
      name: 'pais_nascimento',
      required: false,
      options: ['Informação dos pais nascimento do aluno.'],
    },
    {
      name: 'prouni',
      required: false,
      options: ['Informação do prouni do aluno.'],
    },
    {
      name: 'raca',
      required: false,
      options: ['Informação da raça do aluno.'],
    },
    {
      name: 'sexo',
      required: false,
      options: ['Informação do sexo do aluno.'],
    },
    {
      name: 'tel1',
      required: false,
      options: ['Informação do telefone 1 do aluno.'],
    },
    {
      name: 'tel2',
      required: false,
      options: ['Informação do telefone 2 do aluno.'],
    },
    {
      name: 'tel3',
      required: false,
      options: ['Informação do telefone 3 do aluno.'],
    },
    {
      name: 'tipodeficiencia',
      required: false,
      options: ['Informação do tipo de deficiência do aluno.'],
    },
    {
      name: 'tituloeleitor',
      required: false,
      options: ['Informação do título eleitor do aluno.'],
    },
    {
      name: 'tp_escola',
      required: false,
      options: ['Informação do tipo de escola do aluno.'],
    },
    {
      name: 'uf_nascimento',
      required: false,
      options: ['Informação da uf de nascimento do aluno.'],
    },
    {
      name: 'uf',
      required: false,
      options: ['Informação da uf do aluno.'],
    },
    {
      name: 'posicao',
      required: true,
      options: ['Informação da posição do aluno.'],
    },
  ];

  return <ColumnRules rules={rules} />;
};

export default ImportDadosPessoaisColumnRules;
