import React from 'react';
import { toast } from 'react-toastify';
import { logOut } from '../../lib/auth';

class FinancersBase extends React.Component {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
  }

  handleError(error) {
    const { history } = this.props;
    const { status } = error.response;

    switch (status) {
      case 401:
      	toast.error('Você não tem permissão para entrar nesta página');
      	logOut()
        history.push('/');
        break;
      case 403:
        toast.error('Sua sessão expirou, faça o login novamente para continuar');
        logOut()
        history.push('/');
        break;
      default:
        toast.error('Ocorreu um erro inesperado, tente novamente mais tarde');
    }
  }
}

export default FinancersBase;
