import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import { API_URL } from '../../lib/const';
import { Notification } from '.';
import { Icon } from '../elements';
import logo from '../../assets/img/logo.svg';
import ComponentWithToken from './CompontentWithToken';

class StudentHeader extends ComponentWithToken {
  state = {
    notifications: [],
    student: undefined,
    loading: true,
    showNotification: false,
  }

  componentDidMount() {
    axios.get(
      `${API_URL}/onboarding/enrollments/${this.token}`,
    ).then((response) => {
      this.setState({ student: response.data, loading: false });
    });
    this.fetchNotification();
  }

  fetchNotification() {
    const baseUrl = `${API_URL}/core/notifications`;

    axios.get(baseUrl + (this.token ? `/${this.token}` : ''))
      .then((response) => {
        this.setState({
          notifications: response.data,
        });
      });
  }

  closeNotification() {
    this.setState({
      showNotification: false,
    });
  }

  render() {
    const {
      student,
      loading,
      showNotification,
      notifications,
    } = this.state;

    return (
      <div>
        <header className="navigation">
          <div className="navigation-adjustment">
            <img src={logo} className="navigation-logo" alt="notification" />
            <div className="navigation-title-system">
              <span>BEM-VINDO A SUA <strong>{student && student.kind === 'enrollment_renew' ? 'RENOVAÇÃO DE MATRÍCULA' : 'MATRÍCULA' }</strong></span>
            </div>
            <div className="navigation-info-user">
              <span className="name">{get(student, 'name')}</span>
              <span className="cpf"><span className="destaque">Curso:</span> {get(student, 'onboarding.course.name')}</span>
              <span className="cpf">{!loading && `${get(student, 'onboarding.field_step')}º`} Ano/Período de Promoção</span>
            </div>
            <div className="d-flex">
              <div className="navigation-notification mr-2">
                <Link className="btn-none navigation-notification-button d-flex justify-content-center align-items-center" to={`/faq/${this.token}`}>
                  <div className="navigation-notification-style">
                    <i className="navigation-notification-icon"><FontAwesomeIcon icon="question" className="white" /></i>
                  </div>
                </Link>
              </div>
              <div className="navigation-notification">
                {notifications.length > 0 && (<span className="notification-notify" />)}
                <button type="button" className="btn-none navigation-notification-button" onClick={() => this.setState({ showNotification: !showNotification })}>
                  <div className="navigation-notification-style">
                    <i className="navigation-notification-icon"><Icon name="bell" white /></i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="navigation-space" />
        <Notification token={this.token} showNotification={showNotification} notifications={notifications} onClickTimes={() => this.closeNotification()} />
      </div>
    );
  }
}

export default withRouter(StudentHeader);
