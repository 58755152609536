import React from 'react';
import { FieldSet } from '../elements';

const HealthContents = ({
  title,
  onClick,
  children,
  buttonLabel
}) => (
  <>
    <FieldSet title={title} />
    <button
      className="btn btn-primary w-25 button-responsive"
      type="button"
      onClick={onClick}
    >
      {buttonLabel}
    </button>
    {children}
  </>
);

export default HealthContents;
