import React from 'react';
import PropTypes from 'prop-types';

class Spinner extends React.Component {
  render() {
    const { children, active } = this.props;

    return (
      <div className="spinner-base">
        {
          active && <div className="spinner spin" />
        }
        <div className={active ? 'blur' : ''}>
          {children}
        </div>
      </div>
    );
  }
}

Spinner.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
};

Spinner.defaultProps = {
  active: false,
  children: null,
};

export default Spinner;
