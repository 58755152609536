import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FieldSet, KeyValue } from '../../elements';

const HandicapSummary = ({ handicap, disabilities, special_needs }) => (
  <>
    <FieldSet title="Outras Informações" />
    <div className="enrollment-flex">
      <KeyValue
        col="12"
        title={get(handicap, 'name')}
        label="Possui alguma Deficiência, Transtorno Global do Desenvolvimento, ou Habilidades/Superdotação?"
      />
    </div>
    {disabilities.length > 0 && (
      <>
        <FieldSet title="Deficiência, Transtorno Global do Desenvolvimento, ou Habilidades/Superdotação" />
        <div className="enrollment-flex">
          {disabilities.map(d => (
            <span className="col-md-4">{d.name}</span>
          ))}
        </div>
      </>
    )}
    {special_needs.length > 0 && (
      <>
        <FieldSet title="Necessidades Especiais" />
        <div className="enrollment-flex">
          {special_needs.map(s => (
            <span className="col-md-4">{s.name}</span>
          ))}
        </div>
      </>
    )}
  </>
);

HandicapSummary.propTypes = {
  handicap: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    check_disabilities: PropTypes.bool,
  }),
  disabilities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  special_needs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
};

HandicapSummary.defaultProps = {
  handicap: {},
  disabilities: [],
  special_needs: [],
};

export default HandicapSummary;
