import { Form } from 'informed';
import { get } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { GUARANTEE_TYPE, PAYMENT_METHOD } from '../../lib/config';
import EditingButtons from '../components/common/EditingButtons';
import ComponentWithToken from '../components/CompontentWithToken';
import FinanceOfficerForm from '../components/FinanceOfficerForm';
import FinancePlansForm from '../components/FinancePlansForm';
import GuaranteeTypeForm from '../components/GuaranteeTypeForm';
import GuarantorForm from '../components/GuarantorForm';
import LetterGuaranteeForm from '../components/LetterGuaranteeForm';
import StepControllerButtons from '../components/StepControllerButtons';
import { Spinner } from '../elements';
import { fetchFinanceData, fetchFinancePlans, fetchPaymentPlans, validateFinanceData } from '../services/finance_data_service';
import { fetchDocumentTypes, fetchGuaranteeTypes, fetchMaritalPropertySystems, fetchMaritalStatuses, fetchNationalities, fetchPaymentMethods } from '../services/options_service';
import Html from '../v2/common/components/Html';
import { MessageContext } from '../v2/common/states/MessageState';
import PaymentMethodForm from '../v2/financers/PaymentMethodForm';

class FinanceDataForm extends ComponentWithToken {
  previousErrors = {};


  getChangePlanMessage = () => (
    <>
      <p>O plano de pagamento no vencimento escolhido anteriormente não está mais disponível. Gentileza escolher novamente o plano desejado com nova data de vencimento.</p>
      <p>Caso você tenha realizado o pagamento, gentileza encaminhar comprovante de pagamento para o e-mail financeiro@feluma.org.br.</p>
    </>
  )

  state = {
    loading: true,
    loadingPayments: true,
    form: {},
    financeData: {},
    financePlans: [],
    documentTypes: [],
    paymentPlans: [],
    maritalStatuses: [],
    paymentMethods: [],
    maritalPropertySystems: [],
    guaranteeTypes: [],
    nationalities: [],
    financeOfficer: {},
    errors: {},
    changePlanRequested: false,
    showFinanceOfficer: false,
  };

  componentDidMount() {
    Promise.all(
      [
        this.fetchOptions(),
        this.fetchFinanceData(),
      ],
    ).then(() => this.setState({ loading: false }));
  }

  fetchFinanceData = () => fetchFinanceData(this.token).then((response) => {
    this.formApi.setValues(response);
    this.setState({
      changePlanRequested: response.change_plan_requested,
      showFinanceOfficer: response.show_finance_officer,
      financeOfficer: response.finance_officer,
    });
  }).catch(() => this.setState({ loading: false }));

  fetchOptions = () => {
    const options = [
      {
        field: 'financePlans',
        fetchFunction: fetchFinancePlans,
      },
      {
        field: 'paymentPlans',
        fetchFunction: fetchPaymentPlans,
      },
      {
        field: 'guaranteeTypes',
        fetchFunction: fetchGuaranteeTypes,
      },
      {
        field: 'paymentMethods',
        fetchFunction: fetchPaymentMethods,
      },
      {
        field: 'documentTypes',
        fetchFunction: fetchDocumentTypes,
      },
      {
        field: 'maritalPropertySystems',
        fetchFunction: fetchMaritalPropertySystems,
      },
      {
        field: 'maritalStatuses',
        fetchFunction: fetchMaritalStatuses,
      },
      {
        field: 'nationalities',
        fetchFunction: fetchNationalities,
      },
    ];
    this.setState({ loadingOptions: true });
    return Promise.all([
      options.map(option => option.fetchFunction(this.token).then(response => this.setState({
        [option.field]: response,
      }))),
    ]).then(() => this.setState({ loadingOptions: false }));
  };


  validateFinanceData = () => {
    this.setState({ loading: true });
    const { onClick } = this.props;
    let { values } = this.formApi.getState();

    const { financePlans, changePlanRequested, showFinanceOfficer } = this.state;
    const shouldSendFinanceOfficer = showFinanceOfficer;

    if (!shouldSendFinanceOfficer) {
      values.finance_officer = undefined;
    }

    const financePlan = financePlans.find(f => f.id === values.plan);
    if (financePlan) {
      values = {
        ...values,
        guarantors: (get(values, 'guarantors') || []).filter((_, i) => i < financePlan.guarantors),
      };
    }

    validateFinanceData(this.token, values)
      .then(() => {
        this.setState({ loading: false });
        if (changePlanRequested) {
          window.location.reload();
        } else {
          onClick();
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        const status = get(error, 'response.status');
        if (status === 400) {
          const errors = get(error, 'response.data');
          this.setState({ errors });
          Object.keys(this.previousErrors).forEach((fieldName) => {
            if (this.formApi.fieldExists(fieldName)) {
              this.formApi.setError(fieldName, null);
            }
          });
          this.previousErrors = { ...errors };

          const { guarantors } = errors;

          Object.entries(errors).forEach((e) => {
            if (this.formApi.fieldExists(e[0])) {
              this.formApi.setError(e[0], e[1]);
            }
          });

          if (Array.isArray(guarantors)) {
            guarantors.forEach((gErrors, i) => {
              Object.entries(gErrors).forEach((e) => {
                const fieldName = `guarantors[${i}].${e[0]}`;
                if (this.formApi.fieldExists(fieldName)) {
                  this.formApi.setError(fieldName, e[1]);
                }
              });
            });
          }
        }
      });
  }

  shouldShowGuarantee = () => {
    const { financePlans } = this.state;
    const plan = this.formApi ? this.formApi.getValue('plan') : undefined;
    const selectedPlan = financePlans.find(financePlan => financePlan.id === plan);
    return selectedPlan ? +selectedPlan.guarantors > 0 : false;
  }

  shouldShowPaymentMethod = () => {
    const { financePlans } = this.state;
    const plan = this.formApi ? this.formApi.getValue('plan') : undefined;
    const selectedPlan = financePlans.find(financePlan => financePlan.id === plan);
    return selectedPlan ? selectedPlan.can_choose_payment_methods : false;
  }

  onChangeFinanceOfficer = (field, value) => {
    this.setState(prevState => ({
      ...prevState,
      financeOfficer: {
        ...prevState.financeOfficer,
        [field]: value,
      },
    }));
  }

  render() {
    const {
      loading,
      loadingOptions,
      financePlans,
      paymentPlans,
      guaranteeTypes,
      nationalities,
      maritalStatuses,
      maritalPropertySystems,
      documentTypes,
      changePlanRequested,
      showFinanceOfficer,
      financeOfficer,
      paymentMethods,
    } = this.state;

    const { backClick, onEdit, isEditing } = this.props;

    return (
      <>
        <Spinner active={loading || loadingOptions}>
          <Form
            onValueChange={form => this.setState({ form })}
            getApi={(api) => { this.formApi = api; }}
            initialValues={{ guarantors: [] }}
          >
            {({ formApi }) => (
              <>
                {changePlanRequested && this.getChangePlanMessage()}
                <FinancePlansForm financePlans={financePlans} paymentPlans={paymentPlans} loadingOptions={loadingOptions} />
                {this.shouldShowPaymentMethod() && (
                  <MessageContext.Consumer>
                    {({ getMessage }) => (
                      <>
                        <PaymentMethodForm paymentMethods={paymentMethods} />
                        { formApi.getValue('payment_method') === PAYMENT_METHOD.SICOOB_LOAN && <div className="col-md-12 mt-3"><Html>{getMessage('SICOOB_LOAN_MESSAGE')}</Html></div>}
                      </>
                    )}
                  </MessageContext.Consumer>
                )}
                {this.shouldShowGuarantee() && (
                  <>
                    <GuaranteeTypeForm guaranteeTypes={guaranteeTypes} />
                    { formApi.getValue('guarantee_type') === GUARANTEE_TYPE.LETTER_GUARANTEE && <LetterGuaranteeForm />}
                    { formApi.getValue('guarantee_type') === GUARANTEE_TYPE.GUARANTOR && (
                      <GuarantorForm
                        financePlans={financePlans}
                        nationalities={nationalities}
                        maritalStatuses={maritalStatuses}
                        maritalPropertySystems={maritalPropertySystems}
                        documentTypes={documentTypes}
                      />
                    )}
                  </>
                )}
                {showFinanceOfficer && (
                  <FinanceOfficerForm
                    onChange={this.onChangeFinanceOfficer}
                    financeOfficer={financeOfficer}
                  />
                )}
                {
                  !isEditing && (
                    <>
                      <hr />
                      <StepControllerButtons onBackClick={backClick} onFowardClick={this.validateFinanceData} />
                    </>
                  )
                }
              </>
            )}
          </Form>
        </Spinner>
        {
          isEditing && <EditingButtons onEdit={onEdit} loading={loading} onSubmit={this.validateFinanceData} />
        }
      </>
    );
  }
}

export default withRouter(FinanceDataForm);
