import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Scope, withFormApi } from 'informed';
import { FieldSet } from '../elements';
import GuarantorsGeneralDataForm from './GuarantorsGeneralDataForm';
import { Contato, Endereco } from '../forms';
import GuarantorDocumentsForm from './GuarantorDocumentsForm';

class GuarantorForm extends React.Component {
  get guarantorsLength() {
    const { financePlans, formApi } = this.props;
    const plan = formApi.getValue('plan');
    const financePlan = financePlans.find(f => f.id === +plan);
    return financePlan ? +financePlan.guarantors : 0;
  }

  render() {
    const {
      formApi,
      nationalities,
      maritalStatuses,
      maritalPropertySystems,
      documentTypes,
    } = this.props;

    return (
      <>
        {
          [...Array(this.guarantorsLength)].map((_, i) => (
            <Scope scope={`guarantors[${i}]`}>
              <>
                <FieldSet title={`Fiador ${i + 1}`} />
                <GuarantorsGeneralDataForm
                  nationalities={nationalities}
                  maritalStatuses={maritalStatuses}
                  maritalPropertySystems={maritalPropertySystems}
                  guarantorIndex={i}
                />
                <Contato fiador={i + 1} />
                <Endereco formApi={formApi} scope={`guarantors[${i}]`} dbCep={get(formApi.getState(), `values.guarantors[${i}].zip_code`)} fiador={i + 1} />
                <GuarantorDocumentsForm guarantorIndex={i} documentTypes={documentTypes} />
              </>
            </Scope>
          ))
        }
      </>
    );
  }
}


GuarantorForm.propTypes = {
  financePlans: PropTypes.arrayOf(PropTypes.object),
  nationalities: PropTypes.arrayOf(PropTypes.object),
  maritalStatuses: PropTypes.arrayOf(PropTypes.object),
  maritalPropertySystems: PropTypes.arrayOf(PropTypes.object),
  documentTypes: PropTypes.arrayOf(PropTypes.object),
  formApi: PropTypes.shape({
    getValue: PropTypes.func,
    getState: PropTypes.func,
  }).isRequired,
};

GuarantorForm.defaultProps = {
  financePlans: [],
  nationalities: [],
  maritalStatuses: [],
  maritalPropertySystems: [],
  documentTypes: [],
};

export default withFormApi(GuarantorForm);
