import axios from "axios";
import { API_URL } from "../../lib/const";

const mapData = (d) => ({ value: d.id, label: d.name, ...d });
const mapState = (s) => ({ label: s.uf, value: +s.id, key: +s.id });
const mapCity = (c) => ({ label: c.name, value: +c.id, key: +c.id });
const mapRace = (r) => ({ label: r.name, value: r.id });
const mapInstitution = (i) => ({label: i.name, value: i.id});

export const fetchNationalities = () =>
  axios
    .get(`${API_URL}/core/nationalities`)
    .then((response) => response.data.map(mapData));

export const fetchGenders = () =>
  axios
    .get(`${API_URL}/core/genders`)
    .then((response) => response.data.map(mapData));

export const fetchMaritalStatuses = () =>
  axios
    .get(`${API_URL}/core/marital-statuses`)
    .then((response) => response.data.map(mapData));

export const fetchHandicapOptions = () =>
  axios
    .get(`${API_URL}/core/handicap-options`)
    .then((response) => response.data.map(mapData));

export const fetchDisabilities = () =>
  axios
    .get(`${API_URL}/core/disabilities`)
    .then((response) => response.data.map(mapData));

export const fetchSpecialNeeds = () =>
  axios
    .get(`${API_URL}/core/special-needs`)
    .then((response) => response.data.map(mapData));

export const fetchDocumentTypes = () =>
  axios
    .get(`${API_URL}/core/document-types`)
    .then((response) => response.data.map(mapData));

export const fetchMaritalPropertySystems = () =>
  axios
    .get(`${API_URL}/core/marital-property-systems`)
    .then((response) => response.data.map(mapData));

export const fetchCountries = async () => {
  const response = await axios.get(`${API_URL}/core/countries`);
  let countries = response.data.map(mapData);
  const brazil = countries.find((c) => c.label === "Brasil");
  countries = [brazil, ...countries.filter((c) => c.label !== "Brasil")];
  return countries;
};

export const fetchInstitutions = (params) => axios
  .get(`${API_URL}/core/institutions`, {params})
  .then(response => response.data.map(mapInstitution));

export const fetchStates = () =>
  axios
    .get(`${API_URL}/core/states`)
    .then((response) => response.data.map(mapState));

export const fetchStatus = () =>
  axios
    .get(`${API_URL}/onboarding/enrollment-status`, {
      params: { ordering: "name" },
    })
    .then((response) => response.data.map(mapData));

export const fetchContractStatus = () =>
  axios
    .get(`${API_URL}/onboarding/contract-status`, {
      params: { ordering: "name" },
    })
    .then((response) => response.data.map(mapData));

export const fetchPaymentStatus = () =>
  axios
    .get(`${API_URL}/onboarding/payment-status`, {
      params: { ordering: "name" },
    })
    .then((response) => response.data.map(mapData));

export const fetchCourse = () =>
  axios
    .get(`${API_URL}/onboarding/courses`)
    .then((response) => response.data.map(mapData));

export const fetchCities = (state) =>
  axios
    .get(`${API_URL}/core/cities?state=${state}`)
    .then((response) => response.data.map(mapCity));

export const fetchGuaranteeTypes = () =>
  axios
    .get(`${API_URL}/finance/guarantee-types`)
    .then((response) => response.data.map(mapData));

export const fetchPaymentMethods = () =>
  axios
    .get(`${API_URL}/finance/payment-methods`)
    .then((response) => response.data.map(mapData));

export const fetchRaceTypes = () =>
  axios
    .get(`${API_URL}/core/race`)
    .then((response) => response.data.map(mapRace));
