import React, { Fragment } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

class Stepper extends React.Component {
  render() {
    const { active, status, steppers } = this.props;
    if (steppers.length === 0) {
      return null;
    }

    return (
      <>
        <div className="stepper-header">
          {
          steppers.map(step => (
            <Fragment key={get(step, 'position')}>
              <div className={`stepper-item ${active === get(step, 'id') ? 'active' : ''}`}>
                <div className={`stepper-item-circle bg-${get(status, `${[get(step, 'step')]}.color`, 'gray')}`} />
                <div className="stepper-item-text">{ get(step, 'label') }</div>
              </div>
              {
                step.position < steppers.length
                && <hr className="stepper-item-line" />
              }
            </Fragment>
          ))
        }
        </div>
      </>
    );
  }
}

Stepper.propTypes = {
  active: PropTypes.number.isRequired,
  status: PropTypes.shape({
    personal_data: PropTypes.string,
    finance_data: PropTypes.string,
    summary: PropTypes.string,
    payment: PropTypes.string,
  }).isRequired,
  steppers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    position: PropTypes.number,
    step: PropTypes.string,
    label: PropTypes.string,
  })),
};

Stepper.defaultProps = {
  steppers: [],
};

export default Stepper;
