import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import axios from 'axios';
import { Ball, Icon } from '../../elements';
import { Modal } from '../../components';

const DocumentsView = ({ personalDocuments, personalDocumentsTypes, onApproveDocument, onReproveDocument, audittableDocuments, auditPermission }) => {
  const [showModal, setModal] = useState(false);
  const [fileSrc, setFileSrc] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState({});

  const handleModal = async (document) => {
    try {
      const res = await axios.get(document.url);
      setFileSrc(get(res, 'data.url'));
      setSelectedDocument(document);
      setModal(true);
    } catch (err) {
      setModal(false);
    }
  };

  const getDocumentVerboseType = (documentTypeId) => {
    return get(
      personalDocumentsTypes.filter(type => type.id === documentTypeId), '[0].name', 'Documento'
    )
  }

  const iconWithButtonsToApprove = (personalDocument) => {
    return (
      <>
      {personalDocument.approved === null || personalDocument.approved === true ?  
        <button
          onClick={() => onApproveDocument(personalDocument)}
          className="btn-none">
          <Icon
            name="check"
          />
        </button> : null}
        {personalDocument.approved === null || personalDocument.approved === false ?  <button
          onClick={() => {
            onReproveDocument({...personalDocument, document_type:getDocumentVerboseType(personalDocument.document_type)})
          }}
          className="btn-none"
          disabled={personalDocument.approved  !== null }
        >
          <Icon
            name="error"
            width="20px"
            fill="#fff"
            style={{ cursor: "none" }}
          />
        </button> : null}
      </>
    )
  }

  const approvedOrReprovedIcons = (personalDocument) => {
    return (
      <>
      {personalDocument.approved === true &&
          <Icon
            name="check"
          />
          }

        {personalDocument.approved === false &&  
          <Icon
            name="error"
            width="20px"
            fill="#fff"
            style={{ cursor: "none" }}
          />
        }
        {personalDocument.approved === null &&  
          <Icon
            name="clock"
          />
        }
      </>
    )
  }

  const auditDocumentsActions = (personalDocument) => {
    return (
      <>
        <div
          style={{
            flexDirection: 'column',
            pointerEvents: personalDocument.approved === null ?   "auto" : "none",
            opacity: personalDocument.approved === null ?  "1" : "0.5"
          }}
          className="d-flex align-items-center justify-content-start"
        >
        {auditPermission && iconWithButtonsToApprove(personalDocument)}
        {!auditPermission && approvedOrReprovedIcons(personalDocument)}
        </div>
      </>
    )
  }

  if (personalDocuments.length === 0) {
    return <p>Não existem documentos.</p>;
  }
  return (
    <>
      {
        personalDocuments && (
          personalDocuments.map(personalDocument => (
            <div className="col-sm-12 col-md-6 mt-5">
              <div className="documents-uploaded" style={{ backgroundColor: 'rgb(3, 159, 151)', color: 'white' }}>
                <div className="d-flex align-items-center">
                  <div className="documents-length-0">
                    {get(personalDocumentsTypes.filter(type => type.id === personalDocument.document_type), '[0].name', 'Documento')}
                    <span className="ml-bg-1 mr-1">-</span>
                    {personalDocument.name}
                  </div>
                  <button className="btn-none" type="button" onClick={() => handleModal(personalDocument)}>
                    <Ball
                      icon="view"
                      extraSmall
                      bgColor="white"
                      iconWidth="16"
                    />
                  </button>
                  {audittableDocuments && auditDocumentsActions(personalDocument)}
                </div>
              </div>
            </div>
          ))
        )
      }
      <Modal visible={showModal} onClose={() => setModal(false)} title={get(selectedDocument, 'name')} src={fileSrc} />
    </>
  );
};

DocumentsView.propTypes = {
  personalDocuments: PropTypes.arrayOf(PropTypes.shape({
    document_type: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
  })),
  personalDocumentsTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  auditPermission: PropTypes.bool,
};

DocumentsView.defaultProps = {
  personalDocuments: [],
  personalDocumentsTypes: [],
  onApproveDocument: () => undefined,
  onReproveDocument: () => undefined,
  auditPermission: false,
};

export default DocumentsView;
