import React from 'react';
import { fetchRaceTypes } from '../services/options_service';
import { InputTypeDropdown } from '.';

class InputDropdownRace extends React.Component {
    state = {
      options: [],
    };

    componentDidMount() {
      this.fetchRaceTypes();
    }

    fetchRaceTypes = () => {
      fetchRaceTypes().then((data) => {
        this.setState({
          options: data,
        });
      });
    };

    render() {
      const { ...rest } = this.props;
      const { options } = this.state;

      return (
        <InputTypeDropdown
          field="race"
          label="Raça ou Cor"
          options={options}
          {...rest}
        />
      );
    }
}

export default InputDropdownRace;
