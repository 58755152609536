import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import Label from './Label';

const Card = ({
  title,
  children,
  loading,
  statusColor,
  statusName,
}) => (
  <div className="mt-3">
    <div className="content-right">
      <div className="page-title mb-2 d-flex align-items-center justify-center">
        <h3 className="font-weight-600">{title}</h3>
        {statusColor && (
          <div className="ml-2">
            <Label color={statusColor}>{statusName}</Label>
          </div>
        )}
        <hr className="page-title-line" />
      </div>
      <div>
        {
          loading
            ? (
              <div className="d-flex align-items-center justify-center">
                <Skeleton width="100%" height={400} />
              </div>
            )
            : children
        }
      </div>
    </div>
  </div>
);

Card.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  loading: PropTypes.bool,
  statusColor: PropTypes.string,
  statusName: PropTypes.string,
};

Card.defaultProps = {
  title: '-',
  children: null,
  loading: false,
  statusColor: '',
  statusName: '',
};

export default Card;
