import React from 'react';
import { fetchPaymentStatus } from '../services/options_service';
import { InputTypeDropdown } from '.';

class InputDropdownPaymentStatus extends React.Component {
  state = {
    options: [],
  }

  componentDidMount() {
    this.fetchPaymentStatus();
  }

  fetchPaymentStatus = () => {
    fetchPaymentStatus()
      .then((data) => {
        this.setState({
          options: data,
        });
      });
  }

  render() {
    const { ...rest } = this.props;
    const { options } = this.state;

    return (
      <InputTypeDropdown
        field="payment_status"
        label="Status do Pagamento"
        options={options}
        {...rest}
      />
    );
  }
}

export default InputDropdownPaymentStatus;
