import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Ball, FieldSet, KeyValue } from '../../elements';


const LetterGuaranteeDetail = ({ financeData, showModal }) => (
  <>
    <FieldSet title="Carta Fiança" />
    <KeyValue title={get(financeData, 'bank_name')} label="Nome do Banco" />
    <KeyValue title={get(financeData, 'letter_guarantee.name')} label="Documento da Carta Fiança" />
    <div className="documents-base row mb-5" style={{ justifyContent: 'start' }}>
      <div className="col-sm-12 col-md-6 mt-3">
        <div className="documents-uploaded" style={{ backgroundColor: 'rgb(3, 159, 151)', color: 'white' }}>
          <div className="d-flex align-items-center">
            <div className="documents-length-0"> {financeData.letter_guarantee.name} </div>
            <button className="btn-none" type="button" onClick={() => showModal(financeData.letter_guarantee)}>
              <Ball
                icon="view"
                extraSmall
                bgColor="white"
                iconWidth="16"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
);

LetterGuaranteeDetail.propTypes = {
  financeData: PropTypes.shape({
    letter_guarantee: PropTypes.shape({
      name: PropTypes.string,
    }),
    bank_name: PropTypes.string,
  }).isRequired,
  showModal: PropTypes.func.isRequired,
};

export default LetterGuaranteeDetail;
