import axios from 'axios';
import { API_URL } from '../../lib/const';

export const fetchDocumentTypes = (token, params) => (
  axios.get(`${API_URL}/documents/documents-data/${token}/document_types`, params)
    .then(response => response.data)
);

export const fetchDocumentTypesWithEquivalents = (token, params) => (
  axios.get(`${API_URL}/documents/documents-data/${token}/document_types_with_equivalents`, params)
    .then(response => response.data)
);

export const fetchDocuments = token => (
  axios.get(`${API_URL}/documents/documents-data/${token}`)
    .then(response => response.data)
);

export const sendDocuments = (token, params) => (
  axios.patch(`${API_URL}/documents/documents-data/${token}`, params)
    .then(response => response.data)
);
