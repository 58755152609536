import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Label = ({
  label,
  text,
  tooltipMessage,
  hasTooltip,
}) => (
  <div className="mb-3 mr-5">
    { hasTooltip && <ReactTooltip /> }
    <div className="label--title">
      {label}
      { hasTooltip && <FontAwesomeIcon className="ml-1" data-tip={tooltipMessage} icon="question-circle" />}
    </div>
    <div className="label--text">{text}</div>
  </div>
);

Label.propTypes = {
  label: PropTypes.string,
  text: PropTypes.node,
  hasTooltip: PropTypes.bool,
  tooltipMessage: PropTypes.string,
};

Label.defaultProps = {
  label: '-',
  text: '-',
  hasTooltip: false,
  tooltipMessage: '',
};

export default Label;
