import React from 'react';
import { Route } from 'react-router-dom';
import { StudentHeader } from '../components';

const StudentRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <>
        <StudentHeader />
        <div className="content-top">
          <Component {...props} />
        </div>
      </>
    )}
  />
);

export default StudentRoute;
