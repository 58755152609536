import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class Icon extends React.Component {

  render() {
    const { name, white, width, className, fill } = this.props;

    return(
      <Fragment>
        <span className={`icon-svg ${white ? 'fill-white' : 'fill-black'} ${className}`}>
          {
            name === "warning" &&
            <svg
              viewBox="0 0 1792 1550"
              width={width}
            >
              <path d="M1088 1248v224q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-224q0-26
                9-45t45-19h256q26 0 45 19t19 45zm30-1056l-28 768q-1 26-20.5 45t-45.5
                19h-256q-26 0-45.5-19t-20.5-45l-28-768q-1-26 17.5-45t44.5-19h320q26 0
                44.5 19t17.5 45z" />
            </svg>
          }
          {
            name === "trash" &&
            <svg
              viewBox="0 960 100 90"
              width={width}
            >
              <path
                d="m 41.66667,962.7787 c -1.15417,0 -2.08334,0.9292 -2.08334,2.0833 l 0,6.25
                -16.66666,0 c -2.30834,0 -4.16667,1.8583 -4.16667,4.1667 0,2.3083 1.85833,4.1666
                4.16667,4.1666 l 54.16666,0 c 2.30834,0 4.16667,-1.8583 4.16667,-4.1666 0,-2.3084
                -1.85833,-4.1667 -4.16667,-4.1667 l -16.66666,0 0,-6.25 c 0,-1.1541 -0.92913,
                -2.0833 -2.08334,-2.0833 z m 2.08333,4.1666 12.5,0 0,4.1667 -12.5,0 z M 22.91667,
                983.612 c -2.30834,0 -4.48113,1.89 -4.10155,4.1667 l 8.26821,49.9999 c 0.37667,
                2.2771 1.85834,4.1667 4.16667,4.1667 l 37.5,0 c 2.30833,0 3.78708,-1.89 4.16667,
                -4.1667 l 8.26821,-49.9999 c 0.37666,-2.2771 -1.79321,-4.1667 -4.10155,-4.1667 z"
              />
            </svg>
          }
          {
            name === "view" &&
            <svg
              viewBox="0 0 140 80"
              width={width}
            >
              <path
                d="M89.668,38.786c0-10.773-8.731-19.512-19.51-19.512S50.646,28.01,50.646,38.786c0,
                  10.774,8.732,19.511,19.512,19.511 C80.934,58.297,89.668,49.561,89.668,38.786
                  M128.352,38.727c-13.315,17.599-34.426,28.972-58.193,28.972 c-23.77,
                  0-44.879-11.373-58.194-28.972C25.279,21.129,46.389,9.756,70.158,9.756C93.927,
                  9.756,115.036,21.129,128.352,38.727 M140.314,38.76C125.666,15.478,99.725,0,
                  70.158,0S14.648,15.478,0,38.76c14.648,23.312,40.591,38.81,70.158,38.81 S125.666,
                  62.072,140.314,38.76" />
            </svg>
          }
          {
            name === "upload" &&
            <svg
              viewBox="0 0 470 470"
              width={width}
            >
              <path
                d="M457.7,230.15c-7.5,0-13.5,6-13.5,13.5v122.8c0,33.4-27.2,60.5-60.5,
                60.5H87.5c-33.4,0-60.5-27.2-60.5-60.5v-124.8
                c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v124.8c0,48.3,39.3,87.5,87.5,
                87.5h296.2c48.3,0,87.5-39.3,87.5-87.5v-122.8 C471.2,236.25,465.2,230.15,
                457.7,230.15z" />
              <path
                d="M159.3,126.15l62.8-62.8v273.9c0,7.5,6,13.5,13.5,13.5s13.5-6,
                13.5-13.5V63.35l62.8,62.8c2.6,2.6,6.1,4,9.5,4 c3.5,0,6.9-1.3,
                9.5-4c5.3-5.3,5.3-13.8,0-19.1l-85.8-85.8c-2.5-2.5-6-4-9.5-4c-3.6,0-7,
                1.4-9.5,4l-85.8,85.8 c-5.3,5.3-5.3,13.8,0,19.1C145.5,131.35,154.1,
                131.35,159.3,126.15z" />
            </svg>
          }
          {
            name === "download" &&
            <svg
              viewBox="0 0 470 470"
              width={width}
            >
              <path
                d="M457.7,230.15c-7.5,0-13.5,6-13.5,13.5v122.8c0,33.4-27.2,60.5-60.5,
                60.5H87.5c-33.4,0-60.5-27.2-60.5-60.5v-124.8
                c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v124.8c0,48.3,39.3,87.5,87.5,
                87.5h296.2c48.3,0,87.5-39.3,87.5-87.5v-122.8 C471.2,236.25,465.2,230.15,
                457.7,230.15z" />
              <path
                d="M226.1,346.75c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.8-85.8c5.3-5.3,
                5.3-13.8,0-19.1c-5.3-5.3-13.8-5.3-19.1,0l-62.7,62.8
                V30.75c0-7.5-6-13.5-13.5-13.5s-13.5,
                6-13.5,13.5v273.9l-62.8-62.8c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,
                0,19.1 L226.1,346.75z" />
            </svg>
          }
          {
            name === "menu" &&
            <svg
              viewBox="2 2 30 25"
              width={width}
            >
              <path
                d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,
                8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2 s0.896,2,2,2h24c1.104,0,
                2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,
                2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
            </svg>
          }
          {
            name === "error" &&
            <svg x="0px" y="0px"
                  viewBox="0 0 28 28"
                  width={width}

                  >

                <g>
                  <g id="x">
                    <g>
                      <polygon style={{fill: fill ? fill : '#030104', width:'20px'}} points="28,22.398 19.594,14 28,5.602 22.398,0 14,8.402 5.598,0 0,5.602 8.398,14 0,22.398
                        5.598,28 14,19.598 22.398,28      "/>
                    </g>
                  </g>
                </g>
</svg>
          }
          {
            name === "check" &&
            <svg
              viewBox="0 0 512 512"
              width={width}
            >
              <path
                d="m208 433l-164-164 77-78 87 85 196-197 79 78z"
                fill="#fff" />
            </svg>
          }
          {
            name === "clock" &&
            <svg
              viewBox="0 0 1000 1000"
              width={width}
            >
              <path
                d="M500,10C229.8,10,10,229.8,10,500c0,270.2,219.8,490,490,490c270.2,
                0,490-219.8,490-490C990,229.8,770.2,10,500,10L500,10z M500,906.3C276,
                906.3,93.7,724,93.7,500C93.7,276,276,93.7,500,93.7C724,93.7,906.3,276,
                906.3,500C906.3,724,724,906.3,500,906.3L500,906.3z M325.3,500c-19.4,
                0-35.1,15.6-35.1,35s15.7,35,35.1,35h210c19.4,0,35-15.6,
                35-35V255.1c0-19.4-15.6-35.1-35-35.1c-19.4,0-35,15.7-35,
                35.1V500L325.3,500L325.3,500z" />
            </svg>
          }
          {
            name === "logout" &&
            <svg
              viewBox="0 0 1000 1000"
              width={width}
            >
              <path
                d="M696.5,234.1V68.9c0-26.3-21.5-47.9-47.9-47.9H57.9C31.6,21,10,
                42.5,10,68.9V100v624.2v21.6c0,17.6,10.4,34.3,25.5,42.3l364,188.4c16,8,
                35.1-3.2,35.1-21.6V771.3h213.9c26.3,0,47.9-21.5,
                47.9-47.9V461.6h-95.8v190c0,13.6-10.4,23.9-23.9,
                23.9H435.4V429.7V274v-4.8c0-17.6-10.4-34.3-25.5-42.3L195.2,
                116.8h381.5c13.6,0,23.9,10.4,23.9,23.9v94.2h95.8V234.1z" />
              <path
                d="M819.4,155.9L975,311.5c20,20,20,49.5,0,69.4L819.4,536.6c-20,
                20-50.3,20.8-70.2,0.8c-19.2-19.2-16.8-51.1,
                1.6-70.2l70.2-69.4H548.8c-13.6,0-27.1-5.6-35.9-16c-22.3-23.9-16.8-66.3,
                12-82.2c7.2-4,16-6.4,23.9-6.4H821c0,
                0-69.4-69.4-70.2-69.4c-18.4-18.4-20.8-51.1-1.6-69.4C768.3,135.1,799.4,
                135.9,819.4,155.9z" />
            </svg>
          }
          {
            name === "bell" &&
            <svg
              viewBox="0 0 50 50"
              width={width}
            >
              <path
                d="M 25 6 C 23.355469 6 22 7.355469 22 9 L 22 10.363281 C 17.171875
                11.550781 14 15.613281 14 21.0625 L 14 28.863281 C 14 31.035156 12.71875
                33.496094 11.980469 34.726563 L 10.167969 37.445313 C 9.964844 37.75
                9.941406 38.148438 10.117188 38.472656 C 10.292969 38.796875 10.632813
                39 11 39 L 39 39 C 39.367188 39 39.707031 38.796875 39.882813 38.472656
                C 40.058594 38.148438 40.035156 37.753906 39.832031 37.445313 L
                38.046875 34.765625 C 36.667969 32.472656 36 30.585938 36 29 L 36
                21.199219 C 36 15.683594 32.828125 11.570313 28 10.371094 L 28 9 C 28
                7.355469 26.644531 6 25 6 Z M 25 8 C 25.554688 8 26 8.445313 26 9 L 26
                10.046875 C 25.671875 10.019531 25.339844 10 25 10 C 24.660156 10
                24.328125 10.019531 24 10.046875 L 24 9 C 24 8.445313 24.445313 8 25 8 Z
                M 3.480469 9.476563 C 1.25 13.101563 0 17.417969 0 22 C 0 26.582031 1.25
                30.898438 3.480469 34.523438 L 5.1875 33.476563 C 3.152344 30.167969 2
                26.21875 2 22 C 2 17.78125 3.152344 13.832031 5.1875 10.523438 Z M
                46.519531 9.476563 L 44.8125 10.523438 C 46.847656 13.832031 48 17.78125
                48 22 C 48 26.21875 46.847656 30.167969 44.8125 33.476563 L 46.519531
                34.523438 C 48.75 30.898438 50 26.582031 50 22 C 50 17.417969 48.75
                13.101563 46.519531 9.476563 Z M 7.816406 12.140625 C 5.996094 15.082031
                5 18.355469 5 22 C 5 25.671875 6.128906 29.046875 7.808594 31.847656 L
                9.523438 30.816406 C 8.007813 28.285156 7 25.261719 7 22 C 7 18.710938
                7.871094 15.851563 9.515625 13.191406 Z M 42.183594 12.140625 L
                40.484375 13.191406 C 42.128906 15.851563 43 18.710938 43 22 C 43
                25.261719 41.992188 28.285156 40.472656 30.816406 L 42.191406 31.847656
                C 43.871094 29.046875 45 25.671875 45 22 C 45 18.355469 44.003906
                15.082031 42.183594 12.140625 Z M 20.421875 41 C 21.199219 42.761719
                22.953125 44 25 44 C 27.046875 44 28.800781 42.761719 29.578125 41 Z" />
            </svg>
          }
          {
            name === "mail" &&
            <svg
              viewBox="0 0 320 251.6"
              width={width}
            >
              <path id="Path_168" className="st0" d="M291.5,251.6c6.9,0.2,13.6-2.4,18.7-7.1l-90.7-90.7c-2.2,1.8-4.4,3.1-6.2,4.4
                c-6.7,4.9-12.4,8.9-16.4,11.6c-5.3,3.3-11,6.2-16.9,8.4c-6.1,2.8-12.8,4.3-19.6,4.4H160c-6.8-0.1-13.4-1.6-19.6-4.4
                c-5.8-2.5-11.4-5.3-16.9-8.4c-4-2.7-9.8-6.7-16.4-11.6c-2-1.6-4.1-3.1-6.2-4.4L9.8,244.4c5.1,4.7,11.8,7.3,18.7,7.1H291.5z"/>
              <path id="Path_169" className="st0" d="M18.2,96.4C11.6,92,5.4,86.8,0,80.9v137.8l80-80C64,128,43.1,113.8,18.2,96.4z"/>
              <path id="Path_170" className="st0" d="M302.2,96.4c-24.4,16.4-44.9,30.7-62.2,42.7l80,80V81.3C314.6,86.9,308.6,92,302.2,96.4
                L302.2,96.4z"/>
              <path id="Path_171" className="st0" d="M291.5,0H28.4c-8.9,0-16,3.1-20.9,9.3S0,23.1,0,32.4C0,40,3.1,48,9.8,56.9s13.3,15.6,20.9,20.4
                c4,2.7,16.4,11.6,36.4,25.3c11.1,7.6,20.4,14.2,28.9,20c7.1,4.9,12.9,8.9,18.2,12.4c0.9,0.6,1.7,1.2,2.7,1.8
                c1.3,0.9,3.1,2.2,4.9,3.6c4,2.7,7.1,4.9,9.8,6.7s5.8,3.6,9.3,5.8c3.3,1.9,6.7,3.5,10.2,4.9c3.1,0.9,6.2,1.8,8.9,1.8l0,0
                c3.1,0,6.1-0.6,8.9-1.8c3.6-1.2,7-2.8,10.2-4.9c3.6-2.2,6.7-4,9.3-5.8s5.8-4,9.8-6.7c1.8-1.3,3.6-2.7,4.9-3.6
                c0.9-0.5,1.8-1.1,2.7-1.8c4-2.7,9.8-7.1,18.2-12.4c14.7-10.2,36.4-25.3,65.3-45.3c8.5-5.9,15.9-13.2,21.8-21.8
                c5.8-8.4,8.9-17.8,8.9-27.1c0-8-2.7-14.7-8.4-20C306.3,3,299.1-0.1,291.5,0z"/>
            </svg>
          }
        </span>
      </Fragment>
    );
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  white: PropTypes.bool,
  width: PropTypes.string,
};

Icon.defaultProps = {
  width: "24",
};

export default Icon;
