import React, { Component } from 'react';
import { fetchCourses, fetchInformation, fetchFilterData ,fetchEnrollmentsByStatus} from '../services/dashboard_services';
import Dashboard from '../components/Dashboard';

class DashboardPage extends Component {
  state = {
    generalInformation: {},
    courses: [],
    loading: true,
    filterData:{},
    enrollmentsByStatus:[]
  };

  componentDidMount() {
    Promise.all([
      this.fetchInformation(),
      this.fetchCourses(),
      this.fetchFilterData(),
      this.fetchEnrollmentsByStatus()
    ]).then(() => this.setState({ loading: false }));
  }

  updateInformation = () => {
    this.setState({ loading: true });
    Promise.all([
      this.fetchInformation(),
      this.fetchCourses(),
    ]).then(() => this.setState({ loading: false }));
  };

  fetchInformation = () => fetchInformation().then(response => this.setState({ generalInformation: response }));

  fetchCourses = () => fetchCourses().then(response => this.setState({ courses: response }));
  fetchCurriculum = () => fetchCourses().then(response => this.setState({ courses: response }));
  fetchFilterData = () => fetchFilterData().then(response => this.setState({ filterData: response }));
  fetchEnrollmentsByStatus = (params) => fetchEnrollmentsByStatus(params).then(response => this.setState({enrollmentsByStatus:response}))

  render() {
    const {
      generalInformation, loading, courses,filterData, enrollmentsByStatus
    } = this.state;

    return (
      <Dashboard
        generalInformation={generalInformation}
        loading={loading}
        courses={courses}
        filterData={filterData}
        fetchEnrollmentsByStatus={this.fetchEnrollmentsByStatus}
        enrollmentsByStatus={enrollmentsByStatus}


        onUpdate={this.updateInformation}
      />
    );
  }
}

export default DashboardPage;
