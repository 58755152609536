import React from 'react';
import PropTypes from 'prop-types';
import { FieldSet } from '../elements';

class SpecialNeedsForm extends React.Component {
  render() {
    const {
      error, options, selectedDisabilities, selectedSpecialNeeds, handleChange,
    } = this.props;
    return (
      <>
        <FieldSet title="Necessidades Especiais" />
        {error && <label className="red" htmlFor="special-needs-options">{error}</label>}
        {options.filter(s => selectedDisabilities.find(d => d === s.disability)).map(s => (
          <div className="col-md-4 col-sm-12">
            <label className="input-check-type" htmlFor={`special-need-${s.id}`}>{s.name}
              <input
                type="checkbox"
                className="checkbox"
                id={`special-need-${s.id}`}
                checked={selectedSpecialNeeds.find(x => x === s.id)}
                onChange={e => handleChange(e, s.id)}
              />
              <span className="checkmark" />
            </label>
          </div>
        ))}
      </>
    );
  }
}

SpecialNeedsForm.propTypes = {
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      key: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  selectedDisabilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  selectedSpecialNeeds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
};

SpecialNeedsForm.defaultProps = {
  error: undefined,
};

export default SpecialNeedsForm;
