import React from 'react';
import PropTypes from 'prop-types';


class ComponentWithToken extends React.Component {
  get token() {
    const { match: { params: { token } } } = this.props;
    return token;
  }
}

ComponentWithToken.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }),
  }),
};

ComponentWithToken.defaultProps = {
  match: {
    params: {
      token: '',
    },
  },
};

export default ComponentWithToken;
