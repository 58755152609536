import axios from 'axios';
import { API_URL } from '../../../../lib/const';


export const fetchInvites = (params = {}) => (
  axios.get(`${API_URL}/invites`, { params })
    .then(response => response.data)
);

export const fetchCandidates = (params = {}) => (
  axios.get(`${API_URL}/invites/candidates`, { params })
    .then(response => response.data)
);

export const fetchInviteProcess = (params = {}) => (
  axios.get(`${API_URL}/invite-processes`, { params })
    .then(response => response.data)
)
