import React from 'react';
import { get } from 'lodash';

export const tableTextFilterMethod = (filter, row) => {
  const { id, value } = filter;
  const text = row[id].toString().toUpperCase();
  const search = value.toUpperCase();
  return text.includes(search);
};

export const formatCPF = (d) => {
  const cpf = get(d, 'original.cpf', '');

  if (cpf) {
    const formated_cpf = cpf.replace(/(-)|(\.)/g, '');
    return (
      <em>
        {formated_cpf.substring(0, 3)}
        .
        {formated_cpf.substring(3, 6)}
        .
        {formated_cpf.substring(6, 9)}
        -
        {formated_cpf.substring(9, 11)}
      </em>
    );
  }
  return null;
};

export const formatCurrency = value => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
export const convertCurrencyValue = value => +value.replace('R$', '').replace('.', '').replace(',', '.');
export default tableTextFilterMethod;
