import React from 'react';
import { Icon } from '../elements';

class Footer extends React.Component {
  render() {
    return(
      <div className="footer-base">
        <p>
          Copyright © 2019 — Feluma.
        </p>
        <div className="footer-side">
          <Icon name="mail" width="15" white />
          <a href="mailto:Support@cienciasmedicas.com">support@cienciasmedicas.com</a>
          <img src={require('../../assets/img/educat-logo.svg')} className="footer-logo" alt="educat-logo" />
        </div>
      </div>
    );
  }
}

export default Footer;
