export const menu = {
  name: 'Menu',
  sections: [
    {
      id: 1,
      name: 'Financeiro',
      icon: 'dollar-sign',
      options: [
        {
          id: 1,
          url: '/dashboard',
          name: 'Dashboard',
          permission: '',
        },
        {
          id: 2,
          url: '/financers',
          name: 'Aprovação - Financeira',
          permission: 'onboarding.can_view_and_approve_financedata',
        },
        {
          id: 3,
          url: '/health',
          name: 'Aprovação - Ficha de Saúde',
          permission: 'health.can_view_and_approve_healthdata',
        },
        {
          id: 4,
          url: '/academic',
          name: 'Aprovação - Acadêmica',
          permission: 'documents.can_view_and_approve_documentdata',
        },
        {
          id: 5,
          url: '/naap',
          name: 'Aprovação - NAAP',
          permission: 'onboarding.can_view_and_approve_naap',
        },
        {
          id: 6,
          url: '/invite',
          name: 'Convocação',
          permission: 'invites.view_menu_convocacao',
        },
        {
          id: 7,
          url: '/support',
          name: 'Suporte',
          permission: 'core.view_support_chat',
        },
        {
          id: 8,
          url: '/importacao',
          name: 'Importação',
          permission: 'core.view_imporacao_menu',
        },
      ],
    },
  ],
};

export default menu;
