import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class InviteProcessCard extends React.Component {
  render() {
    const {
      inviteProcess,
      onRemoveUserOfProcess,
      onAddInvitesToProcess,
      onSendProcessInvites,
    } = this.props;

    return (
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <h3 className="m-1">
            {inviteProcess.name}
          </h3>
          <h3>
            {inviteProcess.free_vacancies} vagas
          </h3>
        </div>
        {
          inviteProcess.invites.length > 0 ? inviteProcess.invites.map(invite => (
            <div
              key={invite.id}
              onClick={() => onRemoveUserOfProcess(invite.id)}
              className="d-flex justify-content-between align-items-center candidate-card"
            >
              <FontAwesomeIcon
                className="red-dim"
                size="2x"
                icon="chevron-left"
              />
              <p className="m-0 text-center">{invite.enrollment} ({invite.position || '-'})</p>
              <p className="m-0">{get(invite, 'status.name')}</p>
            </div>
          )) : <p className="m-1 text-center"><i>Nenhum inscrito nessa convocação.</i></p>
        }
        <div className="d-flex h-100 justify-content-end align-items-end m-3">
          {
            inviteProcess.can_set_candidates && (
              <div className="mr-2">
                <button
                  className="btn btn-primary"
                  onClick={() => onAddInvitesToProcess(inviteProcess.id)}
                >
                  adicionar candidatos
                </button>
              </div>
            )
          }
          {
            inviteProcess.can_send_invites && (
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => onSendProcessInvites(inviteProcess.id)}
                >
                  enviar convocação
                </button>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

InviteProcessCard.propTypes = {
  inviteProcess: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    onboarding: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    invites: PropTypes.arrayOf(PropTypes.shape())
  }),
  onRemoveUserOfProcess: PropTypes.func,
  onAddInvitesToProcess: PropTypes.func,
  onSendProcessInvites: PropTypes.func,
};

InviteProcessCard.defaultProps = {
  inviteProcess: {
    invites: [],
  },
  onRemoveUserOfProcess: () => {},
  onAddInvitesToProcess: () => {},
  onSendProcessInvites: () => {},
};

export default InviteProcessCard;
