import React, { Component, createContext } from 'react';
import debounce from 'debounce-promise';

const sortedIds = {
  date: 'data',
  teacher: 'professor',
  course: 'curso',
  discipline: 'disciplina',
  class: 'turma',
  students: 'nr_alunos',
  status: 'status',
  id: 'id',
};

export const TableContext = createContext({
  page: 0,
  pageSize: 10,
  sorted: [],
  pages: 0,
});

const withTable = (fetch, initialState = {}) => WrappedComponent => (
  class Table extends Component {
    // Uses debounce because of api calls that should start with filters
    // This allows onFilterChange to be called before fetchData
    fetchData = debounce(() => {
      this.setState({ loading: true });

      const {
        page,
        pageSize,
        sorted,
        filters,
      } = this.state;

      fetch({
        page: page + 1,
        page_size: pageSize,
        ordering: sorted.map(field => `${field.desc ? '-' : ''}${sortedIds[field.id] || field.id}`).join(','),
        ...filters,
      })
        .then((data) => {
          this.setState({
            pages: data.total_pages,
            data: data.results,
            loading: false,
          });
        });
    }, 300)

    constructor(props) {
      super(props);
      this.state = {
        page: 0,
        pageSize: 10,
        sorted: [],
        pages: 0,
        filters: {},
        ...initialState,
      };
    }

    componentDidMount() {
      this.fetchData();
    }

    handlePageChange = (pageIndex) => {
      this.setState({ page: pageIndex }, this.fetchData);
    }

    handlePageSizeChange = (pageSize) => {
      this.setState({ pageSize, page: 0 }, this.fetchData);
    }

    handleSortedChange = (newSorted) => {
      this.setState({ sorted: newSorted }, this.fetchData);
    }

    handleFilterChange = (filters) => {
      this.setState({ filters, page: 0 }, this.fetchData);
    }

    render() {
      return (
        <TableContext.Provider
          value={{
            onPageChange: this.handlePageChange,
            onPageSizeChange: this.handlePageSizeChange,
            onSortedChange: this.handleSortedChange,
            page: this.state.page,
            pageSize: this.state.pageSize,
            sorted: this.state.sorted,
            pages: this.state.pages,
            data: this.state.data,
            loading: this.state.loading,
            manual: true,
            resizable: false,
          }}
        >
          <WrappedComponent {...this.props} fetchData={this.fetchData} onFilterChange={this.handleFilterChange} data={this.state.data} />
        </TableContext.Provider>
      );
    }
  }
);

export default withTable;
