import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const CollapsedMenuItem = ({ icon, isOpen, onClick }) => (
  <li className="menu-element" onClick={onClick}>
    <p className={`clickable ${isOpen ? 'active-menu' : ''}`}>
      <FontAwesomeIcon className="menu-icon" icon={icon} />
    </p>
  </li>
);

CollapsedMenuItem.propTypes = {
  icon: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

CollapsedMenuItem.defaultProps = {
  icon: '',
  isOpen: false,
};

export default CollapsedMenuItem;
