import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Ball, StepStatus } from './';

class StepHeader extends React.Component {
  render() {
    const {
      label, description, ballLabel, backgroundColor, status,
    } = this.props;

    return (
      <div className={`step-header-base bg-${backgroundColor}`}>
        <Ball bgColor={get(status, 'color')} ballLabel={ballLabel} />
        <div className="step-header-border" />
        <div className="step-header-title">
          <h3>{ label }</h3>
          <h4>{ description }</h4>
          {get(status, 'status')}
        </div>
        <StepStatus status={status !== 'nonFieldStatus' ? get(status, 'status', get(status, 'name')) : 'nonFieldStatus'} />
      </div>
    );
  }
}

StepHeader.propTypes = {
  colorBall: PropTypes.string,
  colorStatus: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  ballLabel: PropTypes.string,
};

StepHeader.defaultProps = {
  colorBall: 'gray',
  colorStatus: 'gray',
  label: '',
  description: '',
  ballLabel: '',
  status: null,
};

export default StepHeader;
