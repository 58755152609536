import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { API_URL } from '../../lib/const';

export const fetchFinanceData = token => axios.get(`${API_URL}/finance/finance-data/${token}`)
  .then(response => response.data);

export const fetchFinanceDataSummary = token => axios.get(`${API_URL}/finance/finance-data/${token}/summary`)
  .then(response => response.data);

export const validateFinanceData = (token, data) => (
  axios.put(`${API_URL}/finance/finance-data/${token}`, data)
    .then(response => response.data)
    .catch((error) => {
      const status = get(error, 'response.status');
      if (status === 400) {
        const msg = get(error, 'response.data.non_field_errors[0]', 'Por favor, certifique-se de que todos campos foram preenchidos corretamente.');
        toast.error(msg, { position: toast.POSITION.BOTTOM_RIGHT });
        window.scrollTo({ left: 0, top: 300, behavior: 'smooth' });
      }
      return Promise.reject(error);
    })
);

export const fetchFinancePlans = token => axios.get(`${API_URL}/finance/finance-plans/${token}`)
  .then(response => response.data);

export const fetchPaymentPlans = token => axios.get(`${API_URL}/finance/payment-plans/${token}`)
  .then(response => response.data);

export const updateInvoice = (token, data, params = {}) => axios.patch(`${API_URL}/finance/finance-data/${token}/update_invoice`, data, { params })
  .then(response => response.data);

export const generateBoleto = token => axios.post(`${API_URL}/finance/finance-data/${token}/generate_boleto`)
  .then(response => response.data);
