export const getToken = () => localStorage.getItem('token');

export const isAuthenticated = () => getToken() !== null;

export const getAxiosConfig = () => ({
  headers: {
    Authorization: `JWT ${getToken()}`,
  },
});

export const logOut = () => localStorage.removeItem('token');
