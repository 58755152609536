import React from 'react';
import PropTypes from 'prop-types';
import { FieldSet } from '../elements';

class DisabilitiesForm extends React.Component {
  render() {
    const {
      error, options, selectedDisabilities, handleChange,
    } = this.props;
    return (
      <>
        <FieldSet title="Selecione" />
        <div className="row">
          {error && <label className="red" htmlFor="disabilitites-form">{error}</label>}
          {options.map(d => (
            <div id="disabilitites-form" className="col-md-4 col-sm-12">
              <label className="input-check-type" htmlFor={`disability-${d.id}`}>{d.name}
                <input
                  type="checkbox"
                  className="checkbox"
                  id={`disability-${d.id}`}
                  checked={selectedDisabilities.find(x => x === d.id)}
                  onChange={e => handleChange(e, d.id)}
                />
                <span className="checkmark" />
              </label>
            </div>
          ))}
        </div>
      </>
    );
  }
}

DisabilitiesForm.propTypes = {
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      key: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  selectedDisabilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
};

DisabilitiesForm.defaultProps = {
  error: undefined,
};

export default DisabilitiesForm;
