import React, { createContext, Component } from 'react';
import axios from 'axios';
import { API_URL } from '../lib/const';


export const PermissionContext = createContext({
  permissions: {},
  fetchingPermissions: true,
  error: false,
  hasPermission: () => false,
});

const withPermission = WrappedComponent => class Hoc extends Component {
  state = {
    permissions: {},
    fetchingPermissions: true,
    error: false,
  }

  componentDidMount() {
    this.fetchPermissions();
  }

  fetchPermissions = () =>  {
    axios.get(`${API_URL}/login/permissions`)
      .then(response => this.setState({ permissions: response.data, fetchingPermissions: false }))
      .catch(() => this.setState({ fetchingPermissions: false, error: true }));
  }

  hasPermission = (permission) => {
    const { permissions } = this.state;
    // eslint-disable-next-line no-underscore-dangle
    return permissions.__all__ === true || permissions[permission] === true;
  }

  render() {
    const { permissions, fetchingPermissions, error } = this.state;

    if (fetchingPermissions) {
      return null;
    }

    return (
      <PermissionContext.Provider
        value={{
          error,
          fetchingPermissions,
          permissions,
          hasPermission: this.hasPermission,
        }}
      >
        <WrappedComponent {...this.props} permissions={permissions} hasPermission={this.hasPermission} />
      </PermissionContext.Provider>
    );
  }
};

export default withPermission;
