import React, { useReducer, useEffect } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import DocumentsReducer from "../reducers/DocumentsReducer";
import { Documents } from "../components";
import { Spinner } from "../elements";
import { API_URL } from "../../lib/const";
import { handleDocumentUpload } from "../services/upload_service";

const DocumentsStep = ({ match, backClick, nextClick }) => {
  const initialState = {
    loadingDocuments: false,
    submitting: false,
    documentTypes: [],
    personalDocuments: [],
    errors: [],
  };

  const [state, dispatch] = useReducer(DocumentsReducer, initialState);
  const { token } = match.params;

  const {
    documentTypes,
    loadingDocuments,
    personalDocuments,
    submitting,
    errors,
  } = state;
  const setLoading = (isLoading) =>
    dispatch({ type: "SET_LOADING", payload: isLoading });
  const setSubmitting = (isSubmitting) =>
    dispatch({ type: "SET_SUBMITTING", payload: isSubmitting });

  const fetchDocumentTypes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${API_URL}/documents/documents-data/${token}/document_types`
      );
      dispatch({
        type: "SET_DOCUMENTS_DATA",
        payload: res.data.map((d) => ({ ...d, useEquivalent: false })),
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchDocuments = async () => {
    const res = await axios.get(`${API_URL}/documents/documents-data/${token}`);
    dispatch({
      type: "SET_PERSONAL_DOCUMENTS",
      payload: res.data.documents,
    });
    fetchDocumentTypes();
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const handleRemoveDocument = (key) => {
    const newListDocuments = personalDocuments.filter((d) => d.key !== key);
    dispatch({
      type: "REMOVE_PERSONAL_DOCUMENTS",
      payload: newListDocuments,
    });
  };

  const getPesonalDocuments = (document_type) =>
    personalDocuments.filter(
      (d) =>
        d.document_type === document_type.id ||
        d.document_type === get(document_type, "equivalent.id")
    );

  const handleUpload = async (event, document_type) => {
    const file = get(event, "target.files.0");
    try {
      const response = await handleDocumentUpload(file, document_type);
      const { key } = response;
      const newPersonalDocument = { key, document_type, name: file.name };
      dispatch({
        type: "ADD_PERSONAL_DOCUMENTS",
        payload: newPersonalDocument,
      });
    } catch (error) {
      toast.error("Ocorreu um erro ao enviar arquivo. Tente novamente.");
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const documents = personalDocuments;
    try {
      await axios.patch(`${API_URL}/documents/documents-data/${token}`, {
        documents,
      });
      nextClick();
    } catch (error) {
      const status = get(error, "response.status");
      if (status === 400) {
        toast.error("Envie todos os arquivos obrigátorios");
        dispatch({
          type: "SET_ERRORS",
          payload: error.response.data,
        });
      }
      setSubmitting(false);
    }
  };

  const handleUseDocumentEquivalent = (documentTypeId) => {
    dispatch({
      type: "SET_DOCUMENTS_DATA",
      payload: documentTypes.map((d) => {
        const shouldChangeDocument =
          d.id === documentTypeId || get(d, "equivalent.id") === documentTypeId;
        return {
          ...d,
          useEquivalent: shouldChangeDocument
            ? !d.useEquivalent
            : d.useEquivalent,
        };
      }),
    });
  };

  return (
    <Spinner action={loadingDocuments}>
      <div className="documents-base row">
        {documentTypes.map((d) => {
          const usableDocumentType =
            d.useEquivalent && d.equivalent ? d.equivalent : d;

          return (
            <Documents
              key={d.id}
              error={errors[`documents${usableDocumentType.id}`]}
              label={usableDocumentType.name}
              is_required={usableDocumentType.is_required}
              equivalent={usableDocumentType.equivalent}
              onUpload={(event) => handleUpload(event, usableDocumentType.id)}
              onRemove={(key) => handleRemoveDocument(key)}
              documents={getPesonalDocuments(usableDocumentType)}
              onUseDocumentEquivalent={() =>
                handleUseDocumentEquivalent(usableDocumentType.id)
              }
            />
          );
        })}
      </div>
      <div className="text-right mt-3">
        <div className="col-md-4 p-0 offset-md-8 col-sm-12">
          <div className="btn-actions">
            <button
              className="btn btn-black mr-2"
              type="submit"
              onClick={() => backClick()}
            >
              Voltar
            </button>
            <button
              disabled={submitting}
              className="btn btn-primary"
              type="submit"
              onClick={() => handleSubmit()}
            >
              {submitting ? "Carregando..." : "Próximo"}
            </button>
          </div>
        </div>
      </div>
    </Spinner>
  );
};

DocumentsStep.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
  backClick: PropTypes.func.isRequired,
  nextClick: PropTypes.func.isRequired,
};

export default withRouter(DocumentsStep);
