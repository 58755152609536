import React from "react";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Spinner, FieldSet, InputTypeDropdown } from "../../../elements";
import InviteProcessCard from "./InviteProcessCard";
import CandidatesCard from "./CandidatesCard";
import { Radio, RadioGroup, withFormApi } from "informed";
import Axios from "axios";
import { API_URL } from "../../../../lib/const";
import Modal from "../../../components/ConfirmationModal";
import { toast } from "react-toastify";

class InviteProcessForm extends React.Component {
  state = {
    statusTableVisible: false,
    modalVisible: false,
    statusses: {},
    selected_status: undefined,
  };

  handleReinvite(inviteProcess, status, newInviteProcess) {
    const { onOnboardingChange, selectedOnboarding } = this.props;

    this.setState({ modalVisible: false });
    Axios.post(`${API_URL}/reinvite`, {
      invite_process: inviteProcess,
      new_invite_process: newInviteProcess,
      status,
    })
      .then(() => {
        toast.success("Candidatos adicionados ao novo processo com sucesso");
        onOnboardingChange(selectedOnboarding);
      })
      .catch(() => toast.error("Não foi possível adicionar "));
  }

  getStudentsStatus(selectedInviteProcess) {
    Axios.get(`${API_URL}/students-statusses/${selectedInviteProcess.id}`).then(
      (res) => this.setState({ statusses: res.data, statusTableVisible: true })
    );
  }

  render() {
    const {
      statusTableVisible,
      statusses,
      selected_status,
      modalVisible,
    } = this.state;
    const {
      candidates,
      loading,
      openNewInviteProcessModal,
      openEditInviteProcessModal,
      onAddUserToProcess,
      onRemoveUserOfProcess,
      inviteProcesses,
      inviteProcessForm,
      onAddInvitesToProcess,
      onSendProcessInvites,
    } = this.props;
    const inviteProcessOptions = inviteProcesses.map((p) => ({
      label: p.name,
      value: p.id,
    }));
    const selectedInviteProcess = inviteProcesses.find(
      (p) => +inviteProcessForm.inviteProcess === +p.id
    );

    const selectedNewInviteProcess = inviteProcesses.find(
      (p) => +inviteProcessForm.newInviteProcess === +p.id
    );
    const selectedStatusObject = Object.values(statusses).find(
      (p) => +selected_status === +p.id
    );

    const { formApi } = this.props;

    return (
      <FieldSet title="Candidatos">
        <div className="col-12">
          <Spinner active={loading}>
            {candidates ? (
              <>
                <div className="d-flex w-100">
                  <InputTypeDropdown
                    field="inviteProcess"
                    label="Convocação"
                    options={inviteProcessOptions}
                    col="8"
                    onValueChange={() =>
                      this.setState({ statusTableVisible: false })
                    }
                  />
                  {selectedInviteProcess && (
                    <button
                      className="btn btn-primary"
                      style={{ height: "70%", width: "70%", marginTop: "3%" }}
                      onClick={() =>
                        this.getStudentsStatus(selectedInviteProcess)
                      }
                    >
                      Reconvocar
                    </button>
                  )}
                  <button
                    type="button"
                    className="float-button"
                    onClick={() => openNewInviteProcessModal()}
                  >
                    <FontAwesomeIcon icon="plus" />
                  </button>
                  {selectedInviteProcess && (
                    <button
                      type="button"
                      className="float-button mr-6"
                      onClick={() =>
                        openEditInviteProcessModal(selectedInviteProcess)
                      }
                    >
                      <FontAwesomeIcon icon="edit" />
                    </button>
                  )}
                </div>
                {statusTableVisible &&
                  selectedInviteProcess && (
                    <>
                      <div style={{ marginBottom: "10px" }}>
                        <RadioGroup
                          field="statusses"
                          onChange={() => formApi.setError("statusses", null)}
                          onValueChange={(value) =>
                            this.setState({ selected_status: value })
                          }
                        >
                          <ReactTable
                            data={Object.values(statusses)}
                            columns={[
                              {
                                Header: "",
                                accessor: "id",
                                width: 50,
                                Cell: (row) => (
                                  <label className="input-radio-type">
                                    <Radio
                                      value={row.value}
                                      id={`status-${row.value}`}
                                    />
                                    <span
                                      className={`radiomark ${formApi.getError(
                                        "plan"
                                      ) && "radiomark-error"} mt-0`}
                                    />
                                  </label>
                                ),
                              },
                              {
                                Header: "Status",
                                accessor: "name",
                              },
                              {
                                Header: "nº Candidatos",
                                accessor: "num_students",
                              },
                            ]}
                            showPagination={false}
                            defaultPageSize={11}
                            pageSize={11}
                            className="-striped -highlight"
                          />
                        </RadioGroup>
                      </div>
                      {selected_status && (
                        <div style={{ marginBottom: "10px" }}>
                          <InputTypeDropdown
                            field="newInviteProcess"
                            label="Novo processo de Convocação"
                            options={inviteProcessOptions}
                            col="8"
                          />
                        </div>
                      )}
                      {selectedNewInviteProcess && (
                        <div style={{ marginTop: "-3%", marginBottom: "10px" }}>
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              this.setState({ modalVisible: !modalVisible })
                            }
                          >
                            Confirmar
                          </button>
                        </div>
                      )}
                    </>
                  )}
                <div className="row mb-3">
                  <div className="col-6">
                    <CandidatesCard
                      candidates={candidates}
                      inviteProcess={selectedInviteProcess}
                      onAddUserToProcess={onAddUserToProcess}
                    />
                  </div>
                  <div className="col-6">
                    {selectedInviteProcess ? (
                      <InviteProcessCard
                        inviteProcess={selectedInviteProcess}
                        onRemoveUserOfProcess={onRemoveUserOfProcess}
                        onAddInvitesToProcess={onAddInvitesToProcess}
                        onSendProcessInvites={onSendProcessInvites}
                      />
                    ) : (
                      <p className="list-base">
                        Favor selecionar ou adicionar uma nova convocação
                      </p>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <p className="list-base">
                Favor selecionar um processo de matrícula
              </p>
            )}
          </Spinner>
          <Modal
            visible={modalVisible}
            onCancel={() => this.setState({ modalVisible: false })}
            confirmText="Confirmar"
            onConfirm={() =>
              this.handleReinvite(
                selectedInviteProcess.id,
                selected_status,
                selectedNewInviteProcess.id
              )
            }
            title="Confirmar Reconvocação"
          >
            Confirmar reconvocação dos alunos com status{" "}
            {selectedStatusObject && selectedStatusObject.name} do processo{" "}
            {selectedInviteProcess && selectedInviteProcess.name}
          </Modal>
        </div>
      </FieldSet>
    );
  }
}

InviteProcessForm.propTypes = {
  candidates: PropTypes.arrayOf(
    PropTypes.shape({
      real_name: PropTypes.string,
    })
  ),
  inviteProcesses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onboarding: PropTypes.string,
      start_at: PropTypes.string,
      end_at: PropTypes.string,
      invites: PropTypes.arrayOf(PropTypes.shape()),
    })
  ),
  selectedOnboarding: PropTypes.number,
  loading: PropTypes.bool,
  inviteProcessForm: PropTypes.shape({}),
  onRemoveUserOfProcess: PropTypes.func,
  onAddInvitesToProcess: PropTypes.func,
  onSendProcessInvites: PropTypes.func,
  fetchInviteProcess: PropTypes.func,
};

InviteProcessForm.defaultProps = {
  candidates: null,
  selectedOnboarding: null,
  inviteProcesses: [],
  loading: false,
  inviteProcessForm: {},
  onRemoveUserOfProcess: () => {},
  onAddInvitesToProcess: () => {},
  onSendProcessInvites: () => {},
  fetchInviteProcess: () => {},
};

export default withFormApi(InviteProcessForm);
