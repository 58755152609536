import axios from 'axios';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';
import { API_URL } from '../../lib/const';
import ComponentWithToken from '../components/CompontentWithToken';
import ConfirmationModal from '../components/ConfirmationModal';
import { Spinner } from '../elements';
import { MessageContext } from '../v2/common/states/MessageState';
import BaseEnrollmentSummary from './BaseEnrollmentSummary';

class EnrollmentSummaryForm extends ComponentWithToken {
  state = {
    showError: false,
    show: false,
    data: {},
    loading: true,
    isModalOpen: false,
    kind: undefined,
  }

  componentDidMount = () => this.fetchData();

  showModal() {
    this.setState({ isModalOpen: true });
  }

  hideModal() {
    this.setState({ isModalOpen: false });
  }

  fetchData() {
    return axios.get(`${API_URL}/onboarding/personal-data/${this.token}/summary`)
      .then((response) => {
        this.setState({
          data: response.data,
          loading: false,
          kind: get(response.data, 'enrollment.kind') === 'enroll' ? 'ENROLL' : 'RENEW_ENROLL',
        });
      });
  }

  handleSubmit(confirmMessage) {
    this.setState({ submitting: true, isModalOpen: false });
    axios.get(`${API_URL}/onboarding/enrollments/${this.token}/confirm`)
      .then((response) => {
        this.setState({
          show: true,
          showSuccess: true,
          showError: false,
          submitting: false,
          confirmMessage: confirmMessage || get(response, 'data.detail'),
        });
      })
      .catch((error) => {
        this.setState({
          show: true,
          showError: true,
          submitting: false,
          confirmMessage: get(error, 'response.data.detail') || 'Ocorreu um erro',
        });
      });
  }

  render() {
    const {
      showSuccess,
      confirmMessage,
      show,
      showError,
      data,
      loading,
      isModalOpen,
      submitting,
      kind,
    } = this.state;

    const { backClick, onClick } = this.props;
    return (
      <Spinner active={submitting}>
        <MessageContext.Consumer>
          {({ getMessage }) => (
            <>
              <BaseEnrollmentSummary token={this.token} />
              <hr />
              <div className="text-right" style={{ marginBottom: '2.5rem' }}>
                <div className="col-md-4 p-0 offset-md-8 col-sm-12">
                  <div className="btn-actions">
                    {(!loading && (get(data, 'enrollment.status.id') === 1 || get(data, 'enrollment.status.id') === 3)) && (<button className="btn btn-black mr-2" disabled={showError || submitting} type="submit" onClick={() => backClick()}>Voltar</button>)}
                    {(!loading && (get(data, 'enrollment.status.id') === 1 || get(data, 'enrollment.status.id') === 3)) && (<button className="btn btn-primary" disabled={showError || submitting} type="button" onClick={() => this.setState({ isModalOpen: true })}>Enviar para Análise</button>)}
                    {(!loading && (get(data, 'enrollment.status.id') === 2 || get(data, 'enrollment.status.id') === 4)) && (<button className="btn btn-primary" disabled={showError || submitting} type="submit" onClick={() => onClick()}>Próximo</button>)}
                  </div>
                </div>
              </div>
              <SweetAlert
                show={show}
                type={showSuccess ? 'success' : 'error'}
                text={confirmMessage}
                onConfirm={showSuccess ? onClick : () => this.setState({ show: false })}
              />
              <ConfirmationModal
                visible={isModalOpen}
                title="Confirmação"
                onCancel={() => this.hideModal()}
                onConfirm={() => this.handleSubmit(getMessage('SENT_ANALYSIS_CONFIRM', kind))}
              >
                {getMessage('SEND_TO_ANALYSIS') || 'Você confirma o envio dos dados preenchidos para análise do setor financeiro?'}
              </ConfirmationModal>
            </>
          )}
        </MessageContext.Consumer>
      </Spinner>
    );
  }
}

EnrollmentSummaryForm.propTypes = {
  backClick: PropTypes.func,
  onClick: PropTypes.func,
};

EnrollmentSummaryForm.defaultProps = {
  backClick: () => {},
  onClick: () => {},
};

export default withRouter(EnrollmentSummaryForm);
