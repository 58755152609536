import React from 'react';
import { Line } from 'rc-progress';
import { Ball } from './';

class InputDocumentFile extends React.Component {
  state = {
    percent: 0,
    displayProgress: true,
  }

  componentDidMount() {
    const { name } = this.props;
    if(name) {
      this.progressBar()
    }
  }

  progressBar() {
    setTimeout(() => {
      this.setState({
        percent: 50,
      })
    }, 500);
    setTimeout(() => {
      this.setState({
        percent: 100,
      })
    }, 1500);
    setTimeout(() => {
      this.setState({
        displayProgress: false,
      })
    }, 2000)
  }

  render() {
    const { name, removeFile, showModal, progress } = this.props;

    return (
      <div className="documents-uploaded">
        {progress > 0 && <Line percent={progress} strokeWidth="1" strokeColor="#00ae97" className={this.state.displayProgress === false ? 'd-none' : null} />}
        <div className="d-flex align-items-center">
          <div className="documents-length-0"> { name } </div>
          <button className="btn-none" type="button" onClick={showModal}>
            <Ball
              icon="view"
              extraSmall
              bgColor="white"
              iconWidth="16"
            />
          </button>
          <button className="btn-none" type="button" onClick={removeFile}>
            <Ball
              icon="trash"
              bgColor="white"
              iconWidth="16"
              extraSmall
            />
          </button>
        </div>
      </div>
    );
  }
}

InputDocumentFile.defaultProps = {
  progress: 0,
};

export default InputDocumentFile;
