import React from 'react';
import InputAsyncInformed from '../../elements/InputAsyncInformed';
import fetchRecipients from '../../services/EmailTask/options_service';


const EmailRecipientsAsync = props => (
  <InputAsyncInformed
    defaultOptions
    className="w-100 mb-3 mt-0"
    field="recipients"
    placeholder="Destinatários"
    loadOptions={search => fetchRecipients({ search })}
    {...props}
  />
);

export default EmailRecipientsAsync;
