import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URL } from '../../lib/const';
import { FieldSet, Spinner } from '../elements';
import ComponentWithToken from '../components/CompontentWithToken';


class FinanceDataForm extends ComponentWithToken {
  state = {
    loading: false,
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const { onClick } = this.props;

    axios.post(`${API_URL}/onboarding/finance-data-prouni/${this.token}`)
      .then(() => {
        this.setState({ loading: false });
        onClick();
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const {
      loading,
    } = this.state;
    const { backClick, gratuity } = this.props;

    return (
      <Spinner active={loading}>
        <FieldSet title="Dados Financeiros" />
        <p>{gratuity.message}</p>
        <hr />
        <div className="text-right">
          <div className="col-md-4 p-0 offset-md-8 col-sm-12">
            <div className="btn-actions">
              <button className="btn btn-black mr-2" type="submit" onClick={() => backClick()}>Voltar</button>
              <button className="btn btn-primary" type="submit" onClick={this.handleSubmit}>Próximo</button>
            </div>
          </div>
        </div>
      </Spinner>
    );
  }
}

FinanceDataForm.propTypes = {
  gratuity: {
    name: PropTypes.string,
    message: PropTypes.string,
  },
};

FinanceDataForm.defaultProps = {
  gratuity: {},
};

export default withRouter(FinanceDataForm);
