import React from 'react';
import PropTypes from 'prop-types';
import StatusIndicator from '../StatusIndicator';


const statusIndicatorProps = {
  PENDING: {
    children: <strong>Pendente</strong>,
    color: 'gold',
    icon: 'minus-circle',
  },
  PAID: {
    children: <strong>Pago</strong>,
    color: 'limegreen',
    icon: 'check-circle',
  },
  DUE: {
    children: <strong>Vencido</strong>,
    color: 'darkred',
    icon: 'times-circle',
  },
};

const BoletoStatus = ({ status }) => (
  <StatusIndicator {...statusIndicatorProps[status]} />
);

BoletoStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default BoletoStatus;
