import React from 'react';
import { Form } from 'informed';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import Container from '../../common/components/Container';
import OnboardingForm from '../components/OnboardingForm';
import InviteProcessForm from '../components/InviteProcessForm';
import NewInviteProcessForm from '../components/NewInviteProcessForm';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { fetchCandidates, fetchInviteProcess } from '../services/fetch_invites';
import { createInviteProcess, createInvite } from '../services/create_invites';
import { deleteInvite } from '../services/delete_invites';
import {
  setProcessInvites,
  sendProcessInvites,
  sendInvite,
  updateInviteProcess,
} from '../services/update_invites';


class InviteProcessPage extends React.Component {
  state = {
    candidates: undefined,
    inviteProcessDocument: undefined,
    loadingCandidates: false,
    showNewInviteProcessModal: false,
    newInviteProcessForm: {},
    inviteProcessForm: {},
    selectedOnboarding: {},
    newInviteProcessLoading: false,
    inviteProcesses: [],
  }

  previousErrors = {}

  fetchInviteProcess = (onboarding, nextInvite = null) => fetchInviteProcess({ onboarding }).then((inviteProcesses) => {
    this.setState({ inviteProcesses });
    if (nextInvite) {
      this.formApi.setValue('inviteProcess', nextInvite);
    } else if (inviteProcesses.length === 1 && this.formApi.fieldExists('inviteProcess')) {
      this.formApi.setValue('inviteProcess', inviteProcesses[0].id);
    }
  })

  fetchCandidates = onboardingId => fetchCandidates({ onboarding: onboardingId }).then(d => this.setState({ candidates: d }));

  onOnboardingChange = (onboarding, nextInvite = null) => {
    this.setState({ loadingCandidates: true, selectedOnboarding: onboarding });
    Promise.all([
      this.fetchInviteProcess(onboarding.id, nextInvite),
      this.fetchCandidates(onboarding.id),
    ]).finally(() => this.setState({ loadingCandidates: false }));
  }

  openNewInviteProcessModal = () => {
    this.newInviteProcessFormApi.reset();
    this.setState({ showNewInviteProcessModal: true });
  }

  openEditInviteProcessModal = (data) => {
    this.newInviteProcessFormApi.setValues(data);
    this.setState({ inviteProcessDocument: data.notice, showNewInviteProcessModal: true });
  }

  handleCreateNewInviteProcess = () => {
    const { newInviteProcessForm, selectedOnboarding } = this.state;
    this.setState({ newInviteProcessLoading: true });
    const data = {
      onboarding: selectedOnboarding.id,
      ...newInviteProcessForm,
    };
    createInviteProcess(data)
      .then((inviteProcess) => {
        this.newInviteProcessFormApi.reset();
        const nextInvite = inviteProcess.id;
        this.setState({
          newInviteProcessLoading: false,
          showNewInviteProcessModal: false,
        }, () => this.onOnboardingChange(selectedOnboarding, nextInvite));
      })
      .catch((error) => {
        this.setState({ newInviteProcessLoading: false });
        const status = get(error, 'response.status');
        if (status === 400) {
          const errors = get(error, 'response.data');
          Object.keys(this.previousErrors).forEach((fieldName) => {
            if (this.newInviteProcessFormApi.fieldExists(fieldName)) {
              this.newInviteProcessFormApi.setError(fieldName, null);
            }
          });
          this.previousErrors = { ...errors };
          Object.entries(errors).forEach((e) => {
            if (this.newInviteProcessFormApi.fieldExists(e[0])) {
              this.newInviteProcessFormApi.setError(e[0], e[1]);
            }
          });
        }
      });
  }

  handleEditInviteProcess = (id) => {
    const { newInviteProcessForm, selectedOnboarding } = this.state;
    this.setState({ newInviteProcessLoading: true });
    const data = {
      ...newInviteProcessForm,
      onboarding: selectedOnboarding.id,
    };
    updateInviteProcess(id, data)
      .then(() => {
        this.newInviteProcessFormApi.reset();
        this.setState({
          newInviteProcessLoading: false,
          showNewInviteProcessModal: false,
        }, () => this.onOnboardingChange(selectedOnboarding));
      })
      .catch((error) => {
        this.setState({ newInviteProcessLoading: false });
        const status = get(error, 'response.status');
        if (status === 400) {
          const errors = get(error, 'response.data');
          Object.keys(this.previousErrors).forEach((fieldName) => {
            if (this.newInviteProcessFormApi.fieldExists(fieldName)) {
              this.newInviteProcessFormApi.setError(fieldName, null);
            }
          });
          this.previousErrors = { ...errors };
          Object.entries(errors).forEach((e) => {
            if (this.newInviteProcessFormApi.fieldExists(e[0])) {
              this.newInviteProcessFormApi.setError(e[0], e[1]);
            }
          });
        }
      });
  }

  handleConfirmInviteProcessForm = () => {
    const id = this.newInviteProcessFormApi.getValue('id');
    if (id) {
      this.handleEditInviteProcess(id);
    } else {
      this.handleCreateNewInviteProcess();
    }
  }

  handleAddUserToProcess = (candidate, inviteProcess) => {
    const { selectedOnboarding } = this.state;
    if (candidate && inviteProcess) {
      const data = {
        process: inviteProcess.id,
        enrollment: candidate.enrollment_id,
      };
      createInvite(data)
        .then(() => this.onOnboardingChange(selectedOnboarding))
        .catch(err => this.handleError(err));
    }
  }

  handleRemoveUserOfProcess = (invite) => {
    const { selectedOnboarding } = this.state;
    deleteInvite(invite)
      .then(() => this.onOnboardingChange(selectedOnboarding))
      .catch(err => this.handleError(err));
  }

  handleAddInvitesToProcess = (inviteProcess) => {
    const { selectedOnboarding } = this.state;
    setProcessInvites(inviteProcess)
      .then(() => this.onOnboardingChange(selectedOnboarding))
      .catch(err => this.handleError(err));
  }

  handleSendProcessInvites = (inviteProcess) => {
    const { selectedOnboarding } = this.state;
    this.setState({ loadingCandidates: true });
    sendProcessInvites(inviteProcess)
      .then(() => this.onOnboardingChange(selectedOnboarding))
      .catch((err) => {
        this.setState({ loadingCandidates: false });
        this.handleError(err);
      });
  }

  handleSendInvite = (invite) => {
    const { selectedOnboarding } = this.state;
    this.setState({ loadingCandidates: true });
    sendInvite(invite)
      .then(() => this.onOnboardingChange(selectedOnboarding))
      .catch((err) => {
        this.setState({ loadingCandidates: false });
        this.handleError(err);
      });
  }


  handleError(err) {
    const message = get(err, 'response.data.non_field_errors[0]') || get(err, 'response.data.detail');
    toast.error(message || 'Ocorreu um erro inesperado, tente novamente mais tarde');
  }

  render() {
    const {
      candidates,
      loadingCandidates,
      showNewInviteProcessModal,
      selectedOnboarding,
      newInviteProcessLoading,
      inviteProcesses,
      inviteProcessForm,
      newInviteProcessForm,
      inviteProcessDocument,
    } = this.state;

    const today = new Date();
    const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

    return (
      <Container title="Convocação do processo de matrícula">
        <Form
          onValueChange={values => this.setState({ inviteProcessForm: values })}
          className="mb-5"
          getApi={(api) => { this.formApi = api; }}
        >
          <OnboardingForm params={{ end_at: date }} onValueChange={this.onOnboardingChange} />
          <InviteProcessForm
            candidates={candidates}
            inviteProcesses={inviteProcesses}
            loading={loadingCandidates}
            inviteProcessForm={inviteProcessForm}
            openNewInviteProcessModal={this.openNewInviteProcessModal}
            openEditInviteProcessModal={this.openEditInviteProcessModal}
            onAddUserToProcess={this.handleAddUserToProcess}
            onRemoveUserOfProcess={this.handleRemoveUserOfProcess}
            onAddInvitesToProcess={this.handleAddInvitesToProcess}
            onSendProcessInvites={this.handleSendProcessInvites}
            selectedOnboarding={selectedOnboarding}
            onOnboardingChange={this.onOnboardingChange}
          />
        </Form>
        <ConfirmationModal
          visible={showNewInviteProcessModal}
          title="Convocação"
          onCancel={() => this.setState({ showNewInviteProcessModal: false })}
          onConfirm={() => this.handleConfirmInviteProcessForm()}
          cancelText="Cancelar"
          loading={newInviteProcessLoading}
          confirmText="Confirmar"
          height="700"
        >
          <NewInviteProcessForm
            onChange={values => this.setState({ newInviteProcessForm: { ...newInviteProcessForm, ...values } })}
            inviteProcessDocument={inviteProcessDocument}
            getApi={(api) => { this.newInviteProcessFormApi = api; }}
            newInviteProcessLoading={newInviteProcessLoading}
            selectedOnboarding={selectedOnboarding}
            sendDocumentData={fileData => this.setState({
              newInviteProcessForm: { ...newInviteProcessForm, notice: fileData },
            })}
            onRemoveFile={() => this.setState({ newInviteProcessForm: { ...newInviteProcessForm, notice: null } })}
          />
        </ConfirmationModal>
      </Container>
    );
  }
}

export default InviteProcessPage;
