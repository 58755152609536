import React from 'react';
import PropTypes from 'prop-types';
import { FieldSet } from '../../elements';

const EducationForm = ({ children }) => <FieldSet title="Formação">{children}</FieldSet>;

EducationForm.propTypes = {
  children: PropTypes.node,
};

EducationForm.defaultProps = {
  children: null,
};

export default EducationForm;
