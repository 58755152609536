import React, { useEffect, useState } from 'react'
import Educhat, { listUsers, currentUser, chatTheme, connectionConfig, chatConfig } from 'educhat'
import { CHAT_CONNECTION } from '../../lib/const'

const ChatInbox = ({sourceUser, supportUser, client}) => {

  const [ isLoading, setIsLoading ] = useState(false)

  useEffect(() => {
    if(supportUser) {

      if (client === 'ENROLL') {
        listUsers([
          supportUser
        ])

        chatConfig({
          provider: 'rematricula',
          oneContact: true,
        })
      } else if (client === 'SUPPORT') {
        listUsers(supportUser)
        chatConfig({
          provider: 'rematricula'
        })
      }

      currentUser(sourceUser)

      connectionConfig(JSON.parse(CHAT_CONNECTION))

      chatTheme({
        fontFamily: 'BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif',
        primaryColor: 'rgb(73, 157, 150)',
        secondaryColor: '#c4c4c4',
        fontColor: '#ffffff',
        messageMeBackground: 'rgb(0, 163, 151)',
        messageOtherBackground: 'rgb(125, 108, 47)',
        messageMeColor: '#fff',
        messageOtherColor: '#fff'
      })
    }
    setIsLoading(true)
  }, [supportUser])

  return <>{isLoading && <Educhat />}</>
}

export default ChatInbox
