import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StatusIndicator = ({
  className, color, children, icon,
}) => (
  <span>
    <FontAwesomeIcon icon={icon || 'circle'} className={`mr-1 ${className}`} style={color ? { color } : {}} />
    {children}
  </span>
);

StatusIndicator.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  icon: PropTypes.string,
};

StatusIndicator.defaultProps = {
  className: '',
  children: null,
  color: null,
  icon: 'circle',
};

export default StatusIndicator;
