import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio, withFormApi } from 'informed';
import { FieldSet } from '../elements';


class GuaranteeTypeForm extends React.Component {
  render() {
    const { guaranteeTypes, formApi } = this.props;
    const error = formApi.getError('guarantee_type');

    return (
      <FieldSet title="Tipo de garantia">
        <div className="list-base">
          <RadioGroup style={{ display: 'flex' }} field="guarantee_type">
            <div id="guarantee_type-options" className="radio-group">
              {guaranteeTypes.map(h => (
                <label key={h.key} className="input-radio-type" htmlFor={`guarantee_type-${h.id}`}>{h.name}
                  <Radio value={h.id} id={`guarantee_type-${h.id}`} />
                  <span className="radiomark" />
                </label>
              ))}
            </div>
          </RadioGroup>
          {error && <label className="red" htmlFor="guarantee_type-options"> {error}</label>}
        </div>
      </FieldSet>
    );
  }
}

GuaranteeTypeForm.propTypes = {
  guaranteeTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      key: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  formApi: PropTypes.shape({
    getError: PropTypes.func,
  }).isRequired,
};

GuaranteeTypeForm.defaultProps = {
  guaranteeTypes: [],
};

export default withFormApi(GuaranteeTypeForm);
