import React from 'react';
import PropType from 'prop-types';
import { API_URL } from '../../lib/const';
import DownloadButton from '../components/DownloadButton';

class EndStep extends React.Component {
  render() {
    const { token } = this.props;

    return (
      <>
        <div className="text-center">
          <h3 className="green">Parabéns!</h3>
          <p className="mt-1">Você finalizou o processo de Matrícula com sucesso!</p>
        </div>
        <div className="d-flex justify-content-center">
          <DownloadButton label="Baixar Contrato" href={`${API_URL}/contracts/enrollment/${token}/contract`} />
        </div>
      </>
    );
  }
}

EndStep.propTypes = {
  token: PropType.string,
};

EndStep.defaultProps = {
  token: '',
};

export default EndStep;
