import axios from 'axios';
import { API_URL } from '../../lib/const';
import { getAxiosConfig } from '../../lib/auth';

export const fetchPendencies = enrollment => axios.get(`${API_URL}/onboarding/pendencies`, { params: { enrollment }, ...getAxiosConfig() })
  .then(response => response.data);

export const createPendency = (enrollment, values) => axios.post(`${API_URL}/onboarding/pendencies`, { ...values, enrollment: enrollment.id }, getAxiosConfig())
  .then(response => response.data);

export const sendPendencies = token => axios.post(`${API_URL}/onboarding/enrollments/${token}/pendency`, {}, getAxiosConfig())
  .then(response => response.data);
