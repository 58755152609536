import { ReactNode } from 'react';

export const childrenIsFunction = function(children: Function | ReactNode): children is Function {
  return typeof children === 'function'
}

export type IMessageStateAction =
  | { type: 'FETCH_MESSAGES' }
  | { type: 'FETCH_MESSAGES_MESSAGES_SUCCESS', payload: any }

export interface IMessage {
  name: string
  value: string
  context: undefined | 'ENROLL' | 'RENEW_ENROLL'
}

export interface IMessageState {
  fecthing: boolean
  messages: IMessage[]
}

export interface IFormApi {
  getError: Function
}

export interface IPaymentMethod {
  id: number
  name: string
}
