import React from 'react';
import { asField } from 'informed';


export default asField(({
  row, error, required, className, helper, fieldState, fieldApi, field, label, col, helpBlock, numbers, onChange, ...props
}) => (
  <div className={className || `col-md-${col} col-sm-12`}>
    <div className="text-area-type-style">
      <textarea
        className="text-area-style"
        id={field}
        value={fieldState.value || ''}
        onChange={(e) => {
          fieldApi.setValue(e.target.value);
          fieldApi.setError(null);
          if (onChange) {
            onChange(e);
          }
        }}
        rows={row}
        {...props}
      />
      <label
        htmlFor={field}
        className={`text-area-label ${required ? 'required' : ''} ${fieldState.error ? 'label-error' : ''}`}
      >
        {label}
      </label>
      {helper &&
        <label
          className="input-helper">
          {helper}
        </label>}
      {fieldState.error && fieldState.error.map(e => <label key={e} className="input-helper text-danger">{e}</label>)}
    </div>
  </div>
));
