import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const SidebarItemOption = ({ to, children, onClick }) => {
  const externalLink = !to.startsWith('/');

  if (externalLink) {
    return (
      <li>
        <a href={to} rel="noopener noreferrer">{children}</a>
      </li>
    );
  }

  return (
    <li>
      <NavLink exact activeClassName="active-link" to={to} onClick={onClick}>
        {children}
      </NavLink>
    </li>
  );
};

SidebarItemOption.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarItemOption.defaultProps = {
  children: null,
  to: '',
  onClick: () => {},
};

export default SidebarItemOption;
