import React from 'react';
import { Radio, RadioGroup, withFormApi } from 'informed';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import ComponentWithToken from './CompontentWithToken';
import { FieldSet } from '../elements';
import PaymentPlans from './PaymentPlans';
import { formatCurrency, convertCurrencyValue } from '../../lib/utils';

class FinancePlansForm extends ComponentWithToken {
  state = {
    selectedFinancePlan: undefined,
  }

  onValueChange = (value) => {
    const { financePlans } = this.props;
    const selectedFinancePlan = financePlans.find(financePlan => financePlan.id === value);
    this.setState({ selectedFinancePlan });
  }

  getPlanValue = (planId) => {
    const { paymentPlans } = this.props;
    if (paymentPlans.length === 0) {
      return 'Calculando...';
    }
    const payment_plans = paymentPlans.filter(p => +p.finance_plan === +planId);
    return payment_plans.reduce((accumulator, payment) => accumulator + convertCurrencyValue(payment.value), 0);
  }

  render() {
    const { selectedFinancePlan } = this.state;
    const { financePlans, formApi, paymentPlans } = this.props;

    return (
      <>
        <FieldSet title="Dados Financeiros">
          <div className="list-base">
            <RadioGroup field="plan" onChange={() => formApi.setError('plan', null)} onValueChange={this.onValueChange}>
              <ReactTable
                data={financePlans}
                columns={[
                  {
                    Header: '',
                    accessor: 'id',
                    width: 50,
                    Cell: row => (
                      <label className="input-radio-type">
                        <Radio value={row.value} id={`finance_plan-${row.value}`} />
                        <span className={`radiomark ${formApi.getError('plan') && 'radiomark-error'} mt-0`} />
                      </label>
                    ),
                  },
                  {
                    Header: 'Plano',
                    accessor: 'name',
                  },
                  {
                    Header: 'Vencimento',
                    accessor: 'due_date',
                  },
                  {
                    Header: 'Fiadores',
                    accessor: 'guarantors',
                  },
                  {
                    Header: 'Total',
                    accessor: 'id',
                    Cell: row => formatCurrency(this.getPlanValue(row.value)),
                  },
                  {
                    Header: 'Parcelas',
                    accessor: 'installments',
                  },
                ]}
                showPagination={false}
                defaultPageSize={financePlans.length}
                pageSize={financePlans.length}
                className="-striped -highlight"
              />
            </RadioGroup>
          </div>
          <i className="helper">
            {selectedFinancePlan && selectedFinancePlan.message}
          </i>
        </FieldSet>
        <PaymentPlans financePlan={selectedFinancePlan} paymentPlans={paymentPlans} />
      </>
    );
  }
}

FinancePlansForm.propTypes = {
  financePlans: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    due_date: PropTypes.string,
    guarantors: PropTypes.number,
    value: PropTypes.string,
    installments: PropTypes.number,
    message: PropTypes.string,
  })),
  formApi: PropTypes.shape({
    setError: PropTypes.func,
    getValue: PropTypes.func,
  }).isRequired,
  paymentPlans: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    date: PropTypes.string,
    value: PropTypes.string,
  })),
};

FinancePlansForm.defaultProps = {
  financePlans: [],
  paymentPlans: [],
};

export default withFormApi(FinancePlansForm);
