import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withFormApi } from 'informed';
import { Documents } from '.';
import { MARITIAL_STATUS } from '../../lib/config';
import { handleDocumentUpload } from '../services/upload_service';

class GuarantorDocumentsForm extends React.Component {
  state = {
    documentUploadProgress: {},
  }

  getDocuments(documentType, guarantorIndex) {
    const { formApi } = this.props;
    const { values } = formApi.getState();
    const documents = get(values, `guarantors.${guarantorIndex}.documents`) || [];
    return documents.filter(d => +documentType.id === +d.document_type);
  }

  handleRemoveDocument = (key, guarantorIndex) => {
    const { formApi } = this.props;
    const { values } = formApi.getState();
    const { guarantors } = values;
    const guarantor = guarantors[guarantorIndex];
    guarantor.documents = guarantor.documents.filter(d => d.key !== key);
    formApi.setValues({
      ...values,
      guarantors,
    });
  }

  getProgress = (guarantorIndex) => {
    const { documentUploadProgress } = this.state;
    return get(documentUploadProgress, guarantorIndex) || 0;
  }

  onUploadProgress = (progressEvent) => {
    const { guarantorIndex } = this.props;

    if (progressEvent) {
      const { loaded, total } = progressEvent;
      this.setState(prevState => ({
        documentUploadProgress: {
          ...prevState.documentUploadProgress,
          [guarantorIndex]: total ? Math.floor(100 * loaded / total) : 0,
        },
      }));
    }
  }

  handleUpload = async (event, document_type) => {
    const file = get(event, 'target.files.0');
    const response = await handleDocumentUpload(file, document_type, { onUploadProgress: this.onUploadProgress });
    const { key } = response;
    const { guarantorIndex, formApi } = this.props;
    const { values } = formApi.getState();
    const { guarantors } = values;
    const guarantor = guarantors[guarantorIndex] || {};
    guarantor.documents = guarantor.documents || [];
    guarantor.documents.push({
      key,
      document_type,
      name: file.name,
    });

    if (!guarantors[guarantorIndex]) {
      guarantors[guarantorIndex] = guarantor;
    }
    formApi.setValues({
      ...values,
      guarantors,
    });
  }

  mapDocuments = (documentTypes) => {
    const { guarantorIndex, formApi } = this.props;

    return documentTypes.map(d => (
      <Documents
        key={d.id}
        label={d.name}
        error={get(formApi.getState().errors, `guarantors[${guarantorIndex}].documents${d.id}`)}
        onUpload={event => this.handleUpload(event, d.id)}
        onRemove={key => this.handleRemoveDocument(key, guarantorIndex)}
        documents={this.getDocuments(d, guarantorIndex)}
        progress={this.getProgress(guarantorIndex)}
      />
    ));
  }

  getDocumentsTypes = (check_field) => {
    const { documentTypes } = this.props;
    return documentTypes.filter(documentType => get(documentType, check_field));
  }

  render() {
    const { guarantorIndex, formApi } = this.props;

    return (
      <>
        <p className="sub-field-set">Vincule os documentos do Fiador {guarantorIndex + 1}: {get(formApi.getState(), `values.guarantors[${guarantorIndex}].name`)}</p>
        <div className="documents-base row">
          {this.mapDocuments(this.getDocumentsTypes('ask_if_guarantor'))}
          {+get(formApi.getState(), `values.guarantors[${guarantorIndex}].marital_status`, null) === MARITIAL_STATUS.MARRIED && this.mapDocuments(this.getDocumentsTypes('ask_if_spouse'))}
          {+get(formApi.getState(), `values.guarantors[${guarantorIndex}].marital_status`, null) === MARITIAL_STATUS.DIVORCED && this.mapDocuments(this.getDocumentsTypes('ask_if_divorced'))}
          {+get(formApi.getState(), `values.guarantors[${guarantorIndex}].marital_status`, null) === MARITIAL_STATUS.WIDOWER && this.mapDocuments(this.getDocumentsTypes('ask_if_widower'))}
        </div>
      </>
    );
  }
}

GuarantorDocumentsForm.propTypes = {
  guarantorIndex: PropTypes.number.isRequired,
  documentTypes: PropTypes.arrayOf(PropTypes.object),
  formApi: PropTypes.shape({
    getState: PropTypes.func,
  }).isRequired,
};

GuarantorDocumentsForm.defaultProps = {
  documentTypes: [],
};

export default withFormApi(GuarantorDocumentsForm);
