import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import { InputDateTime, InputTypeText, InputTypeCheck } from '../../../elements';
import Document from '../../../components/common/upload/Document';
import { DOCUMENT_TYPE } from '../../../../lib/config';


class NewInviteProcessForm extends React.Component {
  render() {
    const {
      onChange,
      getApi,
      newInviteProcessLoading,
      selectedOnboarding,
      sendDocumentData,
      inviteProcessDocument,
      onRemoveFile,
    } = this.props;

    return (
      <Form
        onValueChange={onChange}
        getApi={getApi}
      >
        <div className="p-3">
          <p><strong>Curso:</strong> {selectedOnboarding.course}</p>
          <p><strong>Currículo:</strong> {selectedOnboarding.curriculum}</p>
          <p><strong>Ano / Período de promoção:</strong> {selectedOnboarding.field_step}</p>
        </div>
        <InputTypeText disabled={newInviteProcessLoading} field="name" label="Nome" />
        <div className="row">
          <div className="col-6">
            <InputDateTime disabled={newInviteProcessLoading} field="start_at" label="Início" />
          </div>
          <div className="col-6">
            <InputDateTime disabled={newInviteProcessLoading} field="end_at" label="Final" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <InputTypeCheck
              disabled={newInviteProcessLoading}
              field="require_term_of_absence"
              label="Termo de ciência de faltas obrigatório"
            />
          </div>
        </div>
        <Document
          sendDocumentData={sendDocumentData}
          document_type={DOCUMENT_TYPE.NOTICE}
          documentData={inviteProcessDocument}
          onRemoveFile={onRemoveFile}
        />
      </Form>
    );
  }
}

NewInviteProcessForm.propTypes = {
  onChange: PropTypes.func,
  getApi: PropTypes.func,
  sendDocumentData: PropTypes.func,
  newInviteProcessLoading: PropTypes.bool,
  selectedOnboarding: PropTypes.shape({
    course: PropTypes.string,
    curriculum: PropTypes.string,
    field_step: PropTypes.string,
  }),
};

NewInviteProcessForm.defaultProps = {
  onChange: () => {},
  getApi: () => {},
  sendDocumentData: () => {},
  newInviteProcessLoading: false,
  selectedOnboarding: {},
};

export default NewInviteProcessForm;
