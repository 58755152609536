import React from 'react';
import PropTypes from 'prop-types';
import Boleto from './Boleto';
import ComplementaryBoleto from './ComplementaryBoleto';
import { FieldSet } from '../../elements';


class ListBoleto extends React.Component {
  render() {
    const {
      complementaryBoletos, boleto, resubmitting, onResubmit, showResubmit,
    } = this.props;

    return (
      <>
        <Boleto
          id={boleto}
          resubmitting={resubmitting}
          onResubmit={onResubmit}
          showResubmit={showResubmit}
        />
        {complementaryBoletos.length > 0 && (
          <>
            <FieldSet title="Boletos Complementares">
              {complementaryBoletos.map(complementaryBoleto => (
                <ComplementaryBoleto
                  id={complementaryBoleto.boleto_id}
                  reason={complementaryBoleto.reason}
                />
              ))}
            </FieldSet>
          </>
        )}
      </>
    );
  }
}

ListBoleto.propTypes = {
  boleto: PropTypes.number,
  resubmitting: PropTypes.bool,
  onResubmit: PropTypes.func,
  showResubmit: PropTypes.bool,
  complementaryBoletos: PropTypes.arrayOf(PropTypes.shape({
    boleto_id: PropTypes.number,
    reason: PropTypes.string,
  })),
};

ListBoleto.defaultProps = {
  complementaryBoletos: [],
  boleto: undefined,
  showResubmit: false,
  onResubmit: () => null,
  resubmitting: false,
};

export default ListBoleto;
