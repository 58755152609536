import React from 'react';
import PropTypes from 'prop-types';
import StatusIndicator from './StatusIndicator';

const StatusCard = ({ status }) => (
  <div className="status-card">
    <div className="d-flex  justify-content-between align-items-center pl-2">
    <StatusIndicator color={status.color}>{status.name}</StatusIndicator>
      <p className="status-value m-0 bg-silver--lighter p-2">
        <span className="gray-darker width-300">{status.value}</span>
      </p>
    </div>
  </div>
);

StatusCard.propTypes = {
  status: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number,
  }),
};

StatusCard.defaultProps = {
  status: {
    color: '',
    name: '',
    value: '',
  },
};

export default StatusCard;
