import React from 'react';
import PropTypes from 'prop-types';
import { Ball } from '.';

class Card extends React.Component {
  render() {
    const { title, children, icon, error } = this.props;

    return (
      <div className={`card ${error ? 'error' : ''}`}>
        {
          title && (
            <div className="card-header">
              <h3 className="m-1">
                { title }
              </h3>
              {icon && <Ball name={icon} />}
            </div>
          )
        }
        <div>
          { children }
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
};

Card.defaultProps = {
  title: null,
  children: null,
  icon: null,
};

export default Card;
