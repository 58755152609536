import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import BoletoStatus from './BoletoStatus';
import DownloadButton from '../DownloadButton';

const BOLETO_QUERY = gql`
  query Boleto($id: ID!) {
    boleto(id: $id) {
      id
      pdfUrl
      status
      formattedTypableLine
    }
  }
`;

const Boleto = ({ id, reason }) => {
  const {
    loading, error, data, refetch,
  } = useQuery(BOLETO_QUERY, { variables: { id } });
  const boleto = get(data, 'boleto') || {};

  useEffect(() => {
    const interval = setInterval(() => refetch(), 3000);
    return () => clearInterval(interval);
  }, []);

  if (error || loading || boleto.status === 'UNAVAILABLE') {
    return (
      <div>
        <p className="boleto-reason">{reason}</p>
        <p>Por favor, aguarde alguns instantes até que o boleto seja gerado.</p>
      </div>
    );
  }

  return (
    <div>
      <p className="boleto-reason">{reason}</p>
      <BoletoStatus status={boleto.status} />
      <DownloadButton href={boleto.pdfUrl} label="Baixar boleto" filename="boleto.pdf" />
    </div>
  );
};

Boleto.propTypes = {
  id: PropTypes.number,
  reason: PropTypes.string.isRequired,
};

Boleto.defaultProps = {
  id: undefined,
};

export default Boleto;
