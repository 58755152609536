export const DOCUMENTS = {
  TYPE: [
    { id: 1, label: 'CPF' },
    { id: 2, label: 'Documento com foto' },
    { id: 3, label: 'Comprovante de endereço' },
    { id: 4, label: 'Comprovante de Renda' },
    { id: 5, label: 'Certidão de Casamento' },
    { id: 6, label: 'Boleto' },
    { id: 7, label: 'Carta Fiança' },
    { id: 8, label: 'Certidão de Averbação' },
    { id: 9, label: 'Certidão de Óbito' },
  ],
};

export const MARITIAL_STATUS = {
  MARRIED: 1,
  DIVORCED: 2,
  STABLE_UNION: 3,
  SINGLE: 4,
  WIDOWER: 5,
  OTHER: 6,
};

export const MARITAL_PROPERTY_SYSTEM = {
  SEPARATION_OF_GOODS: 1,
  PARTIAL_COMMUNION_OF_GOODS: 2,
  UNIVERSAL_COMMUNION_OF_GOODS: 3,
  FINAL_PART_OF_THE_PROCEEDINGS: 4,
};

export const DOCUMENT_TYPE = {
  CPF: 1,
  DOCUMENT_WITH_PHOTO: 2,
  PROOF_OF_ADDRESS: 3,
  PROOF_OF_INCOME: 4,
  WEDDING_CERTIFICATE: 5,
  INVOICE: 6,
  LETTER_GUARANTEE: 7,
  ENDORSEMENT_CERTIFICATE: 8,
  DEATH_CERTIFICATE: 9,
  VACCINE_CARD: 19,
  NOTICE: 24,
};

export const GUARANTEE_TYPE = {
  GUARANTOR: 1,
  LETTER_GUARANTEE: 2,
};

export const ENROLLMENT_STATUS = {
  FILLING: 1,
  REVIEWING: 2,
  PENDING: 3,
  WAITING_PAYMENT: 4,
  PAYMENT_REVIEWING: 5,
  FINALIZED: 6,
  ACCESSED: 7,
  NOT_STARTED: 8,
};


export const CONTRACT_STATUS = {
  WAITING_SIGNS: 1,
  WAITING_INSTITUTION_SIGN: 2,
  DONE: 3,
};

export const PAYMENT_STATUS = {
  BOLETO_NOT_ISSUED: 1,
  WAITING_PAYMENT: 2,
  PAYMENT_CONFIRMED: 3,
};

export const PAYMENT_METHOD = {
  SICOOB_LOAN: 2,
};
