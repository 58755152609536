import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { API_URL } from '../../../../lib/const';
import { Ball } from '../../../elements';
import { Modal } from '../..';
import { handleDocumentUpload } from '../../../services/upload_service';

const Document = ({
  document_type,
  sendDocumentData,
  documentData,
  onRemoveFile,
}) => {
  const [currentFile, setCurrentFile] = useState(null);
  const [personalDocument, setPersonalDocument] = useState(null);
  const [showModal, setModal] = useState(false);
  const [modalDocument, setModalDocument] = useState({});

  const handleUpload = async (event) => {
    const file = get(event, 'target.files.0');
    if (file) {
      setCurrentFile(file.name);
      try {
        const data = await handleDocumentUpload(file, document_type);
        const { key } = data;
        const fileData = { key, name: file.name, document_type };
        setPersonalDocument(file);
        sendDocumentData(fileData);
        setCurrentFile(null);
      } catch (error) {
        toast.error('Ocorreu um erro ao enviar arquivo. Tente novamente.');
        setCurrentFile(null);
      }
    } else {
      setCurrentFile(null);
    }
  };

  useEffect(() => {
    setPersonalDocument(documentData);
  }, [documentData]);

  const handleModal = async () => {
    const response = await axios.get(`${API_URL}/uploads/${personalDocument.key}`);
    setModalDocument(response.data);
    setModal(true);
  };

  const removeFile = () => {
    setPersonalDocument(null);
    onRemoveFile(null);
  };

  if (currentFile) {
    return (
      <div className="documents-base">
        <div className="documents-uploaded">
          <div className="d-flex align-items-center">
            <div className="documents-length-0"> {currentFile} </div>
          </div>
        </div>
      </div>
    );
  }

  if (personalDocument) {
    return (
      <>
        <div className="documents-base">
          <div className="documents-uploaded">
            <div className="d-flex align-items-center">
              <div className="documents-length-0"> {personalDocument.name} </div>
              <button className="btn-none" type="button" onClick={handleModal}>
                <Ball
                  extraSmall
                  icon="view"
                  bgColor="white"
                  iconWidth="16"
                />
              </button>
              <button className="btn-none" type="button" onClick={removeFile}>
                <Ball
                  extraSmall
                  icon="trash"
                  bgColor="white"
                  iconWidth="16"
                />
              </button>
            </div>
          </div>
        </div>
        <Modal visible={showModal} onClose={() => setModal(false)} title={personalDocument.name} src={modalDocument.url} />
      </>
    );
  }

  return (
    <div className="documents-base">
      <div className="documents-upload">
        <div className="row align-items-center">
          <div className="btn-none">
            <input type="file" style={{ cursor: 'pointer' }} className="input-file" onChange={handleUpload} />
            <Ball icon="upload" small bgColor="white" iconWidth="18" />
          </div>
          <div className="documents-upload-description">
            <p className="m-0">
              Fazer Upload
            </p>
            <p className="m-0">
              Tipos de arquivo aceitos: PDF e imagens (JPG e PNG)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

Document.propTypes = {
  sendDocumentData: PropTypes.func,
  document_type: PropTypes.string.isRequired,
};

Document.defaultProps = {
  sendDocumentData: () => { },
};

export default Document;
