import React, { Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { get } from "lodash";
import { API_URL } from "../../lib/const";
import { Card, Ball, InputDocumentFile } from "../elements";
import { Modal } from "../components";
import Button from "../v2/common/components/Button";

class Documents extends React.Component {
  state = {
    showModal: false,
    content: [],
    fileSrc: null,
  };

  showModal = (file) =>
    axios.get(`${API_URL}/uploads/${file.key}`).then((response) =>
      this.setState({
        showModal: true,
        content: file,
        fileSrc: get(response, "data.url", null),
      })
    );

  onClose = () => this.setState({ showModal: false });

  handleUpload = (event) => {
    const { onUpload } = this.props;
    const file = get(event, "target.files.0");
    if (file) {
      this.setState({ currentFile: file.name });
      onUpload(event).then(() => {
        this.setState({ currentFile: null });
      });
    } else {
      this.setState({ currentFile: null });
    }
  };

  render() {
    const { showModal, content, currentFile, fileSrc } = this.state;
    const {
      label,
      documents,
      onRemove,
      progress,
      error,
      is_required,
      equivalent,
    } = this.props;
    return (
      <Fragment>
        <div className="col-sm-12 col-md-6 mt-5">
          <Card error={error} title={label}>
            {documents.map((file) => (
              <InputDocumentFile
                key={file.name}
                name={file.name}
                showModal={() => this.showModal(file)}
                removeFile={() => onRemove(file.key)}
              />
            ))}
            {currentFile && (
              <InputDocumentFile name={currentFile} progress={progress} />
            )}
            {!is_required && (
              <Fragment>
                <p className="documents-non-required">
                  Documento não obrigatório
                </p>
              </Fragment>
            )}
            {documents.length === 0 && (
              <Fragment>
                <p className={`documents-none ${error && "red"}`}>
                  Nenhum documento anexado
                </p>
                {equivalent && (
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-black"
                      type="button"
                      style={{ width: "inherit" }}
                      onClick={() =>
                        this.props.onUseDocumentEquivalent(this.props.key)
                      }
                    >
                      Não possuo esse documento
                    </button>
                  </div>
                )}
              </Fragment>
            )}
            <div className="documents-upload">
              <div className="btn-none">
                <input
                  type="file"
                  style={{ cursor: "pointer" }}
                  className="input-file"
                  onChange={this.handleUpload}
                />
                <Ball icon="upload" small bgColor="white" iconWidth="18" />
              </div>
              <div className="documents-upload-description">
                <p>Fazer Upload</p>
                <p>Tipos de arquivo aceitos: PDF e imagens (JPG e PNG)</p>
              </div>
            </div>
          </Card>
        </div>
        <Modal
          visible={showModal}
          onClose={this.onClose}
          title={content.name}
          src={fileSrc}
        />
      </Fragment>
    );
  }
}

Documents.propTypes = {
  key: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  is_required: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.arrayOf(PropTypes.string),
  equivalent: PropTypes.shape({
    key: PropTypes.number,
    label: PropTypes.string,
  }),
  onUseDocumentEquivalent: PropTypes.func,
};

Documents.defaultProps = {
  is_required: true,
  equivalent: undefined,
  documents: [],
  error: undefined,
  onUseDocumentEquivalent: () => undefined,
};

export default Documents;
