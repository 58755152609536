import React from 'react';
import { BarChart } from 'd3plus-react';
import PropTypes from 'prop-types';
import StatusCard from './StatusCard';

class GeneralInformationDashboard extends React.Component {
  render() {
    const { data } = this.props;
    const totalStudents = data.reduce((acc, item) => acc + item.value, 0);
    const methods = {
      groupBy: 'id',
      data: data.map(total => ({
        id: total.name, x: total.name, y: total.value, color: total.color,
      })),
      size: d => d.value,
      color: d => d.color,
      legend: false,
      xConfig: ({
        ticks: [],
      }),
      tooltipConfig: {
        body: d => (d.y / totalStudents).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }),
      },
    };
    return (
      <div className="row">
        <div className="col-lg-4 col-md-6 col-xs-6 col-sm-12">
          {data.map(total => (
            <StatusCard key={total.name} status={total} />
          ))}
        </div>
        <div className="col-lg-8 col-md-6 col-xs-6 col-sm-12">
          <div className="height-300">
            <BarChart config={methods} />
          </div>
        </div>
      </div>
    );
  }
}

GeneralInformationDashboard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    color: PropTypes.string,
  })),
};

GeneralInformationDashboard.defaultProps = {
  data: [],
};

export default GeneralInformationDashboard;
