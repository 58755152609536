import React from 'react';
import PropTypes from 'prop-types';
import { PersonalDataStep, Documents } from '../../components';
import { FieldSet } from '../../elements';

const DocumentsDetailsForm = ({
  onSavePersonal,
  personalDocumentsTypes,
  onUpload,
  onRemoveDocument,
  getPersonalDocuments,
  onEdit,
  errors,
}) => (
  <>
    <PersonalDataStep
      isAcademic
      onClick={onSavePersonal}
      onEdit={onEdit}
    />
    <FieldSet title="DOCUMENTOS" />
    <div className="documents-base row">
      {personalDocumentsTypes.map(dt => (
        <Documents
          key={dt.id}
          label={dt.name}
          is_required={dt.is_required}
          onUpload={event => onUpload(event, dt.id)}
          onRemove={key => onRemoveDocument(key)}
          documents={getPersonalDocuments(dt.id)}
          error={errors[`documents${dt.id}`]}
          equivalent={dt.equivalent}
        />
      ))}
    </div>
  </>
);

DocumentsDetailsForm.propTypes = {
  onSavePersonal: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
  getPersonalDocuments: PropTypes.func.isRequired,
  personalDocumentsTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    is_required: PropTypes.bool,
  })),
  errors: PropTypes.objectOf(PropTypes.array),
};

DocumentsDetailsForm.defaultProps = {
  personalDocumentsTypes: [],
};

export default DocumentsDetailsForm;
