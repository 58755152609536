import React, { Fragment } from 'react';
import { Form } from 'informed';
import { FieldSet, InputCpf, InputYear, InputBirth, InputTypeText } from '../elements';

class Onboarding extends React.Component {
  constructor(props) {
    super(props);
    this.insertAluno = this.insertAluno.bind(this);
  }
  state = {
    alunos: [],
  }

  insertAluno() {
    alert('Aluno Adicionado')
  }

  render() {
    const { alunos } = this.state;

    return(
      <div className="container" style={{height: '80vh'}}>
        <div className="px-2 pt-3">
          <h4>Período de Matrícula</h4>
          <Form>
            <FieldSet title="Configuração de Período">
              <InputYear field="year" label="Conclusão do ensino médio" col="3" helper="Ex: 2014" />
              <InputTypeText field="semester" label="Semestre" col="3" />
              <InputBirth field="initialDate" label="Data de Início" col="3" />
              <InputBirth field="finalDate" label="Data de Fim" col="3" />
            </FieldSet>
            <FieldSet title="Alunos">
            {
              alunos.length > 0 ? (
                alunos.map(aluno => (
                  <Fragment key={aluno.id}>
                    <InputTypeText field="name" label="Nome" helper="Nome completo" col="4" />
                    <InputTypeText field="email" label="E-mail" col="4" />
                    <InputCpf field="cpf" label="CPF" col="4" />
                  </Fragment>
                ))
                ) : (
                  <div className="col-12 p-0">
                    <div className="tag-null">
                      Nenhum item! Clique em 'inserir' para adicionar um novo.
                    </div>
                  </div>
              )
            }
            </FieldSet>
            <button className="btn btn-primary mb-3" onClick={this.insertAluno}>INSERIR</button>
            <div className="float-right">
              <button className="btn btn-primary">SALVAR</button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default Onboarding;
