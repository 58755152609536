import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Form } from 'informed';
import { get } from 'lodash';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAxiosConfig } from '../../lib/auth';
import { CONTRACT_STATUS, ENROLLMENT_STATUS } from '../../lib/config';
import { API_URL } from '../../lib/const';
import { Modal } from '../components';
import ListBoleto from '../components/boleto/ListBoleto';
import ComponentWithToken from '../components/CompontentWithToken';
import ConfirmationModal from '../components/ConfirmationModal';
import ContractMonitor from '../components/ContractMonitor';
import DownloadButton from '../components/DownloadButton';
import { FieldSet, InputTypeArea, Spinner } from '../elements';
import { approveFinanceData, sendContract } from '../services/approval_services';
import { generateBoleto } from '../services/finance_data_service';
import Button from '../v2/common/components/Button';
import { MessageContext } from '../v2/common/states/MessageState';
import BaseEnrollmentSummary from './BaseEnrollmentSummary';

class EnrollmentSummaryFinancer extends ComponentWithToken {
  state = {
    loading: true,
    data: undefined,
    financeData: undefined,
    fileSrc: null,
    content: [],
    contract: undefined,
    fields: {},
    options: {},
    resubmittingInvoice: false,
    resubmittingContract: false,
    showResubmit: false,
    shouldApproveStudent: false,
    showConfirmationModal: false,
    showConfirmationFinishModal: false,
    invoices: [],
    boleto_id: null,
    submittingBoleto: false,
    showResolvePendencyModal: false,
    selectedPendencyId: null,
    updatingPendencies: false,
    complementaryBoletos: [],
    flowPermissions: {},
  }

  componentDidMount() {
    Promise.all([
      this.fetchData(),
      this.fetchFinanceData(),
      this.fetComplementaryBoletos(),
      this.fetchFlowPermissions(),
    ]).then(() => this.setState({ loading: false }));
  }

  fetComplementaryBoletos = () => {
    axios.get(`${API_URL}/onboarding/complementary-boletos/${this.token}`)
      .then(response => this.setState({ complementaryBoletos: response.data }));
  }

  fetchData = () => {
    const { getData } = this.props;
    axios.get(`${API_URL}/onboarding/personal-data/${this.token}/summary`)
      .then((response) => {
        const { data } = response;
        this.setState({
          data,
          loading: false,
          options: {
            autoSave: get(data, 'enrollment.status.id') === ENROLLMENT_STATUS.REVIEWING,
            forwardedInvoice: get(data, 'enrollment.status.id') === ENROLLMENT_STATUS.WAITING_PAYMENT,
            show: ![ENROLLMENT_STATUS.FILLING, ENROLLMENT_STATUS.ACCESSED, ENROLLMENT_STATUS.NOT_STARTED].includes(get(data, 'enrollment.status.id')),
            disabled: [ENROLLMENT_STATUS.PAYMENT_REVIEWING, ENROLLMENT_STATUS.FINALIZED].includes(get(data, 'enrollment.status.id')),
          },
          showResubmit: get(data, 'enrollment.status.id') !== ENROLLMENT_STATUS.FINALIZED,
          shouldApproveStudent: get(data, 'enrollment.contract_status.id') === CONTRACT_STATUS.DONE,
        });
        this.fetchContractDetails();
        getData(data.enrollment.id, data.enrollment.status.id);
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  fetchFlowPermissions = async () => {
    const response = await axios.get(`${API_URL}/flow/${this.token}/permissions`);
    this.setState({ flowPermissions: response.data });
  }

  fetchFinanceData() {
    axios.get(`${API_URL}/finance/finance-data/${this.token}/summary`)
      .then(response => this.setState({ invoice: get(response.data, 'invoice'), invoices: get(response.data, 'invoices'), boleto_id: get(response.data, 'boleto_id') }));
  }

  fetchContractDetails = () => {
    axios.get(`${API_URL}/contracts/enrollment/${this.token}`, getAxiosConfig())
      .then((response) => {
        this.setState({ contract: response.data });
      });
  }

  resubmitContract = () => {
    this.setState({ resubmittingContract: true });
    sendContract(this.token).finally(() => this.setState({ resubmittingContract: false }, this.componentDidMount));
  }

  handleConfirmationFinishModal = () => {
    const { showConfirmationFinishModal } = this.state;
    this.setState({ showConfirmationFinishModal: !showConfirmationFinishModal });
  }

  handleConfirmationModal = () => {
    const { showConfirmationModal } = this.state;
    this.setState({ showConfirmationModal: !showConfirmationModal });
  }

  handleApprove = () => {
    this.setState({ loading: true, showConfirmationModal: false });
    approveFinanceData(this.token).then(() => this.componentDidMount())
      .catch(() => this.setState({ loading: false }));
  }

  handleFinish = () => {
    this.setState({ loading: true, showConfirmationFinishModal: false });
    axios.post(`${API_URL}/flow/${this.token}/action/finish_enrollment`)
      .then(() => {
        toast.success('Processo de matrícula aprovado', { position: toast.POSITION.BOTTOM_RIGHT });
        this.componentDidMount();
      })
      .catch((error) => {
        toast.error(get(error, 'response.data.detail', 'Ocorreu um erro inesperado'), { position: toast.POSITION.BOTTOM_RIGHT });
        this.setState({
          loading: false,
        });
      });
  }

  generateBoleto = () => {
    this.setState({ submittingBoleto: false });
    generateBoleto(this.token).then((d) => {
      this.setState({ submittingBoleto: false, boleto_id: d.boleto_id });
      toast.success('Boleto gerado com sucesso!');
    });
  }

  showModal = (file) => {
    axios.get(`${API_URL}/uploads/${file.key}`)
      .then((response) => {
        this.setState({
          showModal: true,
          content: file,
          fileSrc: get(response, 'data.url', null),
        });
      });
  }

  onClose = () => {
    this.setState({
      showModal: false,
    });
  }

  handleJustifyPendency() {
    const { form, selectedPendencyId } = this.state;
    const { reloadPendencies } = this.props;
    this.setState({
      updatingPendencies: true,
      showResolvePendencyModal: false,
    })
    axios.put(`${API_URL}/onboarding/pendencies/${selectedPendencyId}/justify`, form)
      .then(response => {
        this.setState({ updatingPendencies: false });
        reloadPendencies();
      })
      .catch(error => {
        toast.error(get(error, 'response.data.justification[0]', 'Ocorreu um erro inesperado'), { position: toast.POSITION.BOTTOM_RIGHT });
        this.setState({ updatingPendencies: false });
      })
  }

  renderPendencies() {
    const { pendencies, loadingPendencies } = this.props;
    const { updatingPendencies } = this.state;
    const showResolveForm = p => !p.resolved_at && p.financers_can_resolve

    if (loadingPendencies || updatingPendencies) {
      return <div className="text-center"><FontAwesomeIcon className="green" icon="spinner" pulse /></div>;
    }
    return pendencies.map(p => (
      <div className="pendencies">
        <div className="pendencies-header">
          {p.resolved_at && <FontAwesomeIcon icon="check-circle" className="green mt-1 mr-2" />}
          <div className="mb-2">
            <label className="pendencies-header-title">Pendência {p.id}:</label>
            <small className="d-block "><em>{p.step}</em></small>
          </div>
        </div>
        <div className="pendencies-body">
          {p.description}
        </div>
        <strong><pre>{p.justification}</pre></strong>
        {showResolveForm(p) && (
          <button
            type="button"
            className="btn btn-primary w-25"
            onClick={() => this.setState({
              selectedPendencyId: p.id,
              showResolvePendencyModal: true,
            })}
          >
            Justificar Pendência
          </button>
        )}
        <div className="pendencies-date">{p.created_at}</div>
      </div>
    ));
  }


  renderPreenchendo() {
    return (
      <>
        <FieldSet title="Em Andamento" />
        <div className="col-sm-12 col-md-6 mt-2">
          <p>O aluno ainda não enviou o formulário.</p>
        </div>
      </>
    );
  }

  renderPendencias() {
    const { pendencies } = this.props;
    const checkNonResolvedPendencies = pendencies.filter(f => !f.resolved_at).length > 0;
    const checkPendencies = pendencies.length > 0;
    if (checkNonResolvedPendencies) {
      return (
        <>
          <FieldSet title="Em Andamento" />
          <div className="col-sm-12 col-md-6 mt-2">
            <p>O aluno ainda não resolveu as pendências.</p>
          </div>
          {this.renderPendencies()}
        </>
      );
    } if (checkPendencies) {
      return (
        <>
          <FieldSet title="Pendências" />
          {this.renderPendencies()}
        </>
      );
    } return null;
  }

  onSendPendencies = () => {
    const { handle } = this.props;
    handle()
      .then(() => this.componentDidMount());
  }

  render() {
    const {
      loading,
      data,
      showModal,
      content,
      fileSrc,
      resubmittingContract,
      contract,
      showResubmit,
      showConfirmationModal,
      showConfirmationFinishModal,
      boleto_id,
      submittingBoleto,
      showResolvePendencyModal,
      complementaryBoletos,
      flowPermissions,
    } = this.state;

    const {
      pendencies,
      hasPendencies,
      submittingPendencies,
      onDocumentApproval,
      onDocumentReproval

    } = this.props;
    const enrollmentStatus = get(data, 'enrollment.status.id', 1);
    const regularStudent = !get(data, 'full_fies_or_prouni', false);
    return (
      <>
        <Spinner active={loading}>
          <BaseEnrollmentSummary
            token={this.token}
            onDocumentApproval={onDocumentApproval}
            onDocumentReproval={onDocumentReproval}
          />
          <ContractMonitor
            token={this.token}
            contract={contract}
            resubmitting={resubmittingContract}
            onResubmit={this.resubmitContract}
            showResubmit={showResubmit}
          />
          <Modal visible={showModal} onClose={this.onClose} title={content.name} src={fileSrc} />
          {enrollmentStatus === ENROLLMENT_STATUS.FILLING && this.renderPreenchendo()}
          {(enrollmentStatus === ENROLLMENT_STATUS.REVIEWING || enrollmentStatus === ENROLLMENT_STATUS.PAYMENT_REVIEWING || enrollmentStatus === ENROLLMENT_STATUS.WAITING_PAYMENT) && (
            <>
              {(regularStudent && boleto_id) && (<ListBoleto boleto={boleto_id} showResubmit resubmitting={submittingBoleto} onResubmit={this.generateBoleto} complementaryBoletos={complementaryBoletos} />)}
              {(regularStudent && boleto_id) && (
                <>
                  <FieldSet title="Comprovante de Pagamento" />
                  <DownloadButton href={`${API_URL}/finance/finance-data/${this.token}/download/proofs_of_payment`} label="Baixar comprovante(s) de pagamento" />
                </>
              )}
              {this.renderPendencias()}
              <hr />
              <div className="text-right" style={{ marginBottom: '2.5rem' }}>
                <div className="mt-5" style={{ display: 'flex' }}>
                  <div className="col-md-4 p-0 offset-md-8 col-sm-12">
                    <div className="btn-actions">
                      {
                        (pendencies.filter(f => !f.resolved_at).length > 0 || hasPendencies) && (
                          <Button
                            disabled={get(data, 'enrollment.status.id') === 3 || submittingPendencies}
                            loading={submittingPendencies}
                            onClick={this.onSendPendencies}
                          >
                            Enviar todas pendências
                          </Button>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {enrollmentStatus === ENROLLMENT_STATUS.PENDING && (
            <>
              {this.renderPendencias()}
              <hr />
            </>
          )}
          {enrollmentStatus === ENROLLMENT_STATUS.FINALIZED && (
            <>
              {regularStudent && (
                <>
                  <FieldSet title="Comprovante de Pagamento" />
                  <DownloadButton href={`${API_URL}/finance/finance-data/${this.token}/download/proofs_of_payment`} label="Baixar comprovante(s) de pagamento" />
                </>
              )}
              <>
                <FieldSet title="Contrato" />
                <DownloadButton label="Baixar Contrato" href={`${API_URL}/contracts/enrollment/${this.token}/contract`} />
              </>
            </>
          )}
          <div className="row d-flex justify-content-end">
            <div className="col-md-3 p-0 col-sm-12">
              <Link className="btn btn-primary" to="/financers"> Voltar</Link>
            </div>
            {
              get(flowPermissions, 'can_approve_finance_data') && (
                <div className="col-md-4 p-0 col-sm-12 ml-1">
                  <Button
                    disabled={loading}
                    loading={loading}
                    onClick={this.handleConfirmationModal}
                  >
                    Aprovar
                  </Button>
                </div>
              )
            }
            {
              get(flowPermissions, 'can_finish_enrollment') && (
                <div className="col-md-4 p-0 col-sm-12 ml-1">
                  <Button
                    disabled={loading}
                    loading={loading}
                    onClick={this.handleConfirmationFinishModal}
                  >
                    Concluir processo
                  </Button>
                </div>
              )
            }
          </div>
        </Spinner>
        <ConfirmationModal
          visible={showConfirmationModal}
          title="Confirmação"
          onCancel={() => this.handleConfirmationModal()}
          onConfirm={() => this.handleApprove()}
        >
          <MessageContext.Consumer>
            {
              ({ getMessage }) => (
                <>
                  {getMessage('APPROVE_ENROLLMENT') || 'Você tem certeza que deseja aprovar o aluno? Se sim, o contrato e o boleto, se necessário, serão enviados para o aluno e seus fiadores.?'}
                </>
              )
            }
          </MessageContext.Consumer>
        </ConfirmationModal>
        <ConfirmationModal
          visible={showConfirmationFinishModal}
          title="Confirmação"
          onCancel={() => this.handleConfirmationFinishModal()}
          onConfirm={() => this.handleFinish()}
        >
          Você tem certeza que deseja concluir o processo de matrícula do aluno?
        </ConfirmationModal>
        <ConfirmationModal
          visible={showResolvePendencyModal}
          title="Justificar Pendência"
          onCancel={() => this.setState({ showResolvePendencyModal: false })}
          onConfirm={() => this.handleJustifyPendency()}
          cancelText="Cancelar"
          confirmText="Justificar"
        >
          <p>Ao justificar uma pendência o processo de rematrícula do aluno poderá continuar.</p>
          <Form onValueChange={form => this.setState({ form })}>
            <InputTypeArea className="p0" field="justification" label="Justificativa" row="4" />
          </Form>
        </ConfirmationModal>
      </>
    );
  }
}

export default withRouter(EnrollmentSummaryFinancer);
