import React from 'react';
import PropTypes from 'prop-types';
import BooleanIcon from '../BooleanIcon';
import Table from '../Table';


const ColumnRules = ({ rules }) => (
  <Table
    columns={[
      {
        accessor: 'name',
        Header: 'Coluna',
        width: 200,
        className: 'text-left',
      },
      {
        accessor: 'required',
        Header: 'Obrigatório',
        width: 120,
        Cell: row => (
          <div className="text-center">
            <BooleanIcon value={row.value} />
          </div>
        ),
      },
      {
        accessor: 'options',
        Header: 'Descrição',
        Cell: row => (Array.isArray(row.value) ? row.value.join(', ') : 'N/A'),
        className: 'text-left',

      },
    ]}
    data={rules}
    sortable={false}
    showPagination={false}
    showPageSizeOptions={false}
    defaultPageSize={rules.length}
  />
);

ColumnRules.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    required: PropTypes.bool,
    options: PropTypes.oneOfType(PropTypes.arrayOf(PropTypes.string)),
  })).isRequired,
};

export default ColumnRules;
