import React from 'react';
import { Link } from 'react-router-dom';
import { fetchFaq } from '../services/faq_service';
import ComponentWithToken from '../components/CompontentWithToken';

class Faq extends ComponentWithToken {
  state = {
    data: [],
    loading: true,
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    fetchFaq()
      .then(response => this.setState({
        data: response,
        loading: false,
      }))
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  renderFaq = () => {
    const { data, loading } = this.state;

    if (data.length > 0) {
      return (
        data.map(d => (
          <div className="faq-card-content" key={d.id}>
            <strong className="faq-card-question">{d.name}</strong>
            <div className="faq-card-answer">{d.answer}</div>
          </div>
        ))
      );
    }

    if (loading) {
      return <small>Carregando FAQ...</small>;
    }

    return (
      <p className="m-0">Não foi possível carregar lista de perguntas.</p>
    );
  }

  render() {
    return (
      <section className="container">
        <div className="list-base-width faq-card-container mt-5">
          <div className="faq-card-header">
            <div className="d-flex align-items-center">
              <strong className="m-0 mr-3 faq-title">FAQ</strong>
              <hr className="w-100" />
            </div>
            <small className="font-">Veja as perguntas mais frequentes feitas pelo candidatos sobre o processo de matrícula:</small>
          </div>
          <div className="faq-card-body">
            {this.renderFaq()}
          </div>
          <div className="faq-card-header pt-4">
            <div className="col-md-4 p-0 col-sm-12">
              <Link to={`/inscrever/${this.token}`} className="btn btn-primary">
                Voltar
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Faq;
