import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FieldSet, KeyValue } from '../../elements';
import DocumentsView from './DocumentsView';

const HealthData = ({
  healthData,
  vaccineDocuments,
  vaccineDocumentsTypes,
}) => (
  <>
    <FieldSet title="Ficha de saúde" />
    <div className="enrollment-flex mb-3">
      <KeyValue title={get(healthData, 'hospital')} label="Hospital Encaminhado" />
      <KeyValue title={get(healthData, 'health_insurrance')} label="Plano de Saúde" />
      <KeyValue title={get(healthData, 'blood_type_name')} label="Tipo Sanguíneo" />
      <KeyValue title={get(healthData, 'health_insurrance_phone')} label="Telefone do plano de saúde" />
      <KeyValue title={get(healthData, 'health_insurrance_enrollment')} label="Matrícula do plano de saúde" />
    </div>
    {
      get(healthData, 'contacts', []).length > 0 && (
        <>
          <FieldSet title="Contatos" />
          {
            get(healthData, 'contacts', []).map(contact => (
              <div key={contact.id} className="enrollment-flex mb-3">
                <KeyValue title={get(contact, 'name')} label="Nome" />
                <KeyValue title={get(contact, 'landline_number')} label="Telefone" />
                <KeyValue title={get(contact, 'relationship')} label="Parentesco" />
                <KeyValue title={get(contact, 'phone_number')} label="Celular" />
              </div>
            ))
          }
        </>
      )
    }
    {
      get(healthData, 'vaccines', []).length > 0 && (
        <>
          <FieldSet title="Vacinas" />
          {
            get(healthData, 'vaccines', []).map(vaccine => (
              <div key={vaccine.id} className="enrollment-flex mb-3">
                <KeyValue title={get(vaccine, 'health_vaccine.name')} label="Vacina" />
                {
                  get(vaccine, 'vaccines_doses', []).map(dose => (
                    <KeyValue title={get(dose, 'date')} label="Data dose" />
                  ))
                }
                <KeyValue title={get(vaccine, 'observation')} label="Observação" />
                <KeyValue title={get(vaccine, 'expiration_date')} label="Data validade" />
                {
                  get(vaccine, 'has_exam') && (
                    <>
                      <KeyValue title="Sim" label="Exame soroconversão" />
                      <KeyValue title={get(vaccine, 'exam_results')} label="Resultado do exame" />
                    </>
                  )
                }
              </div>
            ))
          }
        </>
      )
    }
    <FieldSet title="Questionários" />
    {
      get(healthData, 'questions', []).map(question => (
        <Fragment key={question.id}>
          <div className="enrollment-flex mb-3">
            <KeyValue title={get(question, 'health_question.description')} label="Questão" />
          </div>
          <div className="enrollment-flex">
            <KeyValue title={get(question, 'answer') ? 'Sim' : 'Não'} label="Resposta" />
            <KeyValue title={get(question, 'observation')} label="Observação" />
          </div>
        </Fragment>
      ))
    }
    {
      vaccineDocuments.length > 0 && (
        <>
          <FieldSet title="Documentos das vacinas" />
          <div className="documents-base row">
            <DocumentsView
              personalDocuments={vaccineDocuments}
              personalDocumentsTypes={vaccineDocumentsTypes}
            />
          </div>
        </>
      )
    }
  </>
);

HealthData.propTypes = {
  healthData: PropTypes.shape({
    hospital: PropTypes.string,
    health_insurrance: PropTypes.string,
    blood_type: PropTypes.number,
    health_insurrance_phone: PropTypes.string,
    health_insurrance_enrollment: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      landline_number: PropTypes.string,
      relationship: PropTypes.string,
      phone_number: PropTypes.string,
    })),
    vaccines: PropTypes.arrayOf(PropTypes.shape({
      health_vaccine: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      date: PropTypes.string,
      observation: PropTypes.string,
      expiration_date: PropTypes.string,
      has_exam: PropTypes.bool,
      exam_results: PropTypes.string,
    })),
    questions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      health_question: PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
      }),
      answer: PropTypes.bool,
      observation: PropTypes.string,
    })),
  }),
  vaccineDocuments: PropTypes.arrayOf(PropTypes.object),
  vaccineDocumentsTypes: PropTypes.arrayOf(PropTypes.object),
};

HealthData.defaultProps = {
  healthData: [],
  vaccineDocuments: [],
  vaccineDocumentsTypes: [],
};

export default HealthData;
