import React from 'react';
import { Checkbox, asField } from 'informed';


export default asField(({
  disabled, col, field, label, ...props
}) => (
  <div className={`col-md-${col} col-sm-12`}>
    <label className="input-check-type">{label}
      <Checkbox className="checkbox" field={field} id={field} {...props} />
      <span className="checkmark" />
    </label>
  </div>
));
