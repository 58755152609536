import React from 'react';
import { withFormApi } from 'informed';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { FieldSet, InputTypeText } from '../elements';
import { Document } from '.';
import { handleDocumentUpload } from '../services/upload_service';
import { DOCUMENT_TYPE } from '../../lib/config';

class LetterGuaranteeForm extends React.Component {
  state = {
    uploadProgress: 0,
  }

  onUploadProgress = (progressEvent) => {
    if (progressEvent) {
      const { loaded, total } = progressEvent;
      this.setState({
        uploadProgress: total ? Math.floor(100 * loaded / total) : 0,
      });
    }
  }

  handleRemove = () => {
    const { formApi } = this.props;
    const { values } = formApi.getState();

    values.letter_guarantee = undefined;
    formApi.setValues({ ...values });
  }

  handleUpload = async (event) => {
    const file = get(event, 'target.files.0');
    const response = await handleDocumentUpload(file, DOCUMENT_TYPE.LETTER_GUARANTEE, { onUploadProgress: this.onUploadProgress });
    const { formApi } = this.props;
    const { values } = formApi.getState();

    const { key } = response;
    values.letter_guarantee = {
      key,
      name: file.name,
      document_type: DOCUMENT_TYPE.LETTER_GUARANTEE,
    };
    formApi.setValues({ ...values });
  }

  render() {
    const { formApi } = this.props;
    const { uploadProgress } = this.state;
    return (
      <>
        <FieldSet title="Carta Fiança" />
        <div className="col-md-5 col-sm-12 mt-3">
          <InputTypeText field="bank_name" label="Informe o nome do Banco" col="12" required />
        </div>
        <div className="documents-base row">
          <Document
            label="Carta Fiança"
            documento={formApi.getValue('letter_guarantee')}
            onUpload={this.handleUpload}
            onRemove={this.handleRemove}
            progress={uploadProgress}
          />
        </div>
      </>
    );
  }
}

LetterGuaranteeForm.propTypes = {
  formApi: PropTypes.shape({
    setValue: PropTypes.func,
    getValue: PropTypes.func,
  }).isRequired,
};

export default withFormApi(LetterGuaranteeForm);
