import React from 'react';
import ImportsBase from './ImportsBase';
import Card from '../../components/Card';
import ImportDadosPessoaisColumnRules from '../../components/imports/ImportDadosPessoaisColumnRules';


class ImportsPage extends React.Component {
  get data() {
    return [
      {
        id: 1,
        endpoint: '/personal/import-personal-data',
        title: 'Dados pessoais',
        ruleset: <ImportDadosPessoaisColumnRules />,
      },
    ];
  }

  render() {
    return (
      <Card title="Importação de dados">
        <ImportsBase data={this.data} />
      </Card>
    );
  }
}

export default ImportsPage;
