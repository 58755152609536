import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardCourses from './DashboardCourses';
import GeneralInformationDashboard from './GeneralInformationDashboard';

class Dashboard extends React.Component {
  render() {
    const {
      loading, generalInformation, courses, onUpdate, filterData, enrollmentsByStatus, fetchEnrollmentsByStatus
    } = this.props;

    return (
      <div>
        <div className="card m-3">
          <div className="card-header d-flex justify-content-between">
            <p className="m-0">Percentual de candidatos com o processo concluído ({generalInformation.percentage_finished_students}%)</p>
            <p className="m-0">{generalInformation.finished_students} / {generalInformation.total_students} candidatos</p>
            <p className="m-0">
              <span className="mr-4">{generalInformation.retrieve_date}</span>
              <button type="button" onClick={onUpdate} className="btn-no-style">
                <FontAwesomeIcon icon="sync-alt" spin={loading} />
              </button>
            </p>
          </div>
          <div className="card-body">
            {loading ? <Skeleton width="100%" height={400} /> : <GeneralInformationDashboard data={generalInformation.totals} />}
            {loading ?
              <Skeleton width="100%" height={200} />
              :
              <DashboardCourses
                courses={courses}
                filterData={filterData}
                enrollmentsByStatus={enrollmentsByStatus}
                fetchEnrollmentsByStatus={fetchEnrollmentsByStatus}
              />}
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  loading: PropTypes.bool.isRequired,
  generalInformation: PropTypes.shape({
    total_students: PropTypes.number,
    finished_students: PropTypes.number,
    percentage_finished_students: PropTypes.string,
    retrieve_date: PropTypes.string,
    totals: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    })),
  }),
  courses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    totals: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    })),
  })),
  onUpdate: PropTypes.func,
  fetchEnrollmentsByStatus:PropTypes.func
};

Dashboard.defaultProps = {
  onUpdate: () => undefined,
  fetchEnrollmentsByStatus: () => undefined,
  generalInformation: {},
  courses: [],
};

export default Dashboard;
