import React from 'react';
import { get } from 'lodash';
import { Document, Page, pdfjs } from 'react-pdf';
import { withRouter } from 'react-router-dom';
import ComponentWithToken from './CompontentWithToken';
import { fetchStudent } from '../services/enrollment_services';
import approveNotice from '../services/notice_services';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class NoticeStep extends ComponentWithToken {
  state = {
    student: {},
    loading: true,
  }

  componentDidMount() {
    this.fetchStudent();
  }

  onClick = () => {
    const { onClick } = this.props;
    approveNotice(this.token).then(() => onClick());
  }

  fetchStudent = () => {
    fetchStudent(this.token)
      .then((response) => {
        this.setState({ student: response, loading: false });
      });
  }

  render() {
    const { student } = this.state;

    return (
      <>
        <Document
          file={get(student, 'onboarding.notice_url')}
          error="Não foi possível carregar o PDF."
          loading="Carregando..."
          noData="Não existe PDF."
          className="mb-5 pdf-viewer"
        >
          <Page pageNumber={1} />
        </Document>
        <div className="d-flex justify-content-end responsive-button-container">
          <button type="button" className="btn btn-primary col-3" onClick={this.onClick}>Aceitar e continuar</button>
        </div>
      </>
    );
  }
}

export default withRouter(NoticeStep);
