import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../../lib/const';


export const fetchInformation = () => (
  axios.get(`${API_URL}/dashboard/general-information`)
    .then(response => response.data)
    .catch((e) => {
      toast.error('Ocorreu um erro inesperado, tente novamente mais tarde');
      return Promise.reject(e);
    })
);

export const fetchCourses = () => (
  axios.get(`${API_URL}/dashboard/courses`)
    .then(response => response.data)
    .catch((e) => {
      toast.error('Ocorreu um erro inesperado, tente novamente mais tarde');
      return Promise.reject(e);
    })
);

export const fetchFilterData = () => (
  axios.get(`${API_URL}/dashboard/chart-filters`)
    .then(response => response.data)
    .catch((e) => {
      toast.error('Ocorreu um erro inesperado, tente novamente mais tarde');
      return Promise.reject(e);
    })
);


export const fetchEnrollmentsByStatus = (params) => {
  console.log(params)
  return axios.get(`${API_URL}/dashboard/enrollments-status`, {params})
    .then(response => response.data)
    .catch((e) => {
      toast.error('Ocorreu um erro inesperado, tente novamente mais tarde');
      return Promise.reject(e);
    })
}
