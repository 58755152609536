import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Scope } from 'informed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  InputTypeDropdown,
  InputBirth,
  InputTypeArea,
  InputTypeText,
} from '../elements';
import RadioButtonsAnswer from '../elements/RadioButtonsAnswer';
import Document from '../components/common/upload/Document';

const HealthVaccines = ({
  vaccinesOptions,
  vaccineIndex,
  onRemoveVaccines,
  vaccine,
  sendDocumentData,
  onRemoveFile,
}) => {
  const [showExamResults, setShowExamResults] = useState(false);
  const [expirationDates, setExpirationDates] = useState([]);

  const handleValueChange = (value) => {
    if (value) {
      setShowExamResults(value);
    } else {
      setShowExamResults(false);
    }
  };

  const handleVaccineExpirationDate = (value) => {
    const selectedVaccine = vaccinesOptions.find(v => v.id === +value);
    if (selectedVaccine) {
      const doses = Array(selectedVaccine.doses).fill().map((_, i) => i);
      setExpirationDates(doses);
    }
  };

  useEffect(() => {
    if (get(vaccine, 'health_vaccine')) {
      handleVaccineExpirationDate(vaccine.health_vaccine.id);
    }
    setShowExamResults(vaccine.has_exam);
  }, [vaccine]);

  const document_type = 23;

  return (
    <>
      <div className="row">
        <InputTypeDropdown
          col="6"
          field="health_vaccine"
          label="Vacina"
          onValueChange={handleVaccineExpirationDate}
          options={vaccinesOptions}
        />
        <button
          type="button"
          className="btn-none"
          onClick={() => onRemoveVaccines(vaccineIndex)}
        >
          <div className="navigation-notification-style">
            <FontAwesomeIcon icon="times-circle" />
          </div>
        </button>
      </div>
      <div className="row">
        {
          expirationDates.map((expirationDate, index) => (
            <Scope scope={`vaccines_doses[${index}]`}>
              <InputBirth
                key={expirationDate}
                col="4"
                label="Data dose"
                field="date"
              />
            </Scope>
          ))
        }
        <InputBirth
          col="4"
          label="Data validade"
          field="expiration_date"
        />
        <InputTypeArea
          col="12"
          label="Observação"
          field="observation"
        />
        <RadioButtonsAnswer
          field="has_exam"
          label="Não possui essa vacina no cartão de vacinação? Fez o exame de soro conversão?"
          onValueChange={handleValueChange}
        />
        {
          showExamResults && (
            <>
              <InputTypeText
                col="12"
                label="Resultado do exame"
                field="exam_result"
              />
              <div className="ml-2">
                <Document
                  document_type={document_type}
                  sendDocumentData={fileData => sendDocumentData(fileData ? ({ ...fileData, vaccine_index: vaccineIndex }) : null)}
                  documentData={vaccine.exam}
                  onRemoveFile={() => onRemoveFile(vaccineIndex)}
                />
              </div>
            </>
          )
        }
      </div>
    </>
  );
};

HealthVaccines.propTypes = {
  vaccinesOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    health_vaccine: {
      id: PropTypes.number,
      name: PropTypes.string,
      doses: PropTypes.number,
    },
    health_data: PropTypes.number,
    expiration_date: PropTypes.instanceOf(Date),
    observation: PropTypes.string,
    has_exam: PropTypes.bool,
    exam_result: PropTypes.string,
    vaccines_doses: [
      {
        id: PropTypes.number,
        date: PropTypes.instanceOf(Date),
      },
      {
        id: PropTypes.number,
        date: PropTypes.instanceOf(Date),
      },
    ],
    doses_count: PropTypes.number,
  })),
  vaccineIndex: PropTypes.number,
  onRemoveVaccines: PropTypes.func,
  vaccine: PropTypes.shape({
    id: PropTypes.number,
    health_vaccine: {
      id: PropTypes.number,
      name: PropTypes.string,
      doses: PropTypes.number,
    },
    health_data: PropTypes.number,
    expiration_date: PropTypes.instanceOf(Date),
    observation: PropTypes.string,
    has_exam: PropTypes.bool,
    exam_result: PropTypes.string,
    vaccines_doses: [
      {
        id: PropTypes.number,
        date: PropTypes.instanceOf(Date),
      },
      {
        id: PropTypes.number,
        date: PropTypes.instanceOf(Date),
      },
    ],
    doses_count: PropTypes.number,
  }),
  sendDocumentData: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

HealthVaccines.defaultProps = {
  vaccinesOptions: [],
  vaccineIndex: 0,
  onRemoveVaccines: () => {},
  sendDocumentData: () => {},
  onRemoveFile: () => {},
  vaccine: {},
};

export default HealthVaccines;
