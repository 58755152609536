import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Form } from 'informed';
import { InputTypeDropdown, InputTypeText, FieldSet } from '../../elements';
import EmailRecipientsAsync from '../../components/EmailTask/InputAsyncRecipients';
import createEmailBatch from '../../services/EmailTask/create_service';


const CreateEmailTaskPage = ({ history }) => {
  const [form, setForm] = useState(undefined);
  const [submitting, setSubmitting] = useState(false);
  const [formApi, setFormApi] = useState(null);
  let previousErrors = {};

  const EMAIL_TYPES = [
    {
      label: 'Convocação',
      value: 'INVITE',
    },
  ];

  const handleCreateEmailBatch = () => {
    setSubmitting(true);
    createEmailBatch({
      ...form,
      recipients: get(form, 'recipients', []).map(r => r.value),
    }).then(() => history.push('/emails')).catch((error) => {
      toast.error('Erro ao criar um lote de emails');
      const status = get(error, 'response.status');
      if (status === 400) {
        const errors = get(error, 'response.data');
        Object.keys(previousErrors).forEach((fieldName) => {
          if (formApi.fieldExists(fieldName)) {
            formApi.setError(fieldName, null);
          }
        });

        previousErrors = { ...errors };

        Object.entries(errors).forEach((e) => {
          if (formApi.fieldExists(e[0])) {
            formApi.setError(e[0], e[1]);
          }
        });
      }
    });
  };

  return (
    <div className="step-content mt-5">
      <div className="step-content-slot">
        <h3>Novo lote de Emails</h3>
        <Form
          getApi={(api) => { setFormApi(api); }}
          onValueChange={formValues => setForm(formValues)}
        >
          <FieldSet>
            <InputTypeText field="name" label="Descrição" col="8" required />
            <InputTypeDropdown options={EMAIL_TYPES} field="type" label="Tipo" col="4" required />
            <EmailRecipientsAsync isMulti />
          </FieldSet>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-black w-25 mr-1"
              onClick={() => history.push('/emails')}
            >
              Cancelar
            </button>
            <button
              type="button"
              disable={submitting}
              className="btn btn-primary w-25"
              onClick={handleCreateEmailBatch}
            >
              Criar
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};


CreateEmailTaskPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};


export default withRouter(CreateEmailTaskPage);
