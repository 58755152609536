import React from 'react';
import MaskedInput from 'react-text-mask';
import { asField } from 'informed';

export default asField(({
  required, fieldApi, className, helper, fieldState, field, label, col, helpBlock, mask_, autosaveFeedBack, ...props
}) => (
  <div className={`col-md-${col} col-sm-12`} style={{ display: 'flex' }}>
    <div className="input-type-style" style={{ display: 'flex' }}>
      <MaskedInput
        mask={mask_}
        className={fieldState.error ? 'input-error' : 'input-style'}
        value={fieldState.value || ''}
        onBlur={(e) => {
          fieldApi.setValue(e.target.value);
        }}
        onChange={(e) => {
          fieldApi.setValue(e.target.value);
        }}
        {...props}
      />
      {helper && <label className="input-helper">{helper}</label>}
      {fieldState.error && fieldState.error.map(e => <label key={e} className="input-helper text-danger">{e}</label>)}
      <label
        htmlFor={field}
        className={`input-label-style ${required && 'required'}  ${fieldState.error && 'label-error'}`}
      >
        {label}
      </label>
    </div>
    <div style={{ display: 'flex', marginTop: '40px' }}>
      {autosaveFeedBack}
    </div>
  </div>
));
