import React, { Fragment } from 'react';
import axios from 'axios';
import { Form } from 'informed';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { InputTypeText } from '../elements';
import { API_URL } from '../../lib/const';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    logginIn: false,
  }

  handleSubmit(values) {
    const { history } = this.props;
    this.setState({ logginIn: true });
    axios.post(`${API_URL}/login`, values)
      .then((response) => {
        const { token, ...data } = response.data;
        localStorage.setItem('token', token);
        this.setState({ logginIn: false });
        history.push('/dashboard', { ...data });
      })
      .catch((e) => {
        this.setState({ logginIn: false });
        if (get(e, 'response.status') === 400) {
          toast.error('Login ou senha incorretos.', { position: toast.POSITION.BOTTOM_RIGHT });
        } else {
          toast.error('Erro inesperado. Por favor, tente novamente.');
        }
      });
  }

  render() {
    const { logginIn } = this.state;

    return (
      <Fragment>
        <div className="login-background">

          <Form
            className="login-card shadow"
            onSubmit={this.handleSubmit}
          // getApi={(formApi) => { this.formApi = formApi; }}
          >
            <figure className="text-center mt-2">
              <img src={require('../../assets/img/logo-green.svg')} className="login-logo" alt="notification" />
            </figure>
            {/* <div className="login-error">
             <span>Login errado</span>
             <button className="icon">
               <Icon name="error" width="15" white />
             </button>
           </div> */}

            <InputTypeText label="Login" type="text" field="username" required />
            <InputTypeText label="Senha" type="password" field="password" required />
            <button type="submit" className="btn btn-primary col-12 p-3" disabled={logginIn}>{logginIn ? 'Entrando...' : 'ENTRAR'}</button>
          </Form>
        </div>
      </Fragment>
    );
  }
}

export default Login;
