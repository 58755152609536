import React from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { FieldSet, Spinner } from '../elements';
import { Documents } from '../components';
import { API_URL } from '../../lib/const';
import ComponentWithToken from '../components/CompontentWithToken';
import DownloadButton from '../components/DownloadButton';
import { fetchFinanceDataSummary } from '../services/finance_data_service';
import ListBoleto from '../components/boleto/ListBoleto';


class Payment extends ComponentWithToken {
  state = {
    loading: false,
    status: null,
    invoices: [],
    proofs_of_payment: [],
    boleto_id: null,
    ProofSent: true,
    complementaryBoletos: [],
    // options: {},
  }

  componentDidMount() {
    Promise.all([
      this.fetchStatus(),
      this.fetchFinanceData(),
      this.fetComplementaryBoletos(),
    ]).finally(this.setState({ loading: false }));
  }

  fetComplementaryBoletos = () => {
    axios.get(`${API_URL}/onboarding/complementary-boletos/${this.token}`)
      .then(response => this.setState({ complementaryBoletos: response.data }));
  }

  fetchStatus() {
    this.setState({ loading: true });
    return axios.get(`${API_URL}/onboarding/enroll/${this.token}`)
      .then(response => this.setState({
        status: get(response, 'data.enrollment_status'),
        envelopeStatus: get(response, 'data.envelope_status'),
        showProof: get(response, 'data.show_proof'),
        ProofSent: get(response, 'data.proof_sent'),
      }));
  }

  fetchFinanceData = () => fetchFinanceDataSummary(this.token).then(response => this.setState({ boleto_id: get(response, 'boleto_id') }))

  handleDocumentUpload = (event) => {
    const file = get(event, 'target.files.0');
    const data = new FormData();
    data.append('file', file);
    data.append('document_type', 6);
    return axios.post(`${API_URL}/uploads/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        this.setState((prevState) => {
          const { proofs_of_payment } = prevState;
          proofs_of_payment.push({ key: response.data.key, name: file.name });
          return { proofs_of_payment };
        });
      });
  }

  handleProofOfPaymentRemove = (key) => {
    const { proofs_of_payment } = this.state;
    const new_proofs_of_payment = proofs_of_payment.filter(proof => proof.key !== key);
    this.setState({ proofs_of_payment: new_proofs_of_payment });
  }

  handleSend() {
    const { proofs_of_payment } = this.state;
    const { token } = this.props;
    this.setState({ loading: true });
    const data = {
      proofs_of_payment,
    };

    axios.post(`${API_URL}/onboarding/proof-of-payment/${token}`, data)
      .then(() => {
        toast.success('Comprovante de pagamento enviado', { position: toast.POSITION.BOTTOM_RIGHT });
        this.componentDidMount();
      })
      .catch((error) => {
        toast.error(get(error, 'response.data[0]', 'Ocorreu um erro inesperado'), { position: toast.POSITION.BOTTOM_RIGHT });
        this.setState({
          loading: false,
        });
      });
  }

  renderComprovantePagamento() {
    const { proofs_of_payment, boleto_id, complementaryBoletos } = this.state;
    return (
      <>
        <ListBoleto boleto={boleto_id} complementaryBoletos={complementaryBoletos} />
        <div className="documents-base row" style={{ justifyContent: 'flex-start' }}>
          <Documents
            id="Comprovante de pagamento"
            label="Comprovante de pagamento"
            onUpload={this.handleDocumentUpload}
            onRemove={this.handleProofOfPaymentRemove}
            documents={proofs_of_payment}
          />
        </div>
        <hr />
      </>
    );
  }

  renderDownloadContract() {
    return (
      <>
        <FieldSet title="Contrato" />
        <DownloadButton href={`${API_URL}/contracts/enrollment/${this.token}/contract`} label="Baixar contrato" />
        <div className="row mb-3 mt-3">
          <div className="col-sm-12 mt-3">
            <label>Para assinar o contrato digitalmente busque no seu provedor de e-mail pelo contrato enviado pela DocuSign.</label>
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      loading, showProof, boleto_id, ProofSent,
    } = this.state;
    const { backClick } = this.props;
    return (
      <Spinner active={loading}>
        {boleto_id && this.renderDownloadContract()}
        {(boleto_id && !ProofSent) && this.renderComprovantePagamento()}
        <div className="text-right">
          <div className="col-md-4 p-0 offset-md-8 col-sm-12">
            <div className="btn-actions">
              <button className="btn btn-black mr-2" type="button" onClick={() => backClick()}>Voltar</button>
              {(boleto_id && showProof && !ProofSent) && <button onClick={() => this.handleSend()} type="button" className="btn btn-primary">ENVIAR</button>}
            </div>
          </div>
        </div>
      </Spinner>
    );
  }
}

export default withRouter(Payment);
