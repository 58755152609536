import axios from 'axios';
import { API_URL } from '../../lib/const';
import { getAxiosConfig } from '../../lib/auth';

export const fetchStudent = token => (
  axios.get(
    `${API_URL}/onboarding/enrollments/${token}`,
  )
    .then(response => response.data)
);

export const fetchSteps = () => axios.get(`${API_URL}/onboarding/steps`, getAxiosConfig())
  .then(response => response.data);

export default fetchStudent;
