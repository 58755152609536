import React from 'react';
import PropTypes from 'prop-types';

const ImportErrors = ({
  rows, rowOffset, getExplanation, tabName,
}) => {
  const rowsWithErrors = rows
    .map((d, i) => ({ ...d, originalRowId: i + rowOffset }))
    .filter(d => Object.keys(d).length > 1);
  return (
    <div className="danger">
      <p><strong>{getExplanation(tabName)}</strong></p>
      {rowsWithErrors.map(row => (
        <div key={row.originalRowId}>
          <span><strong>Linha {row.originalRowId}</strong></span>
          <ul>
            {Object.entries(row).filter(error => error[0] !== 'originalRowId').map(error => (
              <li key={`${error[0]}_${row.originalRowId}`}>{error[0]}: {error[1]}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

ImportErrors.defaultProps = {
  rowOffset: 2, // 1 for header and 1 because arrays are 0-indexed
  rows: [],
  getExplanation: () => 'Por favor, corrija os problemas abaixo e tente novamente.',
};

ImportErrors.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))),
  rowOffset: PropTypes.number,
  getExplanation: PropTypes.func,
  tabName: PropTypes.string.isRequired,
};

export default ImportErrors;
