import axios from 'axios';
import { API_URL } from '../../lib/const';

export const fetchHealthData = token => (
  axios.get(`${API_URL}/health/health-data/${token}`)
    .then(response => response.data)
);

export const fetchVaccines = token => (
  axios.get(`${API_URL}/health/health-data/${token}`)
    .then(response => response.data)
);

export const fetchQuestions = token => (
  axios.get(`${API_URL}/health/health-data/${token}`)
    .then(response => response.data)
);
