import React from 'react';
import Rodal from 'rodal';
import PropTypes from 'prop-types';

class Modal extends React.Component {
  render() {
    const {
      visible,
      onCancel,
      title,
      children,
      onConfirm,
      cancelText,
      confirmText,
      width,
      height,
      loading,
    } = this.props;

    return (
      <Rodal visible={visible} onClose={onCancel} closeOnEsc height={height} width={width}>
        <div className="d-flex flex-column h-100">
          <div className="modal-header">
            <div className="text-center">
              <h2>{title}</h2>
            </div>
          </div>
          <div className="modal-body">
            {children}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              disabled={loading}
              onClick={onCancel}
              className="btn-danger btn blue"
            >
              {cancelText}
            </button>
            <button
              type="button"
              disabled={loading}
              onClick={onConfirm}
              className="btn-primary btn blue"
            >
              {confirmText}
            </button>
          </div>
        </div>
      </Rodal>
    );
  }
}

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Modal.defaultProps = {
  title: '',
  children: null,
  cancelText: 'Recusar',
  confirmText: 'Aceitar',
  width: 600,
  loading: false,
  height: 400,
};

export default Modal;
