import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { API_URL } from '../../lib/const';

export const approveFinanceData = token => (
  axios.post(`${API_URL}/flow/${token}/action/approve_finance_data`)
    .then(response => toast.success(get(response.data, 'message'), { position: toast.POSITION.BOTTOM_RIGHT }))
    .catch((e) => {
      if (get(e, 'response.status') === 403) {
        toast.error(get(e, 'response.data.detail'), { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.error('Erro inesperado, por favor tente novamente.');
      }
      return Promise.reject(e);
    })
);

export const sendContract = token => (
  axios.post(`${API_URL}/contracts/send/${token}`)
    .then(response => toast.success(get(response.data, 'message'), { position: toast.POSITION.BOTTOM_RIGHT }))
    .catch((e) => {
      toast.error('Erro inesperado, por favor tente novamente.');
      return Promise.reject(e);
    })
);

export const approveProof = (token, data) => axios.post(`${API_URL}/onboarding/proof-of-payment/${token}`, data)
  .then(() => toast.success('Comprovante de pagamento aprovado', { position: toast.POSITION.BOTTOM_RIGHT }));
