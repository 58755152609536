import React from 'react';
import PropTypes from 'prop-types';
import { PermissionContext } from '../../../hoc/withPermission';

const NewBoletoButton = ({ disabled, onClick, permission }) => (
   <PermissionContext.Consumer>
    {({ hasPermission }) => hasPermission(permission) && (
      <div className="col-md-4 p-0 offset-md-8 col-sm-12">
        <button
          type="button"
          className="btn btn-primary mt-5"
          disabled={disabled}
          onClick={onClick}
        >
          Gerar novo boleto
        </button>
      </div>
    )}
  </PermissionContext.Consumer>
);

NewBoletoButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.string,
};

NewBoletoButton.defaultProps = {
  disabled: false,
  permission: 'onboarding.can_generate_boleto_financedata',
};

export default NewBoletoButton;
