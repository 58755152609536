const DocumentsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loadingDocuments: action.payload,
      };
    case 'SET_DOCUMENTS_DATA':
      return {
        ...state,
        documentTypes: action.payload,
      };
    case 'SET_PERSONAL_DOCUMENTS':
    case 'REMOVE_PERSONAL_DOCUMENTS':
      return {
        ...state,
        personalDocuments: action.payload,
      };
    case 'ADD_PERSONAL_DOCUMENTS':
      return {
        ...state,
        personalDocuments: [ ...state.personalDocuments, action.payload]
      }
    case 'SET_SUBMITTING':
      return {
        ...state,
        submitting: action.payload,
      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default DocumentsReducer;
