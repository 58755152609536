import { initial } from "lodash";

const DocumentsDetailReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PERSONAL_DATA':
      return {
        ...state,
        personalData: action.payload,
        loadingPage: false,
      };
    case 'SET_PERSONAL_DOCUMENTS_TYPES':
      return {
        ...state,
        personalDocumentsTypes: action.payload,
      };
    case 'SET_EDITING':
      return {
        ...state,
        isEditing: !state.isEditing,
      };
    case 'SET_PERSONAL_DOCUMENTS':
    case 'REMOVE_PERSONAL_DOCUMENTS':
      return {
        ...state,
        personalDocuments: action.payload,
      };
    case 'ADD_PERSONAL_DOCUMENTS':
      return {
        ...state,
        personalDocuments: [...state.personalDocuments, action.payload],
      };
    case 'SET_LOADING_PAGE':
      return {
        ...state,
        loadingPage: action.payload,
      };
    case 'SET_CONFIRMATION_MODAL':
      return {
        ...state,
        confirmationModal: !state.confirmationModal,
      };
    case 'SET_PENDING_DOCUMENT':
      return {
        ...state,
        pendingDocument:action.payload,
      }
    case 'OPEN_PENDENCIES_VISIBILITY':
      return {
        ...state,
        showPendencies: true,
        pendingDocument:action.payload
      };
    case 'CLOSE_PENDENCIES_VISIBILITY':
      return {
        ...state,
        showPendencies:false,
        pendingDocument: null
      }
    case 'SET_PENDENCIES_LIST_VISIBILITY':
      return {
        ...state,
        hasPendencies: action.payload,
      };
    case 'SET_PENDENCIES_LIST':
      return {
        ...state,
        pendencies: action.payload,
        loadingPendencies: false,
      };
    case 'SET_LOADING_PENDENCIES':
      return {
        ...state,
        loadingPendencies: !state.loadingPendencies,
      };
    case 'SET_FORM':
      return {
        ...state,
        form: action.payload,
      };
    case 'SET_DOCUMENTS_ERROR':
      return {
        ...state,
        errors: action.payload,
      };
    default: return state;
  }
};

export default DocumentsDetailReducer;
