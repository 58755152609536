import React from 'react';
import PropTypes from 'prop-types';

class StepControllerButtons extends React.Component {
  render() {
    const { onBackClick, onFowardClick } = this.props;

    return (
      <div className="text-right">
        <div className="col-md-4 p-0 offset-md-8 col-sm-12">
          <div className="btn-actions">
            <button className="btn btn-black mr-2" type="submit" onClick={onBackClick}>Voltar</button>
            <button className="btn btn-primary" type="submit" onClick={onFowardClick}>Próximo</button>
          </div>
        </div>
      </div>
    );
  }
}

StepControllerButtons.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  onFowardClick: PropTypes.func.isRequired,
};

export default StepControllerButtons;
