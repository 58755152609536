import React from 'react';
import ReactTable from 'react-table';
import { Radio, RadioGroup } from 'informed';
import PropTypes from 'prop-types';
import fetchOnboardings from '../../onboarding/services/fetch_onboarding';


class OnboardingForm extends React.Component {
  state = {
    onboardings: [],
  }

  componentDidMount() {
    this.fetchOnboardings();
  }

  mapData = d => ({
    value: d.id,
    ...d,
  })

  fetchOnboardings = () => {
    const { params } = this.props;

    fetchOnboardings(params)
      .then((data) => {
        this.setState({
          onboardings: data.map(this.mapData),
        });
      });
  }

  render() {
    const { onboardings } = this.state;
    const { onValueChange } = this.props;

    return (
      <div className="list-base">
        <RadioGroup field="onboarding" onValueChange={onValueChange}>
          <ReactTable
            data={onboardings}
            columns={[
              {
                Header: '',
                accessor: 'id',
                width: 50,
                Cell: row => (
                  <label className="input-radio-type">
                    <Radio value={row.original} id={`finance_plan-${row.value}`} />
                    <span className="radiomark mt-0" />
                  </label>
                ),
                filterable: false,
              },
              {
                Header: 'Curso',
                accessor: 'course',
              },
              {
                Header: 'Currículo',
                accessor: 'curriculum',
              },
              {
                Header: 'Ano / Período de promoção',
                accessor: 'field_step',
              },
            ]}
            defaultPageSize={10}
            pageSize={5}
            filterable
            className="table-default -striped -highlight"
          />
        </RadioGroup>
      </div>
    );
  }
}

OnboardingForm.propTypes = {
  params: PropTypes.shape({
    curriculum: PropTypes.string,
    course: PropTypes.string,
    field_step: PropTypes.string,
  }),
  onValueChange: PropTypes.func.isRequired,
};

OnboardingForm.defaultProps = {
  params: {},
};

export default OnboardingForm;
