import React from 'react';
import { InputTypeArea } from '../elements';
import RadioButtonsAnswer from '../elements/RadioButtonsAnswer';

const HealthQuestions = ({ question }) => (
  <div className="row">
    <RadioButtonsAnswer
      field="answer"
      label={question.description}
    />
    <InputTypeArea
      label="Observação"
      col="10"
      field="observation"
    />
  </div>
);

export default HealthQuestions;
