import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../../lib/auth';
import withPermission from '../../hoc/withPermission';
import { Header, SidebarMenu } from '../components';

class PrivateRoute extends React.Component {
  state = {
    collapsed: false,
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    const width = window.innerWidth;
    const mediumSizeScreen = 1200;

    this.setState({
      collapsed: width <= mediumSizeScreen,
    });
  }

  handleCollapse = () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  }

  render() {
    const width = window.innerWidth;
    const mediumSizeScreen = 1200;
    const {
      navbar,
      noSidebar,
      permission,
      hasPermission,
      component: Component,
      ...rest
    } = this.props;
    const { collapsed } = this.state;

    return (
      <Route
        {...rest}
        render={props => (
          isAuthenticated() && (!permission || hasPermission(permission))
            ? (
              <>
                {navbar && <Header />}
                <div className={`content ${(collapsed && width >= mediumSizeScreen) ? 'pl-5' : null}`}>
                  {noSidebar ? '' : <SidebarMenu onCollapse={this.handleCollapse} />}
                  <div className="w-100">
                    <div className="container">
                      <Component {...props} />
                    </div>
                  </div>
                </div>
              </>
            )
            : <Redirect to="/login" />
        )}
      />
    );
  }
}

PrivateRoute.propTypes = {
  navbar: PropTypes.bool,
  noSidebar: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
};

PrivateRoute.defaultProps = {
  navbar: false,
  noSidebar: false,
  component: null,
};

export default withPermission(PrivateRoute);
