import React from 'react';
import { fetchCourse } from '../services/options_service';
import { InputTypeDropdown } from '.';

class InputDropdownCourse extends React.Component {
  state = {
    options: [],
  }

  componentDidMount() {
    this.fetchCourse();
  }

  fetchCourse = () => {
    fetchCourse()
      .then((data) => {
        this.setState({
          options: data,
        });
      });
  }

  render() {
    const { ...rest } = this.props;
    const { options } = this.state;

    return (
      <InputTypeDropdown
        field="course"
        label="Curso"
        options={options}
        {...rest}
      />
    );
  }
}

export default InputDropdownCourse;
