import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class StepStatus extends React.Component {
  render() {
    const { status } = this.props;
    return(
      <Fragment>
          <label className="step-header-status">
            {
              status === 'Preenchido' ? (
                <div className="step-status"> 
                  <i class="fas fa-check-circle fa-2x green" /> 
                  <label className="ml-3 mr-4">{status}</label>
                </div>
              ) : status === 'Em análise' ? (
                  <div className="step-status">
                    <i class="fas fa-pause-circle fa-2x yellow" />
                    <label className="ml-3 mr-4">{status}</label>
                  </div>
              ) : status === 'Com pendências' ? (
                  <div className="step-status">
                    <i class="fas fa-exclamation-circle fa-2x red " /><i class=""></i>
                    <label className="ml-3 mr-4">{status}</label>
                  </div>
                ) : status === 'Aprovado' ? (
                  <div className="step-status">
                    <i class="fas fa-check-circle fa-2x green" />
                    <label className="ml-3 mr-4">{status}</label>
                  </div>
                  ) : status === 'nonFieldStatus' ? (
                    null
                ) : (
                  <div className="step-status">
                    <i class="fas fa-minus-circle fa-2x dark-silver" />
                    <label className="ml-3 mr-4">Não Preenchido</label>
                  </div>
                )
              }
          </label>
      </Fragment>
    );
  }
}

StepStatus.propTypes = {
  color: PropTypes.string,
  status: PropTypes.string,
}

StepStatus.defaultProps = {
  color: 'gray',
  status: '',
}

export default StepStatus;
