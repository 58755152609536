import PropTypes from 'prop-types';
import React from 'react';

const Button = ({
  disabled, loading, onClick, style, type, children,
}) => (
  // eslint-disable-next-line react/button-has-type
  <button
    className={`btn btn-${style}`}
    type={type}
    disabled={disabled}
    onClick={onClick}
  >
    {loading ? 'Carregando' : children}
  </button>
);

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.oneOf(['primary']),
  type: PropTypes.oneOf(['button', 'submit']),
  children: PropTypes.node.isRequired,
};


Button.defaultProps = {
  loading: false,
  disabled: false,
  style: 'primary',
  type: 'button',
};

export default Button;
