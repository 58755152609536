import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { DOCUMENTS, GUARANTEE_TYPE, MARITAL_PROPERTY_SYSTEM, MARITIAL_STATUS } from '../../../lib/config';
import DocumentViewer from '../../components/DocumentViewer';
import { Ball, FieldSet, KeyValue } from '../../elements';

const FinanceDataSummary = ({ financeData }) => (
  <>
    <FieldSet title="Financeiro" />
    <div className="enrollment-flex">
      <KeyValue title={get(financeData, 'plan.name')} label="Plano" />
      <KeyValue title={get(financeData, 'plan.value')} label="Total" />
      <KeyValue title={get(financeData, 'plan.installments')} label="Parcelas" />
      <KeyValue title={get(financeData, 'plan.installment_value')} label="Valor da Parcela" />
      <KeyValue title={get(financeData, 'plan.due_date')} label="Vencimentos" />
      {get(financeData, 'guarantee_type') && (<KeyValue title={get(financeData, 'guarantee_type.name')} label="Tipo de garantia" />)}
      <KeyValue title={get(financeData, 'payment_method.name')} label="Método de Pagamento" />
    </div>
    {get(financeData, 'guarantee_type.id') === GUARANTEE_TYPE.LETTER_GUARANTEE && (
      <>
        <FieldSet title="Carta Fiança" />
        <div className="enrollment-flex">
          <KeyValue title={get(financeData, 'bank_name')} label="Banco" />
        </div>
        {get(financeData, 'letter_guarantee') && <DocumentViewer centralizeContent document={get(financeData, 'letter_guarantee')} />}
      </>
    )}
    {
      get(financeData, 'guarantee_type.id') === GUARANTEE_TYPE.GUARANTOR && financeData.guarantors && get(financeData, 'guarantors').map((g, i) => (
        <>
          <FieldSet title={`Fiador ${i + 1}`} />
          <div className="enrollment-flex">
            <KeyValue title={get(g, 'name')} label="Nome" />
            <KeyValue title={get(g, 'nationality')} label="Nacionalidade" />
            {get(g, 'cpf') && <KeyValue title={get(g, 'cpf')} label="CPF" />}
            {get(g, 'rne') && <KeyValue title={get(g, 'rne')} label="RNE" />}
            <KeyValue title={get(g, 'birth_date')} label="Data de Nascimento" />
            {get(g, 'rg') && <KeyValue title={get(g, 'rg')} label="RG" />}
            {get(g, 'rg_issuing_body') && <KeyValue title={get(g, 'rg_issuing_body')} label="Orgão Emissor" />}
            {get(g, 'rg_issue_date') && <KeyValue title={get(g, 'rg_issue_date')} label="Data de Expedição do RG" />}
            <KeyValue title={get(g, 'marital_status')} label="Estado Civil" />
          </div>
          {
            get(g, 'marital_status_id', null) === MARITIAL_STATUS.MARRIED && (
              <div className="enrollment-flex">
                <KeyValue title={get(g, 'marital_property_system')} label="Regime de Casamento" />
                {
                  get(g, 'marital_property_system_id') !== MARITAL_PROPERTY_SYSTEM.SEPARATION_OF_GOODS && (
                    <>
                      <KeyValue title={get(g, 'spouse_name')} label="Nome do Cônjugue" />
                      <KeyValue title={get(g, 'spouse_nationality')} label="Nacionalidade do Cônjugue" />
                      {get(g, 'spouse_cpf') ? <KeyValue title={get(g, 'spouse_cpf')} label="CPF do Cônjugue" /> : <KeyValue title={get(g, 'spouse_rne')} label="RNE do Cônjugue" />}
                      <KeyValue title={get(g, 'spouse_email')} label="Email do Cônjugue" />
                    </>
                  )
                }
              </div>
            )
          }
          <div className="enrollment-flex">
            <KeyValue title={get(g, 'email')} label="email" />
            <KeyValue title={get(g, 'phone_number')} label="Telefone 1" />
            <KeyValue title={get(g, 'landline')} label="Telefone 2" />
            <KeyValue title={get(g, 'zip_code')} label="CEP" />
            <KeyValue title={get(g, 'street_address')} label="Logradouro" />
            <KeyValue title={get(g, 'address_number')} label="Número" />
            <KeyValue title={get(g, 'complement_address')} label="Complemento" />
            <KeyValue title={get(g, 'neighborhood')} label="Bairro" />
            <KeyValue title={get(g, 'state')} label="Estado" />
            <KeyValue title={get(g, 'city')} label="Cidade" />
          </div>
          {
            g.documents.length > 0 && (
              <>
                <hr />
                <h6>Documentos Fiador {i + 1}</h6>
                <div className="documents-base row mb-5" style={{ justifyContent: 'start' }}>
                  {
                    g.documents.map(document => (
                      <div className="col-sm-12 col-md-6 mt-5">
                        <div className="documents-uploaded" style={{ backgroundColor: 'rgb(3, 159, 151)', color: 'white' }}>
                          <div className="d-flex align-items-center">
                            <div className="documents-length-0"> {get(DOCUMENTS.TYPE.filter(type => type.id === document.document_type), '[0].label', 'Documento')} - {document.name} </div>
                            <button className="btn-none" type="button" onClick={() => this.handleModal(document)}>
                              <Ball
                                icon="view"
                                extraSmall
                                bgColor="white"
                                iconWidth="16"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </>
            )
          }
        </>
      ))
    }
  </>
);

FinanceDataSummary.propTypes = {
  financeData: PropTypes.shape({
    plan: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      installments: PropTypes.number,
      installment_value: PropTypes.string,
      due_date: PropTypes.string,
    }),
    guarantee_type: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    guarantors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      nationality: PropTypes.string,
      cpf: PropTypes.string,
      rne: PropTypes.string,
      birth_date: PropTypes.string,
      rg: PropTypes.string,
      rg_issuing_body: PropTypes.string,
      rg_issue_date: PropTypes.string,
      marital_status: PropTypes.string,
      marital_status_id: PropTypes.string,
      marital_property_system: PropTypes.string,
      marital_property_system_id: PropTypes.string,
      spouse_name: PropTypes.string,
      spouse_nationality: PropTypes.string,
      spouse_cpf: PropTypes.string,
      spouse_rne: PropTypes.string,
      spouse_email: PropTypes.string,
      email: PropTypes.string,
      phone_number: PropTypes.string,
      landline: PropTypes.string,
      zip_code: PropTypes.string,
      street_address: PropTypes.string,
      address_number: PropTypes.string,
      complement_address: PropTypes.string,
      neighborhood: PropTypes.string,
      state: PropTypes.string,
      city: PropTypes.string,
    })),
  }).isRequired,
};

export default FinanceDataSummary;
