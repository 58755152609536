import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../elements/Spinner';
import DefaultProfilePicture from '../../assets/img/people.svg';

class ProfilePicture extends React.Component {
  render() {
    const {
      loading, src, onUpdate, autosaveFeedBack, error,
    } = this.props;
    return (
      <div className="mb-5 text-center">
        <Spinner active={loading}>
          <div>
            {error && (
              <label style={{ color: 'red' }}>{error}</label>
            )}
          </div>
          <button className="btn-invisible" type="button" onClick={() => document.getElementById('photo-id').click()}>
            <img
              src={src}
              className="people-img"
              alt="people"
            />
          </button>
          <label className="people-button" htmlFor="photo-id" style={{ color: error ? 'red' : 'black' }}>
            Mudar foto
            <input type="file" id="photo-id" className="input-file" onChange={event => onUpdate(event)} />
            <span style={{ marginLeft: '10px' }}>{autosaveFeedBack}</span>
          </label>
        </Spinner>
      </div>
    );
  }
}

ProfilePicture.propTypes = {
  loading: PropTypes.bool.isRequired,
  src: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  autosaveFeedBack: PropTypes.element,
  error: PropTypes.string,
};

ProfilePicture.defaultProps = {
  src: DefaultProfilePicture,
  autosaveFeedBack: null,
  error: null,
};

export default ProfilePicture;
