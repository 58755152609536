import React from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { FieldSet } from '../elements';
import { convertCurrencyValue, formatCurrency } from '../../lib/utils';

class PaymentPlans extends React.Component {
  groupByPaymentPlans = (currentPaymentPlans) => {
    const groupedPayments = groupBy(currentPaymentPlans, c => c.date);

    return Object.keys(groupedPayments).map(date => ({
      name: groupedPayments[date][0].name,
      date,
      value: groupedPayments[date].reduce((acc, payment) => acc + convertCurrencyValue(payment.value), 0),
    }));
  }

  render() {
    const { financePlan, paymentPlans } = this.props;
    if (financePlan) {
      const currentPaymentPlans = paymentPlans.filter(p => +p.finance_plan === +financePlan.id);
      return (
        <>
          <FieldSet title="Plano de Pagamento" />
          <div className="list-base">
            <ReactTable
              data={this.groupByPaymentPlans(currentPaymentPlans)}
              columns={[
                {
                  Header: 'Plano de Pagamento',
                  accessor: 'name',
                },
                {
                  Header: 'Data',
                  accessor: 'date',
                },
                {
                  Header: 'Valor',
                  accessor: 'value',
                  Cell: row => formatCurrency(row.value),
                },
              ]}
              showPagination={false}
              defaultPageSize={1}
              pageSize={this.groupByPaymentPlans(currentPaymentPlans).length}
              className="-triped -highlight"
            />
          </div>
        </>
      );
    }
    return null;
  }
}

PaymentPlans.propTypes = {
  financePlan: PropTypes.shape({
    id: PropTypes.number,
  }),
  paymentPlans: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    date: PropTypes.string,
    value: PropTypes.string,
  })),
};

PaymentPlans.defaultProps = {
  financePlan: undefined,
  paymentPlans: [],
};

export default PaymentPlans;
