import React from 'react';
import { asField } from 'informed';


export default asField(({
  error, required, className, helper, fieldState, fieldApi, field, label, col, helpBlock, numbers, onChange, autosaveFeedBack, ...props
}) => (
  <div className={`col-md-${col} col-sm-12`} style={{ display: 'flex' }}>
    <div className="input-type-style" style={{ display: 'flex' }}>
      <input
        className={fieldState.error ? 'input-error' : 'input-style'}
        id={field}
        value={fieldState.value || ''}
        onChange={(e) => {
          fieldApi.setValue(e.target.value);
          fieldApi.setError(null);
          if (onChange) {
            onChange(e);
          }
        }}
        {...props}
      />
      {helper && (<label className="input-helper">{helper}</label>)}
      {fieldState.error && fieldState.error.map(e => <label key={e} className="input-helper text-danger">{e}</label>)}
      <label
        htmlFor={field}
        className={`input-label-style ${required && 'required'} ${fieldState.error && 'label-error'}`}
      >
        {label}
      </label>
    </div>
    <div style={{ display: 'flex', marginTop: '40px' }}>
      {autosaveFeedBack}
    </div>

  </div>
));
