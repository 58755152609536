import React from 'react';
import { Form } from 'informed';
import ReactTable from "react-table";
import { InputTypeText } from '../elements';

class Enrollments extends React.Component {
  render() {
    return (
      <div className="list-base" style={{height: '80vh'}}>
        <h2>Aprovação - Acadêmica</h2>
        <Form>
          <InputTypeText col="12" field="filter" type="text" label="Filtrar" />
        </Form>
        <ReactTable
          columns={[
            {
              Header: 'Classificação',
              accessor: '',
            },
            {
              Header: "Nome",
              accessor: "name"
            },
            {
              Header: "CPF",
              accessor: "cpf"
            },
            {
              Header: "Nascimento",
              accessor: "birth"
            },
            {
              Header: "E-mail",
              accessor: "email"
            },
            {
              Header: "Celular",
              accessor: "cellphone"
            },
            {
              Header: "Tipo de Ingresso",
              accessor: "enrollment.onboarding.admission.name"
            },
            {
              Header: "Modificado em",
              accessor: "lastModify"
            },
            {
              Header: "Status",
              accessor: "status"
            },
          ]}
          defaultSorted={[
            {
              id: "age",
              desc: true
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

export default Enrollments;
