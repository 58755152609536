import React from "react";
import { fetchInstitutions } from "../services/options_service";
// import { InputTypeDropdown } from '.';
import InputAsyncInformed from "./InputAsyncInformed";

// class InputDropdownInstitution extends React.Component {
//   state = {
//     options: [],
//   };

//   componentDidMount() {
//     this.fetchInstitutions();
//   }

//   fetchInstitutions = () => {
//     fetchInstitutions().then((data) => {
//       this.setState({
//         options: data,
//       });
//     });
//   };

//   render() {
//     const { ...rest } = this.props;
//     const { options } = this.state;

//     return (
//       <InputTypeDropdown
//         field="institutions"
//         label="Instituições"
//         options={options}
//         {...rest}
//       />
//     );
//   }
// }

// export default InputDropdownInstitution;

const InputDropdownInstitution = (props) => (
  // state = {
  //   options: [],
  // };

  // componentDidMount() {
  //   this.fetchInstitutions();
  // }

  // fetchInstitutions = () => {
  //   fetchInstitutions().then((data) => {
  //     this.setState({
  //       options: data,
  //     });
  //   });
  // };

  // render() {
  // const { ...rest } = this.props;
  // const { options } = this.state;

  // return (
  <InputAsyncInformed
    defaultOptions
    field="institutions"
    placeholder="Institutição"
    loadOptions={(search) => fetchInstitutions({ search })}
    {...props}
  />
  // );
  // }
);

export default InputDropdownInstitution;
