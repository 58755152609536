import React from 'react';
import PropTypes from 'prop-types';
import { FieldSet } from '../../elements';
import { ENROLLMENT_STATUS } from '../../../lib/config';


const EnrollmentPendingFieldSet = ({ enrollment }) => {
  const getMessage = () => {
    switch (enrollment.status.id) {
      case ENROLLMENT_STATUS.ACCESSED:
      case ENROLLMENT_STATUS.NOT_STARTED:
      case ENROLLMENT_STATUS.FILLING:
        return 'O aluno ainda não enviou o formulário.';
      default:
        return 'O aluno ainda não resolveu as pendências.';
    }
  };

  return (
    <>
      <FieldSet title="Em Andamento" />
      <div className="col-sm-12 col-md-6 mt-2">
        <p>{getMessage()}</p>
      </div>
    </>
  );
};

EnrollmentPendingFieldSet.propTypes = {
  enrollment: PropTypes.shape({
    status_id: PropTypes.number,
  }).isRequired,
};

export default EnrollmentPendingFieldSet;
